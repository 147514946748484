import PopeyeModal from "../popeye-modal.class";

class EquipmentImageViewModalController {
    static $inject = [
        "$q",
        "$scope",
        "$window",
        "imageItem",
    ];

    constructor(
        $q,
        $scope,
        $window,
        imageItem,
    ) {
        this.$q = $q;
        this.$scope = $scope;
        this.$window = $window;
        this.imageItem = imageItem;
        $scope.vm = this;
    }

    /**
     * Окно для просмотра фотографий
     * @param url
     */
    openImagePopup(url) {
        this.$window.open(url, '_blank', 'toolbar=0,width=1024,height=800');
    }

    cancel() {
        this.$scope.$close();
    }
}


export class EquipmentImageViewModal extends PopeyeModal {
    modal = {
        template: require("./equipment-image-view.modal.html"),
        styles: [require("./equipment-image-view.modal.scss")],
        controller: EquipmentImageViewModalController
    };
}
