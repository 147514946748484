import { settings } from '../../app.config'
// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'

/** @ngInject */
class MonthProbegFormController extends PrintFormController {
  reportName = 'mprobeg'

  get defaultFilters() {
    const td = new Date()
    return {
      firm: this.ourFirms.results[0].id,
      month: td.getMonth() + 1,
      year: settings.defaultYear,
    }
  }

  get convertedFilters() {
    return {
      _firma: this.filters.firm,
      _year: this.filters.year,
      _mon: this.filters.month,
    }
  }
}

export const MonthProbegFormComponent = {
  template: require('./month-probeg-form.component.html'),
  styles: [require('./month-probeg-form.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: MonthProbegFormController,
}
