import { FormController } from '../../controllers/form-controller.class'
import { PRICEP_TYPES } from '../../constants'

class PricepUsageFormController extends FormController {
  PRICEP_TYPES = PRICEP_TYPES

  /** @ngInject */
  constructor($scope, $injector, planServiceApiV2) {
    super($scope, $injector)
    this.planServiceApiV2 = planServiceApiV2
    this.model = {}
    this.priceps = [{}]
  }

  /** Инициализация компонента. */
  $onInit() {}

  /** Сформировать отчет. */
  print() {
    const params = Object.assign({}, this.model)
    if (this.priceps.length > 0) {
      params.priceps = this.priceps
        .map((p) => p.pricep)
        .filter((p) => !!p) // пропустим пустые
        .join(',')
    }
    this.planServiceApiV2.getPricepUsage(params)
  }

  close() {
    window.close()
  }

  addPricep() {
    this.priceps.push({ pricep: null })
  }

  removePricep(pricep) {
    const idx = this.priceps.indexOf(pricep)
    if (idx > -1) {
      this.priceps.splice(idx, 1)
    }
  }
}

export const PircepUsageFormComponent = {
  template: require('./pricep-usage-form.component.html'),
  styles: [require('./pricep-usage-form.component.scss')],
  bindings: {
    onSave: '&',
    onClose: '&',
  },
  controller: PricepUsageFormController,
}
