/** @ngInject */
export class ListController {
  ALLOW_DELETE_MSG = 'Вы уверены, что хотите удалить выбранный элемент?'

  /** Сервис для работы с таблицей. */
  api
  /** Настройки основной таблицы. */
  tableOptions
  /** Модальное окно для элементов таблицы. */
  modal

  constructor($scope, $injector) {
    this.$scope = $scope
    this.$injector = $injector
    this.$q = $injector.get('$q')
    $scope.vm = this
    this.selected = null
  }

  /** Фильтры таблицы. */
  get filters() {
    return this._filters
  }

  /** Фильтры таблицы по умолчанию. */
  get defaultFilters() {
    return {}
  }

  /** Контроллер таблицы. */
  get table() {
    return this._table
  }

  /** Словарь данных выбранной строки в таблице. */
  get selectedData() {
    return this.table.ctrl.selected()
  }

  /** Инициализация контроллера. */
  $onInit() {
    const that = this
    this.resetFilters()
    this._table = {
      options: this.tableOptions,
      init$: this.$q.defer(),
      service: this.api,
      ctrl: null,
      rowfmt: this.rowFormatter,
    }
    return this.table.init$.promise.then((ctrl) => {
      that.table.ctrl = ctrl
    })
  }

  /** Сброс фильтров. */
  resetFilters() {
    this._filters = { ...this.defaultFilters }
  }

  /** Форматирование строк. */
  rowFormatter(row) {
    return row
  }

  /** Добавить элемент в таблицу. */
  add() {
    const modalInst = this.modal.open({
      locals: {
        filters: this.filters,
        item: null,
      },
    })
    return modalInst.closed.then((item) => {
      this.table.ctrl.addOrUpdateRow(item)
    })
  }

  /** Редактирование выбранной строки в таблице. */
  edit() {
    const modalInst = this.modal.open({
      locals: {
        filters: this.filters,
        item: this.selectedData,
      },
    })
    return modalInst.closed.then((item) => {
      this.table.ctrl.addOrUpdateRow(item)
    })
  }

  /** Удаление выбранной строки в таблице. */
  delete() {
    const that = this
    return new Promise((resolve, reject) => {
      if (confirm(this.ALLOW_DELETE_MSG)) {
        this.api
          .delete(this.selected)
          .then((id) => {
            that.table.ctrl.removeRow(id)
          })
          .then(
            (resp) => {
              resolve(resp)
            },
            (error) => {
              reject(error)
            }
          )
      } else {
        reject(new Error('cancelled'))
      }
    })
  }

  /** Выборка элементов. */
  search() {
    return this.table.ctrl.load(this.filters)
  }

  /** Закрытие формы. */
  close() {
    window.close()
  }
}
