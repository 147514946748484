export const technicalControlState = {
  name: 'technicalControlState',
  url: '/plists/technical-control',
  component: 'technicalControlForm',
  resolve: {
    ourFirms: (firmServiceApiV2) => firmServiceApiV2.getOurFirms(),
  },
  data: {
    title: 'Журнал контроля технического состояния',
  },
}
