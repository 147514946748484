import angular from 'angular'
import { ngOpenDialog, fprint } from './popups'

export default {
  arraySort: {
    byGosNomer: function (a, b) {
      return extractDigits(a.nomer) - extractDigits(b.nomer)

      function extractDigits(nomer) {
        const found = /(\d+)/.exec(nomer)
        return found ? parseInt(found[0]) : 0
      }
    },
  },
  obj: {
    equal: (obj1, obj2) => {
      const json1 = angular.toJson(obj1)
      const json2 = angular.toJson(obj2)
      return json1 === json2
    },
    copy: (o) => _.cloneDeep(o),
    pick: (obj, keys, clone = false) => (({ ...keys }) => ({ ...keys }))(obj),
  },
  db: {
    str2int: (s) => {
      const iv = parseInt(s)
      return isNaN(iv) ? 0 : iv
    },
    str2float: (s) => {
      const fv = parseFloat(s)
      return isNaN(fv) ? 0.0 : fv
    },
    str2date: $.fn.dateutils.sql2date,
    date2str: $.fn.dateutils.date2sql,
    dmy2date,
    date2dmy,
  },
  math: {
    round: function (num, precision) {
      return $.fn.roundPHP(num, precision)
    },
  },
  url: {
    buildQuery: $.fn.buildQuery,
  },
  print: printReport,
  fprint,
  date: {
    today_s: () => {
      const date = new Date()
      return $.fn.dateutils.date2str_ddmmyyyy(date, '.')
    },
  },
  popups: {
    open: ngOpenDialog,
    openState,
  },
}

/**
 * Полное имя фирмы.
 *
 * @param {string} name наименование фирмы
 * @param {string} type форма собственности
 * @returns {string} полное имя фирмы
 */
export function quotedName(name, type = '') {
  if (type) {
    type = `${type.trim().toUpperCase()}&nbsp;`
  }
  return `${type}"${name.trim()}";`
}

/**
 * Печать на основе шаблонов PHP Spreadsheet.
 * @param {*} report
 * @param {*} params
 */
function printReport(report, title = 'Отчет', params = {}) {
  params._fname = title
  const args = $.fn.buildQuery(params)
  window.location = `print2.php?path=${report}.php&${args}`
}

function dmy2date(value) {
  if (value.trim() === '') {
    return null
  }
  return $.fn.dateutils.str_ddmmyyyy2date(value, '.')
}

function date2dmy(value) {
  if (!value) {
    return ''
  }
  return $.fn.dateutils.date2str_ddmmyyyy(value, '.')
}

/**
 * Открытие состояния в диалоговом окне.
 *
 * @param {string} state состояние для перехода
 * @param {number} width длина окна (px)
 * @param {number} height высота окна (px)
 * @returns {Promise<object>} результат выхода из диалога
 */
function openState(state, width, height) {
  const dfd = $.Deferred()
  const child = $.fn.openWindow('ngopen.php#!/' + state, width, height)
  child.dialog = dfd
  return dfd.promise()
}

/**
 * Является ли строка целым числом?
 * @param {string} str строка
 * @returns {boolean}
 */
export function isInteger(str) {
  if (typeof str !== 'string') return false
  return !isNaN(str) && !isNaN(parseInt(str))
}

/**
 * Первый день текущего месяца.
 * @returns {Date}
 */
export function firstDayOfMonth() {
  const date = new Date()
  const y = date.getFullYear()
  const m = date.getMonth()
  return new Date(y, m, 1)
}

/**
 * Последний день текущего месяца.
 * @returns {Date}
 */
export function lastDayOfMonth() {
  const date = new Date()
  const y = date.getFullYear()
  const m = date.getMonth()
  return new Date(y, m + 1, 0)
}

/**
 * Первый день текущего года.
 * @returns {Date}
 */
export function firstDayOfYear() {
  const date = new Date()
  const y = date.getFullYear()
  return new Date(y, 0, 1)
}

/**
 * Последний день текущего года.
 * @returns {Date}
 */
export function lastDayOfYear() {
  const date = new Date()
  const y = date.getFullYear()
  return new Date(y, 11, 31)
}

/** Текущий месяц (начиная с 0) @returns {number} */
export function currentMonth() {
  return new Date().getMonth()
}
