import PopeyeModal from '../popeye-modal.class'

export default class KintransModal extends PopeyeModal {
  modal = {
    template: require('./kintrans.modal.html'),
    /** @ngInject */
    controller: function ($scope, item) {
      $scope.item = item
    },
  }
}
