import PopeyeModal from '../popeye-modal.class'

export default class PutlistModal extends PopeyeModal {
  modal = {
    template: require('./putlist.modal.html'),
    controller: function (
      $scope,
      plist,
      plans,
      drivers,
      avtos,
      priceps,
      ourFirms
    ) {
      $scope.plist = plist
      $scope.plans = plans
      $scope.drivers = drivers
      $scope.avtos = avtos
      $scope.priceps = priceps
      $scope.ourFirms = ourFirms
    },
  }
}
