export const driverEditState = {
  name: 'driver',
  url: '/drivers/:driverId',
  component: 'driverForm',
  resolve: {
    model: (driverServiceApiV2, $transition$) => {
      return driverServiceApiV2.get({ id: $transition$.params().driverId })
    },
  },
  data: {
    title: 'Карточка водителя',
  },
}
