import { printUrl } from '../popups'

const PRIHOD_HEADERS = {
  firm: 'Пост',
  data: 'Дата',
  summa: 'Сумма',
  __plat: 'Плательщик',
  nscheta: 'Счет',
  is_opl: 'Опл',
  nds_: 'НДС, %',
  predopl_str: 'Предоплата',
}

const RASHOD_HEADERS = {
  nomer: 'Счет',
  data: 'Дата',
  __verbose__firms: 'Субконтрактор',
  __verbose__type: 'Услуга',
  summa: 'Сумма, руб.',
  sumnds: 'В том числе НДС, руб.',
  opl: 'Опл',
  nds_: 'НДС, %',
}

export default function (
  $q,
  $scope,
  uchetService,
  zatratiService,
  zaysService,
  uchetModal,
  zatratiModal,
  zoplModal,
  firmServiceApiV2,
  uslugiService
) {
  const self = {}
  $scope.self = self
  let modal

  self.id = $.fn.urlparam('id')
  self.active = 'dohod'

  self.ourFirms = []

  self.prihod = {
    prognoz: 0,
    balans: 0,
    ostatok: 0,
    table: {
      opts: {
        headers: PRIHOD_HEADERS,
      },
      init$: $q.defer(),
      selected: null,
      service: uchetService,
      ctrl: null,
      rowfmt: function (r) {
        if (('' + r.nds).trim() !== '') {
          r.nds_ = r.nds * 100
        } else {
          r.nds_ = 'н/о'
        }
        r.data = $.fn.dateutils.date2str_ddmmyyyy(r.data, '.')

        if (r.nzopls > 0 && r.nzoplrefs > 0) {
          r.predopl_str = 'ошибочный'
        } else if (r.nzopls > 0) {
          r.predopl_str = `итоговый (${r.nzopls})`
        } else if (r.nzoplrefs > 0) {
          r.predopl_str = 'предоплата'
        }

        return r
      },
    },
  }
  Object.defineProperty(self.prihod.table, 'selectedObject', {
    get: () => {
      const ctrl = self.prihod.table.ctrl
      if (!ctrl || !ctrl.rows) {
        return undefined
      }
      return ctrl.rows.find((r) => r.id === self.prihod.table.selected)
    },
  })

  Object.defineProperty(self, 'uchet', {
    get: () => self.prihod.table.selectedObject,
  })

  self.rashod = {
    prognoz: 0,
    uchten: false,
    table: {
      opts: {
        headers: RASHOD_HEADERS,
      },
      init$: $q.defer(),
      selected: null,
      service: zatratiService,
      ctrl: null,
      rowfmt: function (r) {
        r.data = $.fn.dateutils.date2str_ddmmyyyy(r.data, '.')

        if (isNaN(r.nds) || r.nds === '') {
          r.sumnds = 'н/о'
        } else {
          const sumnds = r.summa - r.summa / (1 + r.nds / 100.0)
          r.sumnds = (Math.round(sumnds * 100) / 100).toFixed(2)
        }

        r.nds_ = ('' + r.nds).trim() === '' ? 'н/о' : r.nds

        r.opl = r.plat ? '+' : '-'
        return r
      },
    },
  }

  const promises = [
    self.prihod.table.init$.promise,
    self.rashod.table.init$.promise,
    zaysService.get({ id: self.id, __verbose: 1 }),
    firmServiceApiV2.get({ kmbm__nashi: 1 }).then((page) => page.results),
  ]
  $q.all(promises).then((resps) => {
    self.prihod.table.ctrl = resps[0]
    self.rashod.table.ctrl = resps[1]
    self.zay = resps[2]
    self.ourFirms = resps[3].filter((f) => f.kmbm !== 'tm')

    return $q.all([reloadPrihod(), reloadRashod()]).then((resps) => {
      $scope.$watch('self.prihod.table.ctrl.lastModDate', (nv, ov) => {
        zaysService.get({ id: self.id, __verbose: 1 }).then((zay) => {
          self.zay = zay
        })
      })

      $scope.$watch('self.rashod.table.ctrl.lastModDate', (nv, ov) => {
        zaysService.get({ id: self.id, __verbose: 1 }).then((zay) => {
          self.zay = zay
        })
      })
    })
  })

  self.editPrihodPrognoz = () => {
    const oldPrognoz = self.zay.__verbose__finanse.prognoz_dohod
    const prognoz = prompt('Введите новый прогноз прихода:', oldPrognoz)
    if (prognoz) {
      self.zay.dohod = parseFloat(prognoz)
      // TODO verbose POST
      zaysService
        .save(self.zay)
        .then((zay) => zaysService.get({ id: zay.id, __verbose: 1 }))
        .then((zay) => {
          self.zay = zay
        })
    }
  }

  self.editRashodPrognoz = () => {
    const oldPrognoz = self.zay.__verbose__finanse.prognoz_rashod
    const prognoz = prompt('Введите новый прогноз расхода:', oldPrognoz)
    if (prognoz) {
      self.zay.rashod = parseFloat(prognoz)
      // TODO verbose POST
      zaysService
        .save(self.zay)
        .then((zay) => zaysService.get({ id: zay.id, __verbose: 1 }))
        .then((zay) => {
          self.zay = zay
        })
    }
  }

  self.editPrihodUchet = (id) => {
    modal = uchetModal.open({
      locals: {
        uchet: getPrihodUchet(id),
        zay: self.zay,
        type: 'prihod',
        ourfirms: [],
      },
    })
    modal.opened.then(focusOnSum)
    modal.closed.then(self.prihod.table.ctrl.addOrUpdateRow)
  }

  self.deletePrihodUchet = (id) => {
    if (!confirm('Вы уверены, что хотите удалить счет?')) {
      return
    }
    getPrihodUchet(id)
    self.prihod.table.ctrl.remove(id)
    return reloadPrihod()
  }

  self.editRashodUchet = (id) => {
    // в зависимости от строки редактируем либо учет, либо затраты
    const rashod = getRashodRow(id)
    return getUchetFromRashod(rashod).then((uchet) => {
      if (!rashod || !!uchet) {
        // этот счет должен быть сразу же оформлен как "счет субконтрактора"
        // TODO в бэкенде должна быть предусмотрена возможность оформления
        // счета как счета субконтратора за одну транзакцию
        // modal = Popeye.openModal(Object.assign(uchetModal, {
        modal = uchetModal.open({
          locals: {
            zay: self.zay,
            type: 'rashod',
          },
          resolve: {
            uchet: () => (rashod ? getUchetFromRashod(rashod) : $.when(null)),
            ourfirms: () =>
              firmServiceApiV2
                .get({ kmbm__nashi: 1 })
                .then((page) => page.results),
          },
        })
        modal.opened.then(focusOnSum)
        modal.closed.then(getRashodFromUchet).then((zatrati) => {
          if (!zatrati) {
            return
          }
          if (zatrati.length > 1 || zatrati.length === 0) {
            return
          }
          self.rashod.table.ctrl.addOrUpdateRow(zatrati[0])
        })
      } else {
        return self.editZatrati(rashod)
      }
    })
  }

  self.editZatrati = (rashod) => {
    if (!isNaN(rashod)) {
      // eslint-disable-next-line no-undef
      rashod = getRashodRow(id)
    }

    modal = zatratiModal.open({
      locals: {
        subschet: rashod,
        zay: self.zay,
        options: null,
      },
      resolve: {
        usltypes: () => uslugiService.get({}),
      },
    })
    modal.opened.then(focusOnSum)
    modal.closed.then(self.rashod.table.ctrl.addOrUpdateRow)
  }

  self.editZopl = (id) => {
    const uchet = getPrihodUchet(id)
    if (uchet.nzoplrefs > 0) {
      if (confirm('Отменить предоплату?')) {
        return uchetService.deleteZopl(uchet).then(reloadPrihod)
      }
    } else {
      modal = zoplModal.open({
        locals: {
          uchet,
          firmUchet: self.prihod.table.ctrl.rows,
        },
      })
      // обновим предоплатный и итоговый счета
      modal.closed.then(reloadPrihod)
    }
  }

  self.deleteRashodUchet = (id) => {
    const rashod = getRashodRow(id)
    return getUchetFromRashod(rashod).then((uchet) => {
      if (uchet) {
        if (
          confirm(
            `Будет удален счет субконтратора ${rashod.nomer} ` +
              `и связанный с ним внутренний счет ${uchet.nscheta} ` +
              `по фирме ${uchet.firm} на сумму ${uchet.summa} руб.`
          )
        ) {
          return uchetService.delete(uchet.id).then(() => {
            self.rashod.table.ctrl.removeRow(id)
          })
        }
      } else {
        if (confirm(`Будет удален счет субконтрактора ${rashod.nomer}`)) {
          return self.rashod.table.ctrl.remove(id)
        }
      }
    })
  }

  self.printZakaz = () => {
    printUrl(`print2.php?path=z_print1.php&id=${self.id}`)
    return false
  }

  self.printZay = (type) => {
    printUrl(
      `print2.php?path=z_print2.php&id=${self.id}&nscheta=` + `&type=${type}`
    )
    return false
  }

  self.printFullRasklad = () => {
    printUrl(`print2.php?path=z_print3.php&id=${self.id}`)
    return false
  }

  function getUchetFromRashod(rashod) {
    if (!rashod) {
      return $q.resolve(null)
    }
    return firmServiceApiV2.get({ id: rashod.firms }).then((firm) => {
      if (firm.kmbm === 'N') {
        return null
      }
      const nscheta = rashod.nomer.substring(1)
      return uchetService.get({ nscheta, firm: firm.kmbm }).then((uchets) => {
        return uchets[0]
      })
    })
  }

  function getRashodFromUchet(uchet) {
    if (!uchet) {
      return $q.resolve(null)
    }
    return zatratiService.get({
      nomer: `T${uchet.nscheta}`,
      zay: uchet.pid,
      kmbm: uchet.firm,
      __verbose: 1,
    })
  }

  function getPrihodUchet(id) {
    return id ? self.prihod.table.ctrl.getRow(id) : null
  }

  function getRashodRow(id) {
    return id ? self.rashod.table.ctrl.getRow(id) : null
  }

  function focusOnSum() {
    modal.element.find('input[name=summa]').focus()
  }

  function reloadPrihod() {
    // не покажет внутренние счета по данной заявке, т.к. они
    // выставлены от другой фирмы
    // TODO запретить выставлять внутренний счет от фирмы,
    // обрабатывающей данную заявку (чтобы внутренний счет
    // не отобразился в "приходе") - или пока не надо?
    return self.prihod.table.ctrl.load({
      firm: self.zay.type,
      zay: self.id,
      __verbose__plat: 1,
      __verbose__subschet: 1,
      __verbose__nzopls: 1,
      __verbose__opl: 1,
    })
  }

  function reloadRashod() {
    return self.rashod.table.ctrl.load({ zay: self.id, __verbose: 1 })
  }
}
