import { ngOpenDialog } from '../popups'

/** @ngInject */
export class FirmsController {
  constructor($scope, $q, $http, $timeout, firmServiceApiV2) {
    this.$scope = $scope
    this.$q = $q
    this.$http = $http
    this.$timeout = $timeout
    this.firmServiceApiV2 = firmServiceApiV2
    $scope.vm = this

    const DEFAULT_FILTERS = {
      _contains: '',
      inn__contains: '',
    }

    this.selected = null
    this.filters = Object.assign({}, DEFAULT_FILTERS)
    this.MODES_ENUM = Object.freeze({ default: 1, selection: 2 })

    this.firmsTable = {
      options: {
        headers: {
          _full_name: 'Фирма',
          inn: 'ИНН',
        },
        tablesorterOptions: {
          sortList: [[0, 0]],
        },
      },
      init$: $q.defer(),
      service: firmServiceApiV2,
      ctrl: null,
      rowfmt: (row) => {
        row.inn = row.rekv.inn
        return row
      },
    }
  }

  get mode() {
    return window.dialog ? this.MODES_ENUM.selection : this.MODES_ENUM.default
  }

  $onInit() {
    this.clearFilters()
    this.$q.all([this.firmsTable.init$.promise]).then((resps) => {
      this.firmsTable.ctrl = resps[0]
    })
  }

  add() {
    $.when(ngOpenDialog('firms/add', 730, 800)).then(
      this.firmsTable.ctrl.addOrUpdateRow
    )
  }

  edit() {
    $.when(ngOpenDialog(`firms/${this.selected}/edit`, 730, 800)).then(
      this.firmsTable.ctrl.addOrUpdateRow
    )
  }

  delete() {
    if (!confirm('Вы уверены, что хотите удалить выбранную фирму?')) {
      return
    }

    this.firmServiceApiV2
      .delete(this.selected)
      .then(this.firmsTable.ctrl.removeRow)
  }

  close() {
    window.close()
  }

  clearFilters() {
    this.filters = { ...this.DEFAULT_FILTERS }
  }

  select() {
    window.dialog.resolve(this.firmsTable.ctrl.getSelectedRow()) // FIXME !
    window.close()
  }
}
