import { CRUDService } from './crud-service.class'

export class PlategiService extends CRUDService {
  subject = 'plategi'

  parse = (p) => {
    p.id = parseInt(p.id)
    p.file = parseInt(p.file)
    if (p.nomer === '') {
      p.nomer = null
    } else {
      p.nomer = parseInt(p.nomer)
    }
    p.pid = parseInt(p.pid)
    if (p.plateg === '') {
      p.plateg = null
    } else {
      p.plateg = parseInt(p.plateg)
    }
    p.summa = parseFloat(p.summa)
    p._data_str = `${p.data}`
    p.data = p.data ? $.fn.dateutils.str_ddmmyyyy2date(p.data, '.') : null
    p.dtmp = parseInt(p.dtmp)
    return p
  }

  format = (o) => {
    if (!o.nomer) {
      o.nomer = ''
    }
    if (!o.plateg) {
      o.plateg = ''
    }
    if (!o.data) {
      o.data = ''
    } else {
      o.data = $.fn.dateutils.date2str_ddmmyyyy(o.data, '.')
    }
    return o
  }
}
