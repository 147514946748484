// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'

/** @ngInject */
class OstFuelFormController extends PrintFormController {
  reportName = 'printofuel'
  legacyReport = true

  get defaultFilters() {
    return {
      _firma: this.ourFirms.results[0].id,
    }
  }
}

export const OstFuelFormComponent = {
  template: require('./ostfuel-form.component.html'),
  styles: [require('./ostfuel-form.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: OstFuelFormController,
}
