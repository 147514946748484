import { CRUDServiceAPI2 } from './crud-service.class'

/** Сервис для легаси пользователей на основе ApiV2 */
export class LegacyUserServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'users/legacy'

  toHtml(user) {
    return user.fio
  }

  /**
   * Поисковые фильтры для легаси пользователей.
   * @param {string} text часть ФИО
   * */
  getSearchFilters(text) {
    return { fio: text }
  }
}
