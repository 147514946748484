export const DelayService = function ($timeout) {
  return function (cb, ms) {
    let timeoutPromise
    return function (nv, ov) {
      // if (nv == ov)
      //     return;
      $timeout.cancel(timeoutPromise)
      timeoutPromise = $timeout(cb, ms)
      return timeoutPromise
    }
  }
}
