import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

export class AvtosService extends CRUDService {
  subject = 'avtos'

  parse = (a) => {
    a.id = parseInt(a.id)
    a.bnorm = parseFloat(a.bnorm)
    a.ntr = parseFloat(a.ntr)
    return a
  }
}

export class AvtoServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'avtos'

  toHtml(avto) {
    return (
      `${avto.nomer} ` +
      `<div class="comment">${avto.firm_info.full_name}</div>`
    )
  }

  getSearchFilters(term) {
    return { nomer: term }
  }
}
