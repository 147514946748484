import { CI_DECIMAL_PATTERN } from '../../constants'
import PopeyeModal from '../popeye-modal.class'

class EquipmentAddModalController {
  /** @ngInject */
  constructor(
    $q,
    $scope,
    $state,
    groupItem,
    equipmentItem,
    equipmentServiceApiV2,
    toaster
  ) {
    Object.assign(this, {
      $q,
      $scope,
      $state,
      groupItem,
      equipmentItem,
      equipmentServiceApiV2,
      toaster,
    })

    this.setOpportunity()

    if (this.equipmentItem.id) {
      this.data = {
        id: this.equipmentItem.id,
        name: this.equipmentItem.name,
        length: this.equipmentItem.length,
        width: this.equipmentItem.width,
        height: this.equipmentItem.height,
        weight: this.equipmentItem.weight,
        quantity: this.equipmentItem.quantity,
        description: this.equipmentItem.description,
      }
    } else {
      this.data = {
        name: '',
        group: this.groupItem.id,
      }
    }

    $scope.vm = this
    this.decimalPattern = CI_DECIMAL_PATTERN
  }

  /**
   * Сохранение изделия
   */
  add() {
    if (this.validate()) {
      this.equipmentServiceApiV2.save(this.data).then((resp) => {
        this.$scope.$close(resp[0])
        this.$state.reload()
      })
    }
  }

  /**
   * Редактирование изделия
   */
  save() {
    if (this.validate()) {
      this.equipmentServiceApiV2.save(this.data).then((resp) => {
        this.$scope.$close(resp[0])
      })
    }
  }

  cancel() {
    this.$scope.$close()
  }

  /**
   * Установка разрешений и ограничений
   */
  setOpportunity() {
    this.opportunity = {
      can_add: !this.equipmentItem.id,
      can_edit: this.equipmentItem.id,
    }
  }

  /**
   * Общая валидация полей формы
   */
  validate() {
    return this.checkName()
  }

  /**
   * Проверка заполненности наименования
   * @returns
   */
  checkName() {
    if (this.data.name === undefined || this.data.name === '') {
      this.toaster.pop({
        type: 'error',
        title: 'Поле "Наименование" обязательно для заполнения',
      })
      return false
    }

    return true
  }
}

export class EquipmentAddModal extends PopeyeModal {
  modal = {
    template: require('./equipment-add.modal.html'),
    styles: [require('./equipment-add.modal.scss')],
    controller: EquipmentAddModalController,
  }
}
