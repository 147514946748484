/** @ngInject */
class SelectMonthController {
  constructor($scope) {
    this.$scope = $scope
    this.months = [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
    ].map((name, id) => ({ id: id + 1, name }))
  }

  $onInit() {
    this.ngModel.$render = this._$parse.bind(this)
    this.$scope.$watch(() => this.model, this._$format.bind(this))
  }

  _$parse() {
    this.model = this.ngModel.$viewValue
  }

  _$format(value) {
    this.ngModel.$setViewValue(value)
  }
}

export const SelectMonthComponent = {
  template: require('./select-month.component.html'),
  styles: [require('./select-month.component.scss')],
  bindings: {},
  require: {
    ngModel: '^ngModel',
  },
  controller: SelectMonthController,
  controllerAs: 'vm',
}
