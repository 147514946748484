export function NbspFilter($sce) {
  return function (value, times) {
    const n = times || 1
    if (!value) {
      return ''
    } else {
      const spaces = '&nbsp;'.repeat(value * n)
      return $sce.trustAsHtml(spaces)
    }
  }
}
