/** Контроллер для выбора заявки. */
class SelectObjectController {
  static $inject = ['$scope', '$injector']

  /** Словарь объектов для виджета (id => объект). */
  objectsById = {}

  /** Коллекция объектов для передачи в ui-select. */
  get objects() {
    return Object.values(this.objectsById)
  }

  /** Конструктор объекта. */
  constructor($scope, $injector) {
    this.$scope = $scope
    this.$injector = $injector
    $scope.vm = this
  }

  /** Инициализация компонента. */
  $onInit() {
    this.service = this.$injector.get(this.objectService)

    // если передали id объекта, подгрузим объект,
    // чтобы его было видно в виджете
    if (this.ngModel) {
      this.loadObjectById(this.ngModel)
    }
  }

  /** Загрузка объекта по id. */
  loadObjectById(id) {
    console.assert(!isNaN(id))
    if (id in this.objectsById) return
    this.service.get({ id }).then((obj) => {
      this.objectsById[obj.id] = obj
    })
  }

  /** Изменения модели. */
  $onChanges(changes) {
    if (changes.ngModel && !changes.ngModel.isFirstChange()) {
      const value = changes.ngModel.currentValue
      if (value === null || value === undefined) return
      this.loadObjectById(value)
    }
  }

  /** При выборе объекта меняем view value. */
  onSelect($item) {
    const id = $item ? $item.id : null
    this.ngModelCtrl.$setViewValue(id)
  }

  /**
   * Поиск объектов.
   * @param {string} text поисковая строка
   */
  search(text) {
    if (text.length <= 1) return
    const filters = this.service.getSearchFilters(text)
    return this.service.get(filters).then((objects) => {
      if (this.objectService.endsWith('ApiV2')) {
        objects = objects.results
      }
      this.objectsById = objects.idlize()
    })
  }

  /** Представление объекта в текстовом виде. */
  repr(value) {
    if (value) {
      if (value.constructor === Object) {
        return this.service.toHtml(value)
      } else {
        return '...'
      }
    } else return ''
  }
}

/** Компонент виджета выбора объекта. */
export const SelectObjectComponent = {
  template: require('./select-object.component.html'),
  styles: [require('./select-object.component.css')],
  bindings: {
    /** Состояние виджета (вкл/выкл) */
    ngDisabled: '<',
    /** Значение модели. */
    ngModel: '<',
    /** Сервис объектов. */
    objectService: '@',
    /** Подсказка ввода. */
    placeholder: '@',
  },
  require: {
    ngModelCtrl: 'ngModel',
  },
  controller: SelectObjectController,
}
