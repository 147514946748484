import { FormController } from '../../controllers/form-controller.class'
import { EQUIPMENT_REGISTRY, EQUIPMENT_ADMIN } from '../../constants'

/** @ngInject */
class EquipmentItemsController extends FormController {
  constructor(
    $scope,
    $injector,
    $state,
    $window,
    equipmentServiceApiV2,
    groupServiceApiV2,
    equipmentAddModal,
    equipmentImageViewModal,
    equipmentImageEditModal,
    equipmentImageDeleteModal,
    currentUser
  ) {
    super($scope, $injector)
    Object.assign(this, {
      $scope,
      $state,
      $window,
      equipmentServiceApiV2,
      groupServiceApiV2,
      equipmentAddModal,
      equipmentImageViewModal,
      equipmentImageEditModal,
      equipmentImageDeleteModal,
      currentUser,
    })

    this.hasAccess = false
    $scope.vm = this
  }

  $onInit() {
    this.hasAccess = this.checkAccess()
    this.hasAdminAccess = this.checkAdminAccess()
  }

  /**
   * Проверка доступа к панели управления реестром
   * @returns {boolean}
   */
  checkAccess() {
    return this.currentUser.roles.includes(EQUIPMENT_REGISTRY)
  }

  /**
   * Проверка на администратора реестра оборудования
   * @returns {boolean}
   */
  checkAdminAccess() {
    const isRegistryAdmin = this.currentUser.roles.includes(EQUIPMENT_ADMIN)
    return this.checkAccess() && isRegistryAdmin
  }

  /**
   * Открытие модального окна для редактирования изделия
   * @returns
   */
  openEquipmentEditModal() {
    const modal = this.equipmentAddModal.open({
      locals: {
        groupItem: this.parentGroupItem,
        equipmentItem: this.equipmentItem,
      },
    })
    return modal.closed.then((eqItem) => {
      this.$state.reload()
    })
  }

  /**
   * Открытие модального окна для удаления изделия
   * @returns
   */
  openEquipmentDeleteModal() {
    if (confirm(`Удалить изделие ${this.equipmentItem.name}`)) {
      this.equipmentServiceApiV2.delete(this.equipmentItem.id).then((resp) => {
        this.$state.go('equipmentGroup', { id: this.parentGroupItem.id })
      })
    }
  }

  /**
   * Открытие модального окна для редактирования фотографии
   * @param {*} image
   * @returns
   */
  openImageEditModal(type, image) {
    return this.equipmentImageEditModal.open({
      locals: {
        eqItem: this.equipmentItem,
        type,
        imageItem: image,
      },
    })
  }

  /**
   * Окно для просмотра фотографий
   * @param url
   */
  openImagePopup(url) {
    this.$window.open(url, '_blank', 'toolbar=0,width=1024,height=800')
  }

  /**
   * Открытие модального окна для просмотра фотографии
   * @param {*} image
   * @returns
   */
  openImageViewModal(image) {
    return this.equipmentImageViewModal.open({
      locals: {
        imageItem: image,
      },
    })
  }

  /**
   * Открытие модального окна для удаления фотографии
   * @param {*} image
   * @returns
   */
  openImageDeleteModal(type, image) {
    return this.equipmentImageDeleteModal.open({
      locals: {
        type,
        imageItem: image,
      },
    })
  }
}

export const EquipmentItemsComponent = {
  template: require('./equipment-items.component.html'),
  styles: [require('./equipment-items.component.scss')],
  bindings: {
    equipmentItem: '<',
    parentGroupItem: '<',
  },
  controller: EquipmentItemsController,
}
