export const ZoplFormComponent = {
  template: require('./zopl-form.component.html'),
  styles: [require('./zopl-form.component.css')],
  bindings: {
    uchet: '<',
  },
  controller: function ($q, $scope, uchetZoplService) {
    const $ctrl = this

    $ctrl.zopl = null

    $ctrl.$onInit = () => {}

    $ctrl.$onChanges = (changes) => {
      if (changes.uchet.isFirstChange()) return
      const uchet = changes.uchet.currentValue
      if (!uchet.firm || !uchet.nscheta) {
        $ctrl.zopl = null
        $ctrl.zopl_form.$setPristine()
        return
      }
      return uchetZoplService
        .get({
          schet: uchet.nscheta,
          firm: uchet.firm,
        })
        .then((zopls) => {
          if (zopls.length > 0) {
            $ctrl.zopl = zopls[0]
          } else {
            $ctrl.zopl = null
            $ctrl.zopl_form.$setPristine()
          }
        })
    }

    $ctrl.save = () => {
      if (!$ctrl.uchet) return
      const zopl = Object.assign(
        {
          firm: $ctrl.uchet.firm,
          schet: $ctrl.uchet.nscheta,
        },
        $ctrl.zopl
      )
      return uchetZoplService.save(zopl).then((zopl) => {
        $ctrl.zopl = zopl
        $ctrl.zopl_form.$setPristine()
      })
    }

    $ctrl.remove = () => {
      if (!$ctrl.uchet) return
      if (!$ctrl.zopl) return
      return uchetZoplService.delete($ctrl.zopl.id).then((resp) => {
        $ctrl.zopl = null
        $ctrl.zopl_form.$setPristine()
      })
    }
  },
}
