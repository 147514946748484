import utils from '../../utils'
import { FormController } from '../../controllers/form-controller.class'

class FirmFormController extends FormController {
  firmCodes = [
    { id: 'N', label: '-' },
    { id: 'zkm', label: 'ЗКМ' },
    { id: 'okm', label: 'ОКМ' },
    { id: 'tm', label: 'ТМ' },
    { id: 'bm', label: 'БМ' },
    { id: 'bsg', label: 'БСГ' },
    { id: 'ta', label: 'ТА' },
    { id: 'th', label: 'TХ' },
    { id: 'kl', label: 'КЛ' },
  ]

  dirTypes = [
    { id: 'dir', label: 'основной' },
    { id: 'zam', label: 'заместитель' },
  ]

  /** Конструктор компонента. @ngInject */
  constructor($scope, $injector, firmServiceApiV2) {
    super($scope, $injector)
    this.api = firmServiceApiV2
  }

  /** Контроллер формы. */
  get form() {
    return this.$scope.form
  }

  $onInit() {
    const emptyFirm = {
      dirtype: 'dir',
      kmbm: 'N',
      contacts: {},
      rekv: {},
    }
    if (!this.model) {
      this.model = { ...emptyFirm }
    }
  }

  alldogs() {
    const id = $.fn.urlparam('id')
    // eslint-disable-next-line no-undef
    openwindex(`url=alldogs&id=${id}`, '300', '390')
  }

  openContracts() {
    utils.popups.open(`firms/${this.model.id}/contracts`)
  }

  save() {
    // нужно обновить родительское окно
    super.save().then((firm) => {
      this.model = firm
      if (window.dialog) {
        window.dialog.resolve(firm)
      }
      window.close()
    })
  }

  close() {
    if (window.dialog) window.dialog.reject('cancel')
    window.close()
  }
}

export default {
  template: require('./firm-form.component.html'),
  styles: [require('./firm-form.component.scss')],
  bindings: {
    model: '<',
  },
  controller: FirmFormController,
}
