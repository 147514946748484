import { fprint } from '../popups'
import utils from '../utils'

export class PrinterService {
  /** @ngInject */
  constructor($window, FileSaver, $http) {
    this.$window = $window
    this.FileSaver = FileSaver
    this.$http = $http
  }

  /** Печать отчета. */
  print(report, params = {}) {
    params = $.fn.objectFromEntries(
      Object.entries(params).filter((e) => e[1] != null)
    )

    const url = `print2.php?path=${report}.php&${utils.url.buildQuery(params)}`
    this.$window.location = url
  }

  /** Печать отчета в старом формате. */
  printLegacy(report, params = {}) {
    const url = `${report}&${utils.url.buildQuery(params)}`
    fprint(url)
  }

  /** Печать через APIv2 */
  printBlob(name, url, params = {}) {
    return this.$http({
      url: `${url}?${utils.url.buildQuery(params)}`,
      method: 'GET',
      responseType: 'blob',
    }).then((resp) => {
      this.FileSaver.saveAs(resp.data, name)
    })
  }
}
