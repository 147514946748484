import { CRUDService } from './crud-service.class'

export class PlanGruzService extends CRUDService {
  subject = 'plan_gruzes'

  parse = (p) => {
    p.id = parseInt(p.id)
    p.nomer = parseInt(p.nomer)
    return p
  }

  format = (o) => {
    return o
  }
}
