import PopeyeModal from '../popeye-modal.class'

class AddPlanModalController {
  // Дата планирования.
  data = null
  // Фильтры.
  filters = {
    /** Доступная к тому, чтобы быть запланированной. */
    plannable: true,
    /** Незапланированные ТТН. */
    planned: false,
  }

  static $inject = [
    '$q',
    '$scope',
    'data',
    'sourcePlan',
    'ekspeditors',
    'planServiceApiV2',
    'ttnsServiceApiV2',
  ]

  constructor(
    $q,
    $scope,
    data,
    sourcePlan,
    ekspeditors,
    planServiceApiV2,
    ttnsServiceApiV2
  ) {
    this.$q = $q
    this.$scope = $scope
    this.data = data
    this.sourcePlan = sourcePlan
    this.ekspeditors = ekspeditors
    this.planServiceApiV2 = planServiceApiV2
    this.ttnsServiceApiV2 = ttnsServiceApiV2
    $scope.vm = this

    this.ttnsTable = {
      options: {
        headers: {
          pid: 'Заявка',
          nomer: 'Номер',
          eksp_name: 'Экспедитор',
        },
      },
      init$: $q.defer(),
      service: ttnsServiceApiV2,
      ctrl: null,
      rowfmt: (row) => {
        row.eksp_name = row.zay_info.ekspeditor
          ? this.ekspeditors[row.zay_info.ekspeditor].name
          : '-'
        return row
      },
    }
  }

  /** Выбранная ТТН. */
  get ttn() {
    return this.ttnsTable.ctrl.selectedObject
  }

  /** Запланировать ТТН. */
  add() {
    const newPlan = {
      pid: this.ttn.pid,
      nomer: this.ttn.nomer,
    }
    // если есть исходный план, то планируем на его основе
    if (this.sourcePlan) {
      delete this.sourcePlan.pid
      delete this.sourcePlan.nomer
      Object.assign(newPlan, this.sourcePlan)
    } else {
      if (this.data) {
        newPlan.data = this.data
      }
    }
    this.planServiceApiV2.save(newPlan).then((plan) => {
      this.$scope.$close(plan)
    })
  }

  cancel() {
    this.$scope.$close()
  }

  onTableInit(ctrl) {
    this.ttnsTable.ctrl = ctrl
  }
}

export class AddPlanModal extends PopeyeModal {
  modal = {
    template: require('./add-plan.modal.html'),
    controller: AddPlanModalController,
  }
}
