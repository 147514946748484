import { DatePickerDirective } from './datepicker.component'
// eslint-disable-next-line max-len
import { DatePickerDirective as OldDatePickerDirective } from './old-datepicker.component'
import FirmFormComponent from './firm-form/firm-form.component'
import { ngEnterDirective } from './ng-enter.directive'
import { PlategiFormComponent } from './plategi-form/plategi-form.component'
// eslint-disable-next-line max-len
import { OformlenieFormComponent } from './oformlenie-form/oformlenie-form.component'
import { ZoplFormComponent } from './zopl-form/zopl-form.component'
import { SubshetFormComponent } from './subshet-form/subshet-form.component'
import JsTableComponent from './js-table/js-table.component'
import ConvertToNumber from './convert-to-number.directive'
import { PutlistFormComponent } from './putlist-form/putlist-form.component'
// eslint-disable-next-line max-len
import { ZayFormComponent } from './zay-form/zay-form.component'
import { PlansComponent } from './plans/plans.component'
import { PlanFormComponent } from './plan-form/plan-form.component'
import { SelectObjectComponent } from './select-object/select-object.component'
import { ContractsComponent } from './contracts/contracts.component'
import { ContractFormComponent } from './contract-form/contract-form.component'
// eslint-disable-next-line max-len
import { SafetyBriefingFormComponent } from './safety-briefing-form/safety-briefing-form.component'
// eslint-disable-next-line max-len
import { TechnicalControlFormComponent } from './technical-control-form/technical-control-form.component'
import { StringToNumberDirective } from './string-to-number.directive'
// eslint-disable-next-line max-len
import { SaleOfServicesComponent } from './sale-of-services/sale-of-services.component'
import { ProstoiAvtoComponent } from './prostoi-avto/prostoi-avto.component'
import { TachographsComponent } from './tachographs/tachographs.component'
import { PricepFormComponent } from './pricep-form/pricep-form.component'
import { StringTSToDateDirective } from './ts-to-date.directive'
import { PricepsComponent } from './priceps/priceps.component'
import {
  ISOStringToDateDirective,
  DMYStringToDateDirective,
} from './string-to-date.directive'
// eslint-disable-next-line max-len
import { PircepUsageFormComponent } from './pricep-usage-form/pricep-usage-form.component'
import { DriverFormComponent } from './driver-form/driver-form.component'
import { TtnFormComponent } from './ttn-form/ttn-form.component'
import { EquipmentsComponent } from './equipments/equipments.component'
// eslint-disable-next-line max-len
import { EquipmentGroupsComponent } from './equipment-groups/equipment-groups.component'
// eslint-disable-next-line max-len
import { EquipmentItemsComponent } from './equipment-items/equipment-items.component'
import { FileReadDirective } from './file-read.directive'
import { MultipleFileReadDirective } from './multipe-file-read.directive'
import { AddGroupModal } from './add-group-modal/add-group.modal'
import { EquipmentAddModal } from './equipment-add-modal/equipment-add.modal'
// eslint-disable-next-line max-len
import { EquipmentImageViewModal } from './equipment-image-view-modal/equipment-image-view.modal'
// eslint-disable-next-line max-len
import { EquipmentImageEditModal } from './equipment-image-edit-modal/equipment-image-edit.modal'
// eslint-disable-next-line max-len
import {
  EquipmentImageDeleteModal,
  EquipmentImageDeleteModalController,
} from './equipment-image-delete-modal/equipment-image-delete.modal'
import { ZayNoteModal } from './zay-note-modal/zay-note.modal'
import UchetModal from './uchet-modal/uchet.modal'
import ZatratiModal from './zatrati-modal/zatrati.modal'
import ZatratiPayModal from './zatrati-pay-modal/zatrati-pay.modal'
import ZoplModal from './zopl-modal/zopl.modal'
import UslModal from './usl-modal/usl.modal'
import PutlistModal from './putlist-modal/putlist.modal'
import { AddPlanModal } from './add-plan-modal/add-plan.modal'
import { PlanModal } from './plan-modal/plan.modal'
import { ContractsModal } from './contracts-modal/contracts.modal'
import { PricepModal } from './pricep-modal/pricep.modal'
import angular from 'angular'
import { PricProbegComponent } from './pric-probeg/pric-probeg.component'
// eslint-disable-next-line max-len
import { AvtoJournalFormComponent } from './avto-journal-form/avto-journal-form.component'
// eslint-disable-next-line max-len
import { ArendaPolupricepovFormComponent } from './arenda-polupricepov-form/arenda-polupricepov-form.component'
import { SelectMonthComponent } from './select-month/select-month.component'
import { OstFuelFormComponent } from './ostfuel-form/ostfuel-form.component'
// eslint-disable-next-line max-len
import { RashodToplivaFormComponent } from './rashod-topliva-form/rashod-topliva-form.component'
// eslint-disable-next-line max-len
import { MonthProbegFormComponent } from './month-probeg-form/month-probeg-form.component'
import { OsopzmFormComponent } from './osopzm-form/osopzm-form.component'
import { Otper2NewFormComponent } from './otper2n-form/otper2n-form.component'
import { Otper2FormComponent } from './otper2-form/otper2-form.component'
import { Otper1FormComponent } from './otper1-form/otper1-form.component'
import { OtvystFormComponent } from './otvyst-form/otvyst-form.component'
import { NeoplFormComponent } from './neopl-form/neopl-form.component'
// eslint-disable-next-line max-len
import { FuelCostFormComponent } from './fuel-cost-form/fuel-cost-form.component'
import { KintransListComponent } from './kintrans-list/kintrans-list.component'
import { KintransFormComponent } from './kintrans-form/kintrans-form.component'
import KintransModal from './kintrans-modal/kintrans.modal'
// eslint-disable-next-line max-len
import { CtrlSchetFormComponent } from './ctrl-schet-form/ctrl-schet-form.component'
import { PackingFormComponent } from './packing-form/packing-form.component'
import { ZayStatusComponent } from './zay-status/zay-status.component'

export default angular
  .module('gruz.components', [])
  .directive('datePicker', DatePickerDirective)
  .directive('ngEnter', ngEnterDirective)
  .directive('oldDatePicker', OldDatePickerDirective)
  .directive('convertToNumber', ConvertToNumber)
  .directive('stringToNumber', StringToNumberDirective)
  .directive('strTsToDate', StringTSToDateDirective)
  .directive('dmyStringToDate', DMYStringToDateDirective)
  .directive('isoStringToDate', ISOStringToDateDirective)
  .directive('fileread', FileReadDirective)
  .directive('multifileread', MultipleFileReadDirective)
  .component('contracts', ContractsComponent)
  .component('contractForm', ContractFormComponent)
  .component('driverForm', DriverFormComponent)
  .component('firmForm', FirmFormComponent)
  .component('ttnForm', TtnFormComponent)
  .component('plans', PlansComponent)
  .component('planForm', PlanFormComponent)
  .component('plategiForm', PlategiFormComponent)
  .component('putlistForm', PutlistFormComponent)
  .component('technicalControlForm', TechnicalControlFormComponent)
  .component('safetyBriefingForm', SafetyBriefingFormComponent)
  .component('oformlenieForm', OformlenieFormComponent)
  .component('zoplForm', ZoplFormComponent)
  .component('zayForm', ZayFormComponent)
  .component('zayStatus', ZayStatusComponent)
  .component('subshetForm', SubshetFormComponent)
  .component('jsTable', JsTableComponent)
  .component('saleOfServices', SaleOfServicesComponent)
  .component('selectObject', SelectObjectComponent)
  .component('tachographs', TachographsComponent)
  .component('prostoiAvto', ProstoiAvtoComponent)
  .component('pricepForm', PricepFormComponent)
  .component('priceps', PricepsComponent)
  .component('pricepUsageForm', PircepUsageFormComponent)
  .component('equipments', EquipmentsComponent)
  .component('equipmentGroups', EquipmentGroupsComponent)
  .component('equipmentItems', EquipmentItemsComponent)
  .component('pricProbegForm', PricProbegComponent)
  .component('avtoJournalForm', AvtoJournalFormComponent)
  .component('arendaPolupricepovForm', ArendaPolupricepovFormComponent)
  .component('selectMonth', SelectMonthComponent)
  .component('ostFuelForm', OstFuelFormComponent)
  .component('rashodToplivaForm', RashodToplivaFormComponent)
  .component('monthProbegForm', MonthProbegFormComponent)
  .component('osopzmForm', OsopzmFormComponent)
  .component('otper2nForm', Otper2NewFormComponent)
  .component('otper2Form', Otper2FormComponent)
  .component('otper1Form', Otper1FormComponent)
  .component('otvystForm', OtvystFormComponent)
  .component('neoplForm', NeoplFormComponent)
  .component('fuelCostForm', FuelCostFormComponent)
  .component('kintransList', KintransListComponent)
  .component('kintransForm', KintransFormComponent)
  .component('ctrlSchetForm', CtrlSchetFormComponent)
  .component('packingForm', PackingFormComponent)
  // модальные окна
  .service('addPlanModal', AddPlanModal)
  .service('contractsModal', ContractsModal)
  .service('planModal', PlanModal)
  .service('uchetModal', UchetModal)
  .service('zatratiModal', ZatratiModal)
  .service('zatratiPayModal', ZatratiPayModal)
  .service('zoplModal', ZoplModal)
  .service('uslModal', UslModal)
  .service('putlistModal', PutlistModal)
  .service('pricepModal', PricepModal)
  .service('zayNoteModal', ZayNoteModal)
  .service('addGroupModal', AddGroupModal)
  .service('equipmentAddModal', EquipmentAddModal)
  .service('equipmentImageViewModal', EquipmentImageViewModal)
  .service('equipmentImageEditModal', EquipmentImageEditModal)
  .service('equipmentImageDeleteModal', EquipmentImageDeleteModal)
  .service('kintransModal', KintransModal)
  .controller(
    'equipmentImageDeleteModalController',
    EquipmentImageDeleteModalController
  ).name
