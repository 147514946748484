import { FIRM_NDS } from '../constants'

export function OfSActController(
  $q,
  $scope,
  $http,
  $timeout,
  uchetService,
  zaysService,
  zatratiService,
  firmsService,
  popup,
  zaysServiceApiV2
) {
  const vm = this

  Object.assign(vm, {
    uchetService,
    zaysService,
    uchet: null,
    uchet0: null, // счет до изменений
    uchetCtrl: null,
    selected: null,
    allzays: null,
    allzaysIdx: null,
    zatrati: null,
    zatratiWarning: null,
    zatratiInfo: null,
    ourFirms: null,
    ourFirmsIdx: null,

    filters: {
      firm: $.fn.assigndef($.fn.urlparam('firm'), 'zkm'),
      zay: $.fn.assigndef($.fn.urlparam('zay'), null),
    },
    uchetTableOptions: {
      headers: {
        nscheta: '№ счета',
        _data_str: 'Дата',
        _file: 'Файл',
        summa: 'Сумма, &#8381;',
        veksp: 'Возн. эксп., &#8381;',
        nds_str: 'НДС, %',
        inner_str: 'Внутр.',
        // '__plat': 'Плательщик'
        // 'primech': 'Примечание',
      },
      trackSelection: true,
      tablesorterOptions: {
        // sortList: [[0, 1]],
        headers: {
          2: { sorter: 'zay/nomer' },
        },
      },
    },

    init: () => {
      $scope.$watchCollection('vm.filters', vm.onFilterChange)
      $scope.$watch('vm.selected', vm.onUchetChange)

      return $q
        .all([
          zaysService.get({ __verbose: 1 }),
          firmsService.get({ kmbm__nashi: 1 }),
        ])
        .then((resps) => {
          vm.updateZays(resps[0])
          vm.ourFirms = resps[1]
          vm.ourFirmsIdx = vm.ourFirms.idlize()
        })
    },

    updateZays: (zays) => {
      vm.allzays = zays
      vm.allzaysIdx = zays.idlize()
    },

    getZayDohod: () => {
      vm.uchet.summa = vm.allzaysIdx[vm.uchet.pid].dohod
    },

    openZay: () => {
      popup.state('zay.base.detail', { id: vm.uchet.pid }, 800, 600)
    },

    onFilterChange: async (nv, ov) => {
      if (nv === ov) {
        return
      }

      if (nv.firm !== ov.firm) {
        vm.filters.zay = null
      } else if (nv.zay && nv.zay !== ov.zay) {
        vm.filters.firm = vm.allzaysIdx[nv.zay].type
      }

      // vm.filters.nscheta = null;

      await vm.reloadUchet()
    },

    reloadUchet: () => {
      return vm.uchetCtrl
        .load(Object.assign(vm.filters, { __verbose: 1 }))
        .then((data) => {
          const nscheta = $.fn.assigndef(
            parseInt($.fn.urlparam('nscheta')),
            null
          )
          if (nscheta) {
            const row = vm.uchetCtrl.rows.find((row) => row.nscheta === nscheta)
            if (row) {
              vm.uchetCtrl.selectRow(row.id)
              $timeout(() => {
                $(`#scheta tr[row-id=${row.id}]`).trigger('click')
              })
            } else {
              console.warn(`Can't find row for nscheta ${nscheta}`)
            }
          }
        })
    },

    onUchetChange: () => {
      const nv = vm.selected
      // if (nv === ov)
      //     return;
      if (nv) {
        const uchet = vm.uchetCtrl.selectedItem
        return zatratiService
          .get({ zay: uchet.pid, nomer: `T${uchet.nscheta}` })
          .then((zatrati) => {
            vm.zatratiWarning =
              zatrati.length > 1
                ? `Внимание! По заявке ${uchet.pid} ` +
                  `найдено более одного счета T${uchet.nscheta}`
                : null
            vm.zatrati = zatrati.length !== 0 ? zatrati[0] : null
            vm.resetUchet(uchet)
          })
      } else {
        vm.uchet = null
        vm.uchet0 = null
      }
    },

    updateUchetFromZay: () => {
      const zid = vm.uchet.pid
      if (!zid) {
        return
      }

      const zay = vm.allzaysIdx[zid]

      // если "плательщик" наш - то счет внутренний
      const plidIsOurs = !!vm.ourFirmsIdx[zay.plid]
      vm.uchet.inner = plidIsOurs ? '1' : '0'

      // берем сумму из заявки
      vm.uchet.summa = zay.dohod

      // вознаргаждение экспедитора (см. #8)
      if (vm.vekspVisible) {
        if (vm.uchet.summa < 500000) {
          vm.uchet.veksp = zay.dohod * 0.2
        } else if (vm.uchet.summa >= 500000 && vm.uchet.summa < 1500000) {
          vm.uchet.veksp = zay.dohod * 0.1
        } else {
          vm.uchet.veksp = 0
        }
        vm.uchet.veksp = Math.round(vm.uchet.veksp / 1000) * 1000
      } else {
        vm.uchet.veksp = 0
      }

      // НДС
      vm.uchet.nds = FIRM_NDS[zay.type]
    },

    save: () => {
      return vm.uchetCtrl.save(vm.uchet).then((saved) => vm.resetUchet(saved))
    },

    resetUchet: (data) => {
      vm.uchet0 = Object.assign({}, data) //, {data: date, summa: summa});
      vm.uchet = Object.assign({}, data) //, {data: date, summa: summa});
      $scope.uchet_form.$setPristine()
    },

    resetFilters: () => {
      vm.filters = {
        firm: 'zkm',
        zay: null,
        nscheta: null,
      }
    },

    add: async () => {
      let nomer = null
      // выбираем первую существующую ТТН по данной заявке
      if (vm.filters.zay) {
        const zay = await zaysServiceApiV2.get({ id: vm.filters.zay })
        if (zay.ttns.length > 0) {
          nomer = zay.ttns[0].nomer
        }
      }

      $timeout(() => {
        vm.uchet0 = {
          data: new Date(),
          inner: '0',
          // nds: '0.2',
          nds: getDefaultFirmNDS(vm.filters.firm),
          primech: '',
          cur: 'rur',
          nsubid: 0,
          oldcur: '',
          oldrub: '0',
          veksp: 0, // TODO make field
          nscheta: null,
          firm: vm.filters.firm,
          pid: vm.filters.zay,
          nomer,
        }

        vm.uchet = Object.assign({}, vm.uchet0)

        vm.updateUchetFromZay()

        vm.uchetCtrl.clearSelection()
        $('input[name=summa]').focus()

        vm.zatrati = null
      })

      function getDefaultFirmNDS(firm) {
        return FIRM_NDS[firm]
      }
    },

    oformlenie: () => {
      $.fn.openPopup(
        'ngoformlenie',
        {
          pid: vm.uchet.nscheta,
          fid: vm.uchet.firm,
          val: vm.uchet.cur,
        },
        680,
        600
      )
    },

    saveSubSchet: () => {
      const firm = vm.ourFirms.find((f) => f.kmbm === vm.uchet.firm)
      let nds = null
      if (vm.uchet.nds !== null && vm.uchet.nds !== ' ') {
        nds = vm.uchet.nds * 100
      }

      if (vm.zatrati) {
        return zatratiService
          .save(
            Object.assign(vm.zatrati, {
              pid: vm.uchet.pid,
              nomer: `T${vm.uchet.nscheta}`,
              data: vm.uchet.data,
              nds,
              summa: vm.uchet.summa,
              firms: firm.id,
            })
          )
          .then((zatrati) => {
            vm.zatrati = zatrati
          })
      } else {
        return zatratiService
          .save({
            pid: vm.uchet.pid,
            nomer: `T${vm.uchet.nscheta}`,
            data: vm.uchet.data,
            nds,
            summa: vm.uchet.summa,
            firms: firm.id,
            file: '', // наверное, поле совсем не нужно?
            plat: '',
            dataplat: '',
            type: '2',
            service: '0.00',
            tax: '0.00',
          })
          .then((zatrati) => {
            vm.zatrati = zatrati
          })
      }
    },

    removeSubSchet: () => {
      return zatratiService.delete(vm.zatrati.id).then(() => {
        vm.zatrati = null
      })
    },

    reset: () => {
      vm.resetUchet(vm.uchet0)
    },

    onInit: (ctrl) => {
      vm.uchetCtrl = ctrl
      return vm.reloadUchet()
    },

    remove: async () => {
      if (
        !confirm(`Вы уверены, что хотите удалить счет №${vm.uchet.nscheta}?`)
      ) {
        return
      }
      try {
        await vm.uchetCtrl.remove(vm.uchet)
      } catch (e) {
        alert('Произошла ошибка при удалении счета')
      }
    },

    rowProcessor: processRow,

    exit: () => {
      window.close()
    },
  })

  Object.defineProperty(vm, 'vekspVisible', {
    get: () =>
      vm.uchet
        ? !(
            (vm.uchet.inner === '1' && vm.uchet.firm === 'tm') ||
            vm.uchet.firm !== 'tm'
          )
        : false,
  })

  vm.init()

  function processRow(r) {
    r._file = `${r.pid}/${r.nomer}`
    r._data_str = $.fn.dateutils.date2str_ddmmyyyy(r.data, '.')
    r.inner_str = r.inner === '1' ? '<i class="fa fa-check"></i>' : ''

    if (r.nds === '0') {
      r.nds_str = '0'
    } else {
      const ndsval = parseFloat(r.nds)
      r.nds_str = !isNaN(ndsval) ? r.nds * 100 : 'н/о'
    }

    return r
  }
}
