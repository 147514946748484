export const PlategiFormComponent = {
  template: require('./plategi-form.component.html'),
  styles: [require('./plategi-form.component.css')],
  bindings: {
    nscheta: '<',
    firm: '<',
    pid: '<',
    nomer: '<',
  },
  controller: function ($q, $scope, $http, $element, $timeout, plategiService) {
    const $ctrl = this

    Object.defineProperty($ctrl, 'disabled', {
      get: () => {
        return !(
          !!$ctrl.nscheta &&
          !!$ctrl.firm &&
          !!$ctrl.pid &&
          !!$ctrl.nomer
        )
      },
    })

    $ctrl.plategiService = plategiService
    $ctrl.selected = null
    $ctrl.plat = null
    $ctrl.plategiCtrl = null
    $ctrl.plategiRows = []
    $ctrl.plategiTableOptions = {
      headers: {
        plateg: '№ платежки',
        _data_str: 'Дата',
        summa: 'Сумма',
      },
      trackSelection: true,
    }
    $ctrl.onPlategiTableInit$ = $q.defer()

    $ctrl.$onInit = () => {
      $scope.$watch(
        '$ctrl.selected',
        (nv) => ($ctrl.plat = $ctrl.plategiCtrl.selected())
      )

      const promises = [$ctrl.onPlategiTableInit$.promise]
      $q.all(promises).then((resps) => {
        $ctrl.plategiCtrl = resps[0]
        $ctrl.$onChanges = $onChanges
        return $ctrl.reload()
      })
    }

    function $onChanges(changes) {
      $ctrl.reload()
    }

    $ctrl.add = () => {
      const newPlat = Object.assign({}, $ctrl.plat, {
        pid: $ctrl.nscheta,
        file: $ctrl.pid,
        nomer: $ctrl.nomer,
        firma: $ctrl.firm,
      })
      return $ctrl.plategiCtrl.add(newPlat)
    }

    $ctrl.save = () => {
      return $ctrl.plategiCtrl.save($ctrl.plat)
    }

    $ctrl.remove = () => {
      if (!confirm('Вы уверены, что хотите удалить платежное поручение?'))
        return
      return $ctrl.plategiCtrl.remove($ctrl.plat).then(() => {
        $ctrl.plategi_form.$setPristine()
      })
    }

    $ctrl.reload = () => {
      $ctrl.selected = null
      if ($ctrl.disabled) {
        $ctrl.plategiCtrl.clear()
        return
      }

      return $ctrl.plategiCtrl
        .load({
          pid: $ctrl.nscheta,
          firma: $ctrl.firm,
          file: $ctrl.pid,
          nomer: $ctrl.nomer,
        })
        .then(() => {
          $ctrl.plategi_form.$setPristine()
        })
    }
  },
}
