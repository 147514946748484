import { FIRM_CODENAMES_SMALL } from '../../constants'
import { ModalFormCtrl } from '../../controllers/form-controller.class'

class KintransFormController extends ModalFormCtrl {
  /** @ngInject */
  constructor($scope, $injector, keensServiceApiV2) {
    super($scope, $injector)
    this.api = keensServiceApiV2
    this.codes = FIRM_CODENAMES_SMALL
  }

  $onInit() {
    if (!this.model) {
      this.model = {
        dirtype: 'dir',
      }
    }
    super.$onInit()
  }
}

export const KintransFormComponent = {
  template: require('./kintrans-form.component.html'),
  styles: [require('./kintrans-form.component.scss')],
  bindings: {
    model: '<',
    onClose: '&',
  },
  controller: KintransFormController,
}
