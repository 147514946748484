export default function () {
  return function (object, props) {
    const transformed = { ...object }
    for (const [key, value] of Object.entries(transformed)) {
      if (value instanceof Date) {
        // https://stackoverflow.com/questions/10830357
        const isoDateTime = new Date(
          value.getTime() - value.getTimezoneOffset() * 60000
        ).toISOString()
        transformed[key] = isoDateTime.substring(0, 10)
      }
    }
    return transformed
  }
}
