import { contractsState } from './contract.states'
import {
  firmAddState,
  firmContractsState,
  firmEditState,
  firmAddDriverState,
  baseFirmState,
} from './firm.states'
import { plistAddState, plistState } from './plist.states'
import { plansPricepUsageState, plansState } from './plans.states'
import { prostoiAvtoState } from './prostoi-avto.states'
import {
  zayBaseState,
  zayCreateState,
  zayDetailBaseState,
  zayDetailState,
} from './zay.states'
import { ttnNewState, ttnDetailState, ttnBaseState } from './ttn.states'
import { safetyBriefingState } from './safety-briefing.states'
import { saleOfServicesState } from './sale-of-services.states'
import { tachographState } from './tachograph.states'
import { technicalControlState } from './technical-control.states'
import { pricepEditState, pricepListState } from './pricep.states'
import { driverEditState } from './drivers.states'
import './equipments.states'
import angular from 'angular'

export default angular.module('gruz.states', ['gruz.states.equipment']).config([
  '$stateRegistryProvider',
  function ($stateRegistry) {
    $stateRegistry.register(contractsState)
    $stateRegistry.register(baseFirmState)
    $stateRegistry.register(firmAddState)
    $stateRegistry.register(firmEditState)
    $stateRegistry.register(ttnBaseState)
    $stateRegistry.register(ttnNewState)
    $stateRegistry.register(ttnDetailState)
    $stateRegistry.register(plistAddState)
    $stateRegistry.register(plistState)
    $stateRegistry.register(plansState)
    $stateRegistry.register(plansPricepUsageState)
    $stateRegistry.register(prostoiAvtoState)
    $stateRegistry.register(safetyBriefingState)
    $stateRegistry.register(saleOfServicesState)
    $stateRegistry.register(tachographState)
    $stateRegistry.register(technicalControlState)
    $stateRegistry.register(zayBaseState)
    $stateRegistry.register(zayCreateState)
    $stateRegistry.register(zayDetailBaseState)
    $stateRegistry.register(zayDetailState)
    $stateRegistry.register(firmContractsState)
    $stateRegistry.register(pricepEditState)
    $stateRegistry.register(pricepListState)
    $stateRegistry.register(firmAddDriverState)
    $stateRegistry.register(driverEditState)

    $stateRegistry.register({
      name: 'pricProbeg',
      url: '/pric-probeg',
      component: 'pricProbegForm',
      data: {
        title: 'Пробег прицепов',
      },
    })

    $stateRegistry.register({
      name: 'avtoJournal',
      url: '/journal',
      component: 'avtoJournalForm',
      resolve: {
        /** @ngInject */
        ourFirms,
      },
      data: {
        title: 'Журнал учета работы автотранспорта',
      },
    })

    $stateRegistry.register({
      name: 'arendaPolupricepov',
      url: '/arenda',
      component: 'arendaPolupricepovForm',
      resolve: {
        ourFirms,
      },
      data: {
        title: 'Аренда полуприцепов',
      },
    })

    $stateRegistry.register({
      name: 'ostfuel',
      url: '/ostfuel',
      component: 'ostFuelForm',
      resolve: {
        ourFirms,
      },
      data: {
        title: 'Остаток топлива',
      },
    })

    $stateRegistry.register({
      name: 'rashod',
      url: '/rashod',
      component: 'rashodToplivaForm',
      resolve: {
        ourFirms,
      },
      data: {
        title: 'Расход топлива',
      },
    })

    $stateRegistry.register({
      name: 'monthprobeg',
      url: '/monthprobeg',
      component: 'monthProbegForm',
      resolve: { ourFirms },
      data: {
        title: 'Пробег за месяц',
      },
    })

    $stateRegistry.register({
      name: 'osopzm',
      url: '/osopzm',
      component: 'osopzmForm',
      data: {
        title: 'Отчет страхователя о перевозках за месяц',
      },
    })

    $stateRegistry.register({
      name: 'otper2n',
      url: '/otper2n',
      component: 'otper2nForm',
      resolve: { ekspeditors },
      data: {
        title: 'Отчет 2 новый',
      },
    })

    $stateRegistry.register({
      name: 'otper2',
      url: '/otper2',
      component: 'otper2Form',
      data: {
        title: 'Отчет о перевозках 2',
      },
    })

    $stateRegistry.register({
      name: 'otper1',
      url: '/otper1',
      component: 'otper1Form',
      data: {
        title: 'Отчет о перевозках 1',
      },
    })

    $stateRegistry.register({
      name: 'otvyst',
      url: '/otvyst',
      component: 'otvystForm',
      resolve: { ekspeditors },
      data: {
        title: 'Отчет о выставленных счетах',
      },
    })

    $stateRegistry.register({
      name: 'neopl',
      url: '/neopl',
      component: 'neoplForm',
      resolve: { ekspeditors },
      data: {
        title: 'Неоплаченные счета',
      },
    })

    $stateRegistry.register({
      name: 'fuelCost',
      url: '/fuel-cost',
      component: 'fuelCostForm',
      resolve: {
        fuelCost: (fuelCostServiceApiV2) => fuelCostServiceApiV2.get({ id: 1 }),
      },
      data: {
        title: 'Цены на топливо',
      },
    })

    $stateRegistry.register({
      name: 'kintrans',
      url: '/kintrans',
      component: 'kintransList',
      data: {
        title: 'Банковские реквизиты',
      },
    })

    $stateRegistry.register({
      name: 'ctrlSchet',
      url: '/ctrl-schet',
      component: 'ctrlSchetForm',
      data: {
        title: 'Контроль невыставленных счетов',
      },
    })

    $stateRegistry.register({
      name: 'zayStatus',
      url: '/zay-status',
      component: 'zayStatus',
      data: {
        title: 'Статус заявок',
      },
      resolve: {
        managers,
      },
    })

    /**
     * Список наших фирм.
     * @ngInject
     * */
    function ourFirms(firmServiceApiV2) {
      return firmServiceApiV2.getOurFirms()
    }

    /** Все экспедиторы. @ngInject */
    function ekspeditors(ekspServiceApiV2) {
      return ekspServiceApiV2.get()
    }
  },
]).name

function managers(ekspServiceApiV2) {
  return ekspServiceApiV2.get()
}
