import { FormController } from '../../controllers/form-controller.class'

class SafetyBriefingFormController extends FormController {
  /** @ngInject */
  constructor($scope, $injector, putlistServiceApiV2) {
    super($scope, $injector)
    this.putlistServiceApiV2 = putlistServiceApiV2
  }

  /** Инициализация компонента. */
  $onInit() {}

  /** Сформировать отчет. */
  print() {
    const params = {}
    if (this.dateFrom) {
      params.date_from = $.fn.dateutils.date2sql(this.dateFrom)
    }
    if (this.dateTo) {
      params.date_to = $.fn.dateutils.date2sql(this.dateTo)
    }
    if (this.firm) {
      params.firm = this.firm
    }
    this.putlistServiceApiV2.getSafetyBriefingJournal(params)
  }

  close() {
    window.close()
  }
}

export const SafetyBriefingFormComponent = {
  template: require('./safety-briefing-form.component.html'),
  styles: [require('./safety-briefing-form.component.scss')],
  bindings: {
    onSave: '&',
    onClose: '&',
    ourFirms: '<',
  },
  controller: SafetyBriefingFormController,
}
