import { FormController } from '../../controllers/form-controller.class'

class ContractFormController extends FormController {
  /** @ngInject */
  constructor($scope, $injector, contractServiceApiV2) {
    super($scope, $injector)
    this.api = contractServiceApiV2
  }
}

export const ContractFormComponent = {
  template: require('./contract-form.component.html'),
  styles: [require('./contract-form.component.scss')],
  bindings: {
    client: '<',
    server: '<',
    model: '<',
    hiddenFirms: '<',
    onSave: '&',
    onClose: '&',
  },
  controller: ContractFormController,
}
