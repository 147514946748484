import { CRUDServiceAPI2 } from './crud-service.class'

export class FuelCostServiceApi2 extends CRUDServiceAPI2 {
  subject = 'fuel'

  parse = (inp) => {
    inp.cost = +inp.cost
    inp.park = +inp.park
    inp.komsut = +inp.komsut
    inp.cost2 = +inp.cost2
    inp.park2 = +inp.park2
    inp.komsut2 = +inp.komsut2
    return inp
  }

  format = (out) => {
    out.cost = out.cost.toFixed(2)
    out.park = out.park.toFixed(2)
    out.komsut = out.komsut.toFixed(2)
    out.cost2 = out.cost2.toFixed(2)
    out.park2 = out.park2.toFixed(2)
    out.komsut2 = out.komsut2.toFixed(2)
    return out
  }
}
