export const zayBaseState = {
  name: 'zay',
  url: '/zays',
  abstract: true,
}

const zayCommonResolve = {
  ourFirms: (firmServiceApiV2) => firmServiceApiV2.getOurFirms(),
  legacyUsers: (legacyUsersServiceApiV2) => legacyUsersServiceApiV2.get(),
  managers: (ekspServiceApiV2) => ekspServiceApiV2.get(),
}

export const zayCreateState = {
  name: 'zay.new',
  url: '/new',
  component: 'zayForm',
  resolve: Object.assign({}, zayCommonResolve, {}),
  data: {
    title: 'Новая заявка',
  },
}

export const zayDetailBaseState = {
  name: 'zay.base',
  url: '/{id}',
  abstract: true,
  resolve: {
    zay: ($stateParams, zaysServiceApiV2) => {
      return zaysServiceApiV2.get({ id: $stateParams.id })
    },
  },
}

export const zayDetailState = {
  name: 'zay.base.detail',
  url: '/edit',
  component: 'zayForm',
  resolve: Object.assign({}, zayCommonResolve, {
    plid: (zay, firmServiceApiV2) => {
      if (zay && zay.plid) {
        return firmServiceApiV2.get({ id: zay.plid })
      }
    },
    contracts: (zay, ourFirms, contractServiceApiV2) => {
      if (zay && zay.zaid) {
        const ourFirmsByCode = ourFirms.results.idlize((f) => f.kmbm)
        const server = ourFirmsByCode[zay.type]
        return contractServiceApiV2
          .get({ client: zay.zaid, server: server.id })
          .then((resp) => resp.results)
      }
    },
  }),
  data: {
    title: (state) => `Заявка № ${state.params().id}`,
  },
}
