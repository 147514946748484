import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

export class DriversService extends CRUDService {
  subject = 'drivers'

  parse = (p) => {
    p.id = parseInt(p.id)
    return p
  }
}

export class DriverServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'drivers'

  toHtml(driver) {
    return driver.fio
  }

  getSearchFilters(term) {
    return { fio: term }
  }
}
