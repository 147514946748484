import ComponentsModule from './components'
import ModelsModule from './models'
import ControllersModule from './controllers'
import FiltersModule from './filters'
import ServicesModule from './services'
import StatesModule from './states'

import ngSanitize from 'angular-sanitize'
import ngMessages from 'angular-messages'
import ngFileSaver from 'angular-file-saver'
import 'angularjs-toaster'
import 'ui-select'
import '@uirouter/angularjs'
import 'angular-popeye'
import 'angular-modal-service'
import '../style/style.scss'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { settings } from './app.config'
import angular from 'angular'

Sentry.init({
  dsn: settings.sentry.dsn,
  environment: settings.environment,

  // Alternatively, use `process.env.npm_package_version`
  // for a dynamic release version
  // if your build tool supports it.
  release: settings.version,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: settings.sentry.tracesSampleRate,
})

const app = angular.module('gruz', [
  'ui.select',
  'ui.router',
  'pathgather.popeye',
  'angularModalService',
  'toaster',
  ngFileSaver,
  ngMessages,
  ngSanitize,
  StatesModule,
  ComponentsModule,
  ControllersModule,
  ModelsModule,
  FiltersModule,
  ServicesModule,
])

app.constant('config', settings)

app.config(appConfig)

app.run(appRun)

function appConfig($httpProvider, uiSelectConfig) {
  uiSelectConfig.theme = 'select2'
  uiSelectConfig.resetSearchInput = true

  $httpProvider.interceptors.push('authHttpInterceptorService')
  $httpProvider.interceptors.push('busyCursorHttpInterceptor')
  $httpProvider.interceptors.push('toasterHttpInterceptor')
}

app.factory('toasterHttpInterceptor', [
  '$q',
  'toaster',
  function ($q, toaster) {
    const excludedUrl = (url) => {
      return /auth\/jwt/.test(url)
    }

    return {
      request: (config) => {
        return config
      },
      response: (response) => {
        const config = response.config

        if (excludedUrl(config.url)) {
          return response
        }

        if (config.method === 'POST' && response.status === 200) {
          toaster.pop({ body: 'Изменения сохранены.' })
        }
        return response
      },
      responseError: (rejection) => {
        const config = rejection.config

        if (rejection.status === 401) {
          return $q.reject(rejection)
        }

        if (rejection.status === 403) {
          toaster.pop({
            type: 'error',
            title: 'Ошибка доступа',
            body:
              rejection.data.detail ||
              'Недостаточно прав для совершения действия',
          })
        } else if (['POST', 'PUT'].includes(config.method)) {
          toaster.pop({ type: 'error', body: 'Ошибка сохранения.' })
        } else {
          toaster.pop({ type: 'error', body: 'Ошибка запроса.' })
        }
        return $q.reject(rejection)
      },
    }
  },
])

app.factory('busyCursorHttpInterceptor', [
  '$q',
  function ($q) {
    return {
      request: (config) => {
        $('html').addClass('progress')
        return config
      },
      response: (response) => {
        $('html').removeClass('progress')
        return response
      },
      responseError: (rejection) => {
        $('html').removeClass('progress')
        return $q.reject(rejection)
      },
    }
  },
])

function appRun($rootScope, $templateCache, $transitions) {
  // загрузка шаблонов сообщений об ошибках для полей форм
  $templateCache.put(
    'error-messages',
    require('./templates/error-messages.html')
  )

  $transitions.onSuccess({}, function ($transition) {
    // подстановка заголовка страницы из состояния
    const title = $transition.$to().data.title
    if (typeof title === 'function') {
      $rootScope.title = title($transition.targetState())
    } else {
      $rootScope.title = title
    }
  })
}
