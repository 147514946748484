import utils from '../../utils'

class SubshetFormController {
  NOT_SELECTED_ERROR = 'Пожалуйста, выберите счет'
  CONFIRM_DELETE_MSG = 'Пожалуйста, подтвердите удаление счета: '

  static $inject = [
    '$q',
    'zatratiService',
    'uslugiService',
    'zatratiModal',
    'zatratiPayModal',
    'zaysService',
    'firmServiceApiV2',
  ]

  /** Наши фирмы */
  ourFirms = {}
  /** Итоговая сумма выставленных счетов. */
  itogo = 0.0

  constructor(
    $q,
    zatratiService,
    uslugiService,
    zatratiModal,
    zatratiPayModal,
    zaysService,
    firmServiceApiV2
  ) {
    Object.assign(this, {
      $q,
      zatratiService,
      uslugiService,
      zatratiModal,
      zatratiPayModal,
      zaysService,
      firmServiceApiV2,
    })
    this.subshetTable = {
      options: {
        headers: {
          __verbose__firms: 'Субконтрактор',
          pid__firm: 'Фирма',
          nomer: '№ счета',
          data_fmt: 'Дата',
          pid: 'Заявка',
          summa: 'Сумма (руб.)',
          nds_fmt: 'НДС',
          plat: '№ платежки',
          dataplat_fmt: 'Дата платежки',
        },
        tablesorterOptions: {
          headers: {
            0: { sorter: 'typecomp' },
            1: { sorter: 'typecomp' },
            2: { sorter: 'nscheta' },
            3: { sorter: 'dd.mm.yyyy' },
            8: { sorter: 'dd.mm.yyyy' },
          },
        },
      },
      init$: $q.defer(),
      load$: $q.defer(),
      rowfmt: (zatr) => {
        const formattedZatr = { ...zatr }
        if (!this.ourFirms[zatr.__verbose__pid__type]) {
          console.warn('Look here!')
          console.log(angular.copy(this.ourFirms))
          console.trace()
        }
        Object.assign(formattedZatr, {
          nds_fmt: zatr.nds.trim() ? `${zatr.nds}%` : '',
          data_fmt: zatr.data
            ? $.fn.dateutils.date2str_ddmmyyyy(zatr.data, '.')
            : '',
          dataplat_fmt: zatr.dataplat
            ? $.fn.dateutils.date2str_ddmmyyyy(zatr.dataplat, '.')
            : '',
          pid__firm: this.ourFirms[zatr.__verbose__pid__type]._full_name,
        })
        return formattedZatr
      },
      service: zatratiService,
      ctrl: null,
    }
  }

  /** Выбранный счет. */
  get selected() {
    return this.subshetTable.ctrl.selectedObject
  }

  /** Выбран ли счет? */
  get isSelected() {
    return !!this.subshetId
  }

  filters = {
    zay: '',
    firm: '',
    nomer__icontains: '',
  }

  /** Инициализация компонента. */
  $onInit() {
    // получим контроллер таблицы и наши фирмы
    this.$q
      .all([
        this.subshetTable.init$.promise,
        this.firmServiceApiV2.getOurFirms(),
      ])
      .then(([ctrl, firms]) => {
        this.subshetTable.ctrl = ctrl
        this.ourFirms = firms.results.idlize((f) => f.kmbm)
        ctrl.reload()
      })
  }

  /**
   * Вычисление итоговой суммы счетов.
   * @param {*} data данные таблицы
   */
  calcTotal(data) {
    this.itogo = data
      .map((s) => s.summa)
      .reduce((prev, current) => prev + current, 0.0)
      .toFixed(2)
  }

  /** Создание/Редактирование счета. */
  editSchet(subschet) {
    const modal = this.zatratiModal.open({
      locals: {
        subschet,
        options: {
          canEditZay: true,
        },
      },
      resolve: {
        zay: () =>
          this.filters.zay
            ? this.zaysService.get({ id: this.filters.zay })
            : $.when(null),
        usltypes: () => this.uslugiService.get({}),
      },
    })
    modal.closed.then(this.subshetTable.ctrl.addOrUpdateRow)
  }

  /** Дубликат счета. */
  copySchet() {
    const schet = this.selected
    const newschet = {}
    Object.assign(newschet, schet, {
      id: null,
      pid: null,
      dataplat: null,
      plat: null,
      summa: null,
    })
    this.editSchet(newschet)
  }

  /** Оплата счета. */
  paySchet() {
    const modal = this.zatratiPayModal.open({
      locals: {
        subschet: this.selected,
      },
    })
    modal.closed.then(this.subshetTable.ctrl.addOrUpdateRow)
  }

  /** Печать счета. */
  printSchet() {
    utils.print('subschet', `Счета субконтракторов ${utils.date.today_s()}`, {
      zay: this.filters.zay,
      firm: this.filters.firm,
      nomer__icontains: this.filters.nomer__icontains,
    })
  }

  /** Удаление счета. */
  deleteSchet() {
    const schet = this.selected
    if (!confirm(this.CONFIRM_DELETE_MSG + schet.nomer)) return

    this.zatratiService.delete(schet.id).then(() => {
      this.subshetTable.ctrl.removeRow(schet.id)
    })
  }

  /** Сброс фильтров. */
  resetFilters() {
    this.filters = {
      zay: '',
      firm: '',
      nomer__icontains: '',
    }
  }

  /** Фильтрация по выбранной фирме. */
  filterBySelectedFirm() {
    this.filters.firm = this.selected.firms
  }
}

export const SubshetFormComponent = {
  template: require('./subshet-form.component.html'),
  styles: [require('./subshet-form.component.css')],
  controller: SubshetFormController,
  controllerAs: 'vm',
}
