/** @ngInject Контроллер для абстрактной формы. */
export class FormController {
  /** Сервис API */
  api
  /** Редактируемая модель. */
  model
  /** Сохраненный экземпляр модели. */
  oldModel
  /** Ключевое поле модели. */
  modelKeyProp = 'id'

  /** Конструктор контроллера. */
  constructor($scope, $injector) {
    this.$scope = $scope
    this.$injector = $injector
    $scope.vm = this
  }

  /** Контроллер формы. */
  get form() {
    return this.$scope.form
  }

  /**
   * Сохранение формы.
   * @returns {Promise<Object>} сохраненная модель.
   * */
  save() {
    return new Promise((resolve, reject) => {
      if (!this.form.$valid) {
        reject(new Error('Invalid form'))
      } else {
        this.api.save(this.model).then((model) => {
          this.oldModel = Object.assign({}, this.model)
          this.model = model
          if (this.onSave) {
            this.onSave({ model: this.model })
          }
          resolve(model)
        })
      }
    })
  }

  /**
   * Удаление модели по ключу.
   * @returns {Promise<any>} значение ключа.
   * */
  delete() {
    const key = this.model[this.modelKeyProp]
    return this.api.delete(key)
  }

  /** Закрытие окна. */
  close() {
    if (this.onClose) {
      this.onClose({})
    }
  }

  /** Отмена изменений. */
  cancel() {
    this.model = Object.assign({}, this.oldModel)
  }

  /** Есть ли изменения в форме. */
  get hasChanges() {
    return JSON.stringify(this.model) !== JSON.stringify(this.oldModel)
  }

  $onChanges(changes) {
    if (changes.model) {
      this.oldModel = Object.assign({}, changes.model.currentValue)
    }
  }

  $onInit() {
    // При добавлении функциональности сюда нужно убедиться, что
    // дочерние классы, переопределяющие этот метод, корректно вызывают
    // super.$onInit(), т.к. некоторые из них могут этого не делать
  }
}

/**
 * Форма в отдельном окне.
 *
 * Форма связывается с родительской формой через промис `window.dialog`.
 */
export class WindowFormController extends FormController {
  save(params = {}) {
    // нужно обновить родительское окно
    return super.save().then((data) => {
      if (window.dialog) {
        window.dialog.resolve(data)
      }
      if (params.close) {
        window.close()
      }
      return data
    })
  }

  close() {
    window.close()
  }
}

export class ModalFormCtrl extends FormController {
  /**
   * Сохранение формы.
   * @returns {Promise<Object>} сохраненная модель.
   * */
  save() {
    return super.save().then((model) => {
      const modelCopy = JSON.parse(JSON.stringify(model))
      this.onClose({ model: modelCopy })
      return modelCopy
    })
  }
}
