import { CRUDServiceAPI2 } from './crud-service.class'
import { DateTime } from 'luxon'
import utils from '../utils'

/** Сервис для договоров на основе ApiV2 */
export class ContractServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'contracts'

  parse = (o) => {
    o.ot = utils.db.str2date(o.ot)
    o.do = utils.db.str2date(o.do)
    o._name =
      (o.is_default ? '* ' : '') +
      o.nomer +
      ' (с ' +
      (o.ot ? $.fn.dateutils.date2str_ddmmyyyy(o.ot, '.') : '...') +
      ' по ' +
      (o.do ? $.fn.dateutils.date2str_ddmmyyyy(o.do, '.') : '...') +
      ')'
    return o
  }

  format = (o) => {
    o.ot = o.ot ? DateTime.fromJSDate(o.ot).toISODate() : null
    o.do = o.do ? DateTime.fromJSDate(o.do).toISODate() : null
    return o
  }

  getSearchFilters(text) {
    return { nomer: text }
  }

  toHtml(contract) {
    const info = []
    info.push(`Номер: <b>${contract.nomer}</b>`)
    if (contract.client) {
      info.push(`Заказчик: ${contract.client_info._full_name}`)
    }
    if (contract.server) {
      info.push(`Исполнитель: ${contract.server_info._full_name}`)
    }
    if (contract.ot) {
      const ot = $.fn.dateutils.date2str_ddmmyyyy(contract.ot, '.')
      info.push(`Начало действия: ${ot}`)
    }
    if (contract.do) {
      const do_ = $.fn.dateutils.date2str_ddmmyyyy(contract.do, '.')
      info.push(`Окончание действия: ${do_}`)
    }
    return info.join('<br>')
  }
}
