// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class CtrlSchetFormController extends PrintFormController {
  reportName = 'kvnytr'

  get defaultFilters() {
    return {
      from: firstDayOfMonth(),
      to: lastDayOfMonth(),
      firm: null,
      excludeInnerRashod: false,
    }
  }

  get convertedFilters() {
    return {
      d1: utils.db.date2dmy(this.filters.from),
      d2: utils.db.date2dmy(this.filters.to),
      firm: this.filters.firm,
      vn: this.filters.excludeInnerRashod ? '1' : '',
    }
  }
}

export const CtrlSchetFormComponent = {
  template: require('./ctrl-schet-form.component.html'),
  styles: [require('./ctrl-schet-form.component.scss')],
  bindings: {},
  controller: CtrlSchetFormController,
}
