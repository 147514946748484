import { CRUDServiceAPI2 } from './crud-service.class'

export class EquipmentServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'equipment/items'

  attachmentSubject = 'attachments'
  imageSubject = 'images'
  sketchSubject = 'sketches'
  nameplateSubject = 'nameplates'

  save(item) {
    const formData = new FormData()

    formData.append('name', item.name)
    formData.append('description', item.description)
    if (item.quantity) {
      formData.append('quantity', item.quantity)
    }
    if (item.length) {
      formData.append('length', item.length)
    }
    if (item.width) {
      formData.append('width', item.width)
    }
    if (item.height) {
      formData.append('height', item.height)
    }
    if (item.weight) {
      formData.append('weight', item.weight)
    }
    if (item.group) {
      formData.append('group', item.group)
    }
    if (item.image) {
      formData.append('image', item.image)
    }

    if (item.id) {
      const url = `${this.baseUrl}/${this.subject}/${item.id}`
      return this.$http
        .put(url, formData, this.getMultipartHeaders())
        .then((resp) => this.saveFiles(resp, item))
    } else {
      const url = `${this.baseUrl}/${this.subject}`
      return this.$http
        .post(url, formData, this.getMultipartHeaders())
        .then((resp) => this.saveFiles(resp, item))
    }
  }

  /**
   * Сохранение всех прикрепленных файлов
   * @param resp Результат запроса на сохранение/добавление изделия
   * @param item Дополнительные данные для изделия
   */
  saveFiles(resp, item) {
    const deferred = this.$q.defer()
    const itemId = resp.data.id

    this.$q
      .all([
        this.saveAttachments(itemId, item.attachments, this.attachmentSubject),
        this.saveAttachments(itemId, item.images, this.imageSubject),
        this.saveAttachments(itemId, item.sketches, this.sketchSubject),
        this.saveAttachments(itemId, item.nameplates, this.nameplateSubject),
      ])
      .then((resps) => {
        // возвращаем изначальный результат, т.к. именно его нам
        // надо вернуть
        deferred.resolve([resp.data, resps])
      })
    return deferred.promise
  }

  /**
   * Дополнительные файлы
   * @param itemId
   * @param attachments
   * @param endpoint
   */
  saveAttachments(itemId, attachments, endpoint) {
    const deferred = this.$q.defer()
    const url = `${this.baseUrl}/${this.subject}/${itemId}/${endpoint}`

    if (attachments) {
      for (const key in attachments) {
        const attachment = attachments[key]
        if (attachment instanceof File) {
          const formData = new FormData()
          formData.append('name', attachment.name)
          formData.append('file', attachment)
          this.$http
            .post(url, formData, this.getMultipartHeaders())
            .then((resp) => {
              deferred.resolve(resp.data)
            })
        }
      }
    } else {
      deferred.resolve(null)
    }
    return deferred.promise
  }

  printPacking(data) {
    const url = `${this.baseUrl}/${this.subject}/packing`
    return this.$http({
      url,
      method: 'POST',
      data,
      headers: {
        Accept: 'application/vnd.ms-excel',
      },
      responseType: 'blob',
    }).then((resp) => {
      this.FileSaver.saveAs(resp.data, 'Паккинг.xlsx')
    })
  }
}
