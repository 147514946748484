export function RealUslController($scope, $q, $http, $timeout) {
  const MONTHLIST = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ]

  const vm = {
    keens: null,
    zayfirm: 'tm',
    schetfirm: 'tm',
    zay: null,
    reals: $('#reals'),
    total: 0,
    m1: null,
    m2: null,
    month: MONTHLIST.map((m, i) => ({ num: i + 1, name: m })),
    init: () => {
      vm.reals
        .jsTable({
          headers: {
            nomer: '№ счета',
            data: 'Дата',
            pid: 'Заявка',
            summa: 'Сумма, руб',
          },
          tablesorterOptions: {
            headers: {
              // 0: { sorter: 'nscheta' },
              1: { sorter: 'dd.mm.yyyy' },
              3: { sorter: 'csnum' },
            },
            sortList: [[1, 0]],
          },
        })
        .jsTable('loadRows', [])

      $q.all([$http.get('api.php?url=keen')]).then((resps) => {
        vm.keens = keens2kodname(resps[0].data)
      })
    },
    search: () => {
      search().then((resp) => {
        // filter T* счета
        const data = resp.data.filter((d) => {
          // первая T - латинская, вторая на всякий случай - кириллическая
          return d.nomer[0] === 'T' || d.nomer[0] === 'Т'
        })

        vm.total = $.fn.numberWithCommas(
          data.length
            ? data
                .map((i) => parseFloat(i.summa))
                .reduce((total, next) => {
                  return total + next
                })
                .toFixed(2)
            : 0.0
        )

        data.forEach((d) => (d.summa = $.fn.numberWithCommas(d.summa)))
        vm.reals.jsTable('loadRows', data)
      })
    },
  }
  $scope.vm = vm

  vm.init()

  function search() {
    const params = {}
    if (vm.zay) params.zay = vm.zay
    if (vm.zayfirm) params.zay_kmbm = vm.zayfirm
    if (vm.schetfirm) params.kmbm = vm.schetfirm
    if (vm.m1) params.m1 = vm.m1
    if (vm.m2) params.m2 = vm.m2
    return $http.get(`api.php?url=zatrati&${$.param(params)}`)
  }

  function keens2kodname(keens) {
    return keens
      .reduce((u, i) => {
        return u.find((j) => j.kod === i.kod) ? u : [...u, i]
      }, [])
      .map(({ kod, comp }) => ({ kod, comp }))
  }
}

export const SaleOfServicesComponent = {
  template: require('./sale-of-services.component.html'),
  styles: [require('./sale-of-services.component.scss')],
  bindings: {},
  controller: RealUslController,
}
