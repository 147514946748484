import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

export class PricepsService extends CRUDService {
  subject = 'priceps'

  parse = (p) => {
    p.id = parseInt(p.id)
    return p
  }
}

export class PricepServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'priceps'

  toHtml(pricep) {
    return (
      `${pricep.nomer} ` +
      `<div class="comment">${pricep.firm_info.full_name}</div>`
    )
  }

  getSearchFilters(term) {
    return { nomer: term }
  }
}
