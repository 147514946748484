export const ttnBaseState = {
  name: 'zay.base.ttn',
  url: '/ttn',
  abstract: true,
}

export const ttnNewState = {
  name: 'zay.base.ttn.new',
  url: '/new',
  component: 'ttnForm',
  data: {
    title: 'Новая накладная',
  },
}

export const ttnDetailState = {
  name: 'zay.base.ttn.detail',
  url: '/{nomer}',
  component: 'ttnForm',
  resolve: {
    ttn: ($stateParams, zay, zaysServiceApiV2) => {
      return zaysServiceApiV2.getTtnService(zay.id).get({
        nomer: $stateParams.nomer,
      })
    },
  },
  data: {
    title: (state) => {
      const params = state.params()
      return `Накладная № ${params.nomer} по заявке № ${params.id}`
    },
  },
}
