import { GroupItem } from '../../models/groupItem'
import {
  EQUIPMENT_REGISTRY,
  EQUIPMENT_GROUP_LEVELS,
  EQUIPMENT_ADMIN,
} from '../../constants'

/** @ngInject */
class EquipmentGroupsController {
  LEVELS = EQUIPMENT_GROUP_LEVELS

  constructor(
    $scope,
    $state,
    addGroupModal,
    equipmentAddModal,
    groupServiceApiV2,
    equipmentServiceApiV2,
    currentUser,
    popup
  ) {
    Object.assign(this, {
      $scope,
      $state,
      addGroupModal,
      equipmentAddModal,
      groupServiceApiV2,
      equipmentServiceApiV2,
      currentUser,
      popup,
    })
    this.$scope.vm = this

    // По умолчанию все доступы отключены
    this.permissions = {
      canAddType: false,
      canAddGroup: false,
      canAddSubgroup: false,
      canAddEquipmentItem: false,
    }
    this.hasAccess = false
  }

  get groupItem() {
    if (this.groupItemModel === undefined) {
      this.groupItemModel = new GroupItem(this.equipmentGroup)
    }
    return this.groupItemModel
  }

  get title() {
    return this.equipmentGroup.name
  }

  $onInit() {
    this._setPermissions()
    this.hasAccess = this.checkAccess()
    this.hasAdminAccess = this.checkAdminAccess()
  }

  /**
   * Проверка доступа к панели управления реестром
   * @returns {boolean}
   */
  checkAccess() {
    return this.currentUser.roles.includes(EQUIPMENT_REGISTRY)
  }

  /**
   * Проверка на администратора реестра оборудования
   * @returns {boolean}
   */
  checkAdminAccess() {
    const isRegistryAdmin = this.currentUser.roles.includes(EQUIPMENT_ADMIN)
    return this.checkAccess() && isRegistryAdmin
  }

  /**
   * Редактирование объекта
   */
  openEditGroupModal() {
    const item = angular.copy(this.groupItem)
    this.openGroupModal(item)
  }

  /**
   * Добавление дочернего элемента к объекту
   */
  openAddGroupModal() {
    const item = angular.copy(this.groupItem)
    item.parentId = item.id
    delete item.id
    this.openGroupModal(item)
  }

  /**
   * Открываем модальное окно для добавления групп
   * @param {} groupItem
   * @returns
   */
  openGroupModal(groupItem) {
    const modal = this.addGroupModal.open({
      locals: {
        groupItem,
      },
    })
    return modal
  }

  /**
   * Модальное окно для добавления изделий
   * @returns
   */
  openEquipmentAddModal() {
    const modal = this.equipmentAddModal.open({
      locals: {
        equipments: this.equipmentItems,
        groupItem: this.groupItem,
        equipmentItem: {},
      },
    })
    return modal.closed.then((eqItem) => {
      this.equipmentGroupItems.results.push(eqItem)
      this.equipmentGroupItems.count += 1
    })
  }

  /**
   * Удаление группы
   */
  deleteGroup() {
    if (
      this.permissions.canDelete &&
      confirm(`Удалить страницу ${this.groupItem.name}`)
    ) {
      this.groupServiceApiV2.delete(this.groupItem.id).then((resp) => {
        this.$state.go('equipmentGroup', { id: this.parentEquipmentGroup.id })
      })
    }
  }

  /**
   * Удаление pdf-файла богопротивными методом
   */
  deletePdf() {
    if (confirm(`Удалить pdf-файл?`)) {
      const data = {
        id: this.groupItem.id,
        name: this.groupItem.name,
        document: '',
      }
      this.groupServiceApiV2.save(data).then((resp) => {
        this.$state.reload()
      })
    }
  }

  /**
   * Установка разрешений и ограничений
   */
  _setPermissions() {
    const hasNoChildren = this.groupItem.children.length === 0
    const hasNoEquipmentItems = this.equipmentGroupItems.count === 0

    // Можем добавлять изделие, если нет дочерних групп
    this.permissions.canAddEquipmentItem = true // hasNoChildren;
    this.permissions.canAddType = this.groupItem.isBaseItem()
    this.permissions.canAddGroup = this.groupItem.isType()
    this.permissions.canAddSubgroup = this.groupItem.isGroup()

    // Можем удалять если только нет дочерних групп и изделий
    this.permissions.canDelete = hasNoChildren && hasNoEquipmentItems
  }

  /**
   * Создать паккинг.
   *
   * Открывается страница для создания паккинга.
   */
  createPacking() {
    this.popup.state('packing.form')
  }
}

export const EquipmentGroupsComponent = {
  template: require('./equipment-groups.component.html'),
  styles: [require('./equipment-groups.component.scss')],
  bindings: {
    equipmentGroup: '<',
    equipmentGroupItems: '<',
    parentEquipmentGroup: '<',
  },
  controller: EquipmentGroupsController,
}
