import { settings } from '../app.config'

export class CRUDService {
  subject = ''
  cacheEnabled = false
  cache = null

  parse = (input) => input
  format = (output) => output

  static $inject = ['$http', '$cacheFactory']
  constructor($http, $cacheFactory) {
    this.$http = $http
    this.$cacheFactory = $cacheFactory
  }

  get(filters) {
    // clear null properties
    filters = filters || {}
    filters = $.fn.objectFromEntries(
      Object.entries(filters).filter((e) => e[1] != null)
    )

    const url = `api.php?url=${this.subject}&${$.fn.buildQuery(filters)}`
    const responseProcessor = (resp) => {
      if (!resp.data) {
        return resp.data
      }
      if (resp.data instanceof Array) {
        resp.data.forEach(this.parse)
        return resp.data
      } else {
        return this.parse(resp.data)
      }
    }

    if (this.cacheEnabled) {
      if (!this.cache) {
        this.cache = this.$cacheFactory(this.subject)
      }
      const cached = this.cache.get(url)
      if (cached !== undefined) {
        return responseProcessor(cached)
      }
    }

    return this.$http.get(url).then((resp) => {
      if (this.cacheEnabled) {
        this.cache.put(url, resp)
      }

      return responseProcessor(resp)
    })
  }

  save(obj) {
    const parsed = this.format(Object.assign({}, obj))
    let id = parsed.id
    if (!id) {
      id = ''
      delete parsed.id
    }
    const url = `api.php?url=${this.subject}&id=${id}`
    return this.$http.post(url, parsed).then((resp) => {
      return this.parse(resp.data)
    })
  }

  delete(id) {
    const url = `api.php?url=${this.subject}&id=${id}`
    return this.$http.delete(url).then(() => id)
  }
}

/**
 * Класс для абстрактного сервиса на основе APIv2
 */
export class CRUDServiceAPI2 {
  /** Базовый URL. */
  baseUrl = `${settings.apiUrl}/api/v2`
  /** Имя ключевого поля. */
  key_arg = 'id'

  parse = (input) => input
  format = (output) => output

  /** @ngInject */
  constructor($q, $http, FileSaver, printer) {
    Object.assign(this, { $q, $http, FileSaver, printer })
  }

  /** Кусочек URL сервиса. */
  get subject() {
    return ''
  }

  /**
   * Получение объектов.
   * @param {*} filters словарь фильтров
   * @returns {Promise<*>} объекты сервиса
   */
  get(filters) {
    // clear null properties
    filters = filters || {}
    filters = $.fn.objectFromEntries(
      Object.entries(filters).filter((e) => e[1] != null)
    )

    let url = `${this.baseUrl}/${this.subject}`

    const key = filters[this.key_arg]
    if (key) {
      delete filters[this.key_arg]
      url += `/${key}?`
    } else {
      url += '?'
    }
    url += `${$.fn.buildQuery(filters)}`
    return this.$http.get(url).then((resp) => {
      if (key) {
        return this.parse(resp.data)
      } else {
        resp.data.results.forEach(this.parse)
        return resp.data
      }
    })
  }

  /**
   * Сохранение объекта.
   * @param {*} obj объект для сохранения
   * @returns {Promise<*>} сохраненный объект
   */
  save(obj) {
    const formatted = this.format(Object.assign({}, obj))
    const key = formatted[this.key_arg]
    if (key) {
      const url = `${this.baseUrl}/${this.subject}/${key}`
      return this.$http
        .put(url, formatted)
        .then((resp) => this.parse(resp.data))
    } else {
      const url = `${this.baseUrl}/${this.subject}`
      return this.$http
        .post(url, formatted)
        .then((resp) => this.parse(resp.data))
    }
  }

  /**
   * Патч объекта.
   * @param {*} obj объект для патча
   * @returns {Promise<*>} пропатченный объект
   */
  patch(obj) {
    const key = obj[this.key_arg]
    const url = `${this.baseUrl}/${this.subject}/${key}`
    return this.$http.patch(url, obj).then((resp) => this.parse(resp.data))
  }

  /**
   * Удаление объекта.
   * @param {*} key идентификатор
   * @returns {Promise<number>} идентификатор
   */
  delete(key) {
    const url = `${this.baseUrl}/${this.subject}/${key}`
    return this.$http.delete(url).then(() => key)
  }

  /**
   * Заголовки Multipart/Form-data
   * @returns {*}
   */
  getMultipartHeaders() {
    return {
      // это обязательно, чтобы не затронуть сериализацию multipart
      transformRequest: angular.identity,
      // это для установки Content-Type: multipart/form-data
      headers: { 'Content-Type': undefined },
    }
  }
}
