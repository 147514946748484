import PopeyeModal from '../popeye-modal.class'

class PricepModalController {
  static $inject = ['$scope', 'pricep']

  constructor($scope, pricep) {
    this.$scope = $scope
    this.pricep = pricep
    $scope.vm = this
  }
}

export class PricepModal extends PopeyeModal {
  modal = {
    template: require('./pricep.modal.html'),
    controller: PricepModalController,
  }
}
