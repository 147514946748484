export class GroupItem {
  name = ''
  depth = 0

  constructor(data) {
    Object.assign(this, data)
  }

  getPdfPath() {
    return true
  }

  isBaseItem() {
    return this.depth === 1
  }

  isType() {
    return this.depth === 2
  }

  isGroup() {
    return this.depth === 3
  }

  isSubGroup() {
    return this.depth >= 4
  }
}
