export function FirmInnSearchDialogCtrl($scope, $http, $timeout) {
  const vm = this

  vm.zatrmode = true
  vm.searchType = 'name'

  vm.searchINN = searchINN
  vm.searchName = searchName
  vm.research = research
  vm.submit = submit
  vm.cancel = cancel

  const $table = $('#results')
  $table.jsTable({
    headers: {
      value: 'ИНН',
      label: 'Фирма',
    },
  })
  $table.jsTable('option', 'onRowClick', selectFirm)
  $table.jsTable('option', 'onRowDblClick', onRowDblClick)
  $table.jsTable('loadRows', [])

  vm.selected = null

  function research() {
    if (vm.searchType === 'name') {
      return searchName()
    } else {
      return searchINN()
    }
  }

  function searchINN() {
    vm.name = null

    // если меньше 2 знаков введено - то не ищем
    if (vm.inn < 10) {
      return
    }

    return $http
      .get(
        'ajax.php?url=subshet&action=search_inn&term=' +
          vm.inn +
          '&zatrmode=' +
          (vm.zatrmode === true ? 1 : 0)
      )
      .then(function (resp) {
        $table.jsTable('loadRows', resp.data)
      })
  }

  function searchName() {
    vm.inn = null

    if (vm.name.length < 2) {
      return
    }

    return $http
      .get(
        'ajax.php?url=subshet&action=search_name&term=' +
          vm.name +
          '&zatrmode=' +
          (vm.zatrmode === true ? 1 : 0)
      )
      .then(function (resp) {
        $table.jsTable('loadRows', resp.data)
      })
  }

  function submit() {
    window.dialog.resolve(vm.selected)
    window.close()
  }

  function cancel() {
    window.close()
  }

  function selectFirm(e) {
    $timeout(function () {
      const row = $table.jsTable('getSelectedRowData')
      vm.selected = row
    })
  }

  function onRowDblClick(e) {
    $timeout(function () {
      const row = $table.jsTable('getSelectedRowData')
      vm.selected = row
      vm.submit()
    })
  }
}
