export function DatePickerDirective($timeout) {
  return {
    restrict: 'A',
    link,
    require: '?ngModel',
    scope: {
      ngModel: '=',
      onSelect: '&',
    },
  }

  function link(scope, el, attrs, ngModel) {
    if (!ngModel) {
      return
    }

    const $el = $(el[0])

    ngModel.$render = $render

    init()

    function init() {
      $el.datepicker({
        dateFormat: 'd.mm.yy',
        changeMonth: true,
        changeYear: true,
        onSelect: function (dateStr) {
          const date = $.fn.dateutils.str_ddmmyyyy2date(dateStr, '.')

          ngModel.$setViewValue(date)

          $timeout(function () {
            onSelect(dateStr)
          })
        },
      })
    }

    function $render() {
      if (!ngModel.$viewValue) {
        $el.val(null)
      } else {
        const date = new Date(ngModel.$viewValue)
        $el.datepicker('setDate', date)
      }
    }

    function onSelect(dateStr) {
      if (!scope.onSelect) {
        return
      }

      const ts = !dateStr ? null : str2ts(dateStr)
      scope.onSelect({ date: ts })
    }
  }
}

// function ts2str (timestamp) {
//   const d = new Date(timestamp * 1000)
//   return d.getDate() + '.' + (d.getMonth() + 1) + '.' +
//     d.getFullYear()
// }

function str2ts(dateStr) {
  const parts = dateStr.split('.')
  if (parts.length !== 3) {
    throw new Error('Wrong date format')
  }
  const isoDateStr = parts.reverse().join('/')
  return new Date(isoDateStr).getTime() / 1000
}

// })();
