import { WindowFormController } from '../../controllers/form-controller.class'
import utils, { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class PricProbegController extends WindowFormController {
  constructor($scope, $injector, printer) {
    super($scope, $injector)
    this.printer = printer
  }

  $onInit() {
    this.filters = {
      date_from: firstDayOfMonth(),
      date_to: lastDayOfMonth(),
    }
  }

  print() {
    this.printer.print('pric_probeg', this._convertDates(this.filters))
  }

  /** Адаптация фильтров под старый формат даты. */
  _convertDates(filters) {
    return Object.assign({}, filters, {
      date_from: utils.db.date2dmy(filters.date_from),
      date_to: utils.db.date2dmy(filters.date_to),
    })
  }
}

export const PricProbegComponent = {
  template: require('./pric-probeg.component.html'),
  styles: [require('./pric-probeg.component.scss')],
  bindings: {},
  controller: PricProbegController,
}
