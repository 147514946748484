import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'
import utils from '../utils'

export class UchetService extends CRUDService {
  subject = 'uchet'

  parse = (u) => {
    u.id = parseInt(u.id)
    u.data = $.fn.dateutils.str_yyyymmdd2date(u.data, '-')
    // u.inner = parseInt(u.inner);     // problems with select options
    u.nomer = parseInt(u.nomer)
    u.nscheta = parseInt(u.nscheta)
    u.pid = parseInt(u.pid)
    u.summa = parseFloat(u.summa)
    u.veksp = parseFloat(u.veksp)
    if (u.is_subschet) {
      u.is_subschet = parseInt(u.is_subschet)
    }
    return u
  }

  format = (o) => {
    o.data = $.fn.dateutils.date2str_yyyymmdd(o.data, '-')
    o.veksp = o.inner !== '1' ? parseFloat(o.veksp) : 0
    if (!o.veksp) {
      o.veksp = 0
    }
    return o
  }

  deleteZopl(uchet) {
    const that = this
    return this.$http
      .delete(
        `api.php?url=uchet-zopl&s_schet=${uchet.nscheta}&firm=${uchet.firm}`
      )
      .then((resp) => {
        return that.get({
          nscheta: uchet.nscheta,
          firm: uchet.firm,
          __verbose__nzopls: 1,
          __verbose__plat: 1,
        })
      })
      .then((uchets) => {
        return uchets[0]
      })
  }
}

export class UchetServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'bills'

  act(uchet, params = {}) {
    return this.$http({
      url: `${this.baseUrl}/${this.subject}/${
        uchet.id
      }/act?${utils.url.buildQuery(params)}`,
      method: 'GET',
      responseType: 'blob',
    }).then((resp) => {
      this.FileSaver.saveAs(resp.data, `Акт №${uchet.nscheta}.xlsx`)
    })
  }
}
