import utils from '../utils'

/**
 * Директива для перевода объекта Date в строку ГГГГ-ММ-ДД и наоборот.
 * Для APIv2 полей с типом DATE.
 */
export function ISOStringToDateDirective() {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$parsers.push(utils.db.date2str)
      ngModel.$formatters.push(utils.db.str2date)
    },
  }
}

/**
 * Директива для перевода объекта Date в строку ДД.ММ.ГГГГ и наоборот.
 * (для старых полей, где дата хранилась в текстовом поле в виде ДД.ММ.ГГГГ).
 */
export function DMYStringToDateDirective() {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$parsers.push(parser)
      ngModel.$formatters.push(formatter)

      /**
       * Парсер.
       * @param {Date} value
       * @returns {string}
       */
      function parser(value) {
        if (!value) {
          return null
        }
        return utils.db.date2dmy(value)
      }

      /**
       * Форматтер.
       * @param {string} value
       * @returns {Date}
       */
      function formatter(value) {
        if (!value) {
          return null
        }
        return utils.db.dmy2date(value)
      }
    },
  }
}
