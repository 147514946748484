import { CRUDService } from './crud-service.class'

export class UslugiService extends CRUDService {
  subject = 'uslugi'
  cacheEnabled = true

  parse = (o) => {
    o.id = parseInt(o.id)
    return o
  }

  format = (o) => {
    return o
  }
}
