import PopeyeModal from '../popeye-modal.class'

export default class UchetModal extends PopeyeModal {
  modal = {
    template: require('./usl.modal.html'),
    controller: function ($scope, usl, zay, uchet, uslType) {
      const vm = {
        usl: { ...usl },

        saveUsl: () => {
          $scope.$close({ ...vm.usl })
        },
        getFromUchet: () => {
          vm.usl.summ = uchet.summa - uchet.veksp
        },
        getUslText: () => {
          let usltext = uslType.tdesc + ''

          if (uslType.type === 'Перевозка негабаритов') {
            usltext += zay.pergruz ? ` "${zay.pergruz}"` : ''
            usltext += zay.marshrut ? ` по маршруту ${zay.marshrut}` : ''
          }
          vm.usl.text = usltext
        },
      }

      if (isNaN(vm.usl.nds)) {
        vm.usl.nds = ''
      }

      $scope.vm = vm
    },
  }
}
