export const settings = {
  /** Ссылка на ApiV2. */
  apiUrl: API_URL,
  /** Среда (dev, prod, local). */
  environment: ENVIRONMENT,
  /** Настройки Sentry. */
  sentry: {
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  },
  /** Версия программы. */
  version: VERSION,
  /** Отчетный год. */
  defaultYear: parseInt(DEFAULT_YEAR),
}
