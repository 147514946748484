import { FormController } from '../../controllers/form-controller.class'
import { PRICEP_TYPES, PRICEP_STATUS_OPTIONS } from '../../constants'

/**
 * Форма для редактирования прицепа.
 */
class PricepFormController extends FormController {
  STATUS_OPTIONS = PRICEP_STATUS_OPTIONS
  PRICEP_TYPES = PRICEP_TYPES

  /** @ngInject */
  constructor($scope, $injector, $stateParams, pricepServiceApiV2) {
    super($scope, $injector)
    this.$stateParams = $stateParams
    this.api = pricepServiceApiV2
  }

  /** Инициализация компонента. */
  $onInit() {
    this.model = Object.assign(
      {
        pid: this.$stateParams.firmId,
        status: 'gotov',
        pricep_type: 'REGULAR',
        weight: 0,
        weightpts: 0,
        arenda: 0,
      },
      this.model
    )
  }
}

export const PricepFormComponent = {
  template: require('./pricep-form.component.html'),
  styles: [require('./pricep-form.component.scss')],
  bindings: {
    onSave: '&',
    onClose: '&',
    model: '<',
  },
  controller: PricepFormController,
}
