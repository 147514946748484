import { CRUDService } from './crud-service.class'

export class ZatratiService extends CRUDService {
  subject = 'zatrati'

  parse = (p) => {
    p.id = parseInt(p.id)
    p.pid = parseInt(p.pid)
    p.summa = parseFloat(p.summa)
    p.type = parseInt(p.type)
    p.firms = parseInt(p.firms)
    p.plat = p.plat ? parseInt(p.plat) : null
    p.data = p.data ? $.fn.dateutils.str_ddmmyyyy2date(p.data, '.') : new Date()
    if (p.dataplat) {
      p.dataplat = $.fn.dateutils.str_ddmmyyyy2date(p.dataplat, '.')
    }

    if (p.nds.trim() !== '') {
      p.nds = '' + parseFloat(p.nds.split('%')[0])
    }
    p.uchet_id = parseInt(p.uchet_id)
    return p
  }

  format = (o) => {
    o.data = $.fn.dateutils.date2str_ddmmyyyy(o.data, '.')
    if (o.dataplat) {
      o.dataplat = $.fn.dateutils.date2str_ddmmyyyy(o.dataplat, '.')
    }

    if (o.nds == null || o.nds === undefined) {
      o.nds = ''
    }
    o.nds = o.nds.trim() !== '' ? o.nds + '%' : ' '

    return o
  }

  /** Оплата счета. */
  pay(payoff) {
    return this.save(
      Object.assign(
        {
          action: 'pay',
        },
        payoff
      )
    )
  }

  /** Частичная оплата счета. */
  partialPay(payoff) {
    return this.save(
      Object.assign(
        {
          action: 'part_pay',
        },
        payoff
      )
    )
  }
}
