import { printUrl } from '../popups'

/** @ngInject */
export function TehosmotrController($scope, GruzAvto, GruzPricep, $http) {
  const $ctrl = this

  $ctrl.firm = null

  $scope.$watch(function () {
    return $ctrl.firm
  }, onFirmChanged)

  $ctrl.ifLate = ifLate
  $ctrl.onAvtoTehosmotrChanged = onAvtoTehosmotrChanged
  $ctrl.onPricepTehosmotrChanged = onPricepTehosmotrChanged
  $ctrl.deleteAvtoTehosmotr = deleteAvtoTehosmotr
  $ctrl.deletePricepTehosmotr = deletePricepTehosmotr
  $ctrl.printAvtos = printAvtos
  $ctrl.printPriceps = printPriceps
  $ctrl.printBefore = printBefore

  $scope.$ctrl = $ctrl

  init()

  function init() {
    $http.get('api.php?url=firms&kmbm__nashi=1').then((resp) => {
      $ctrl.firms = resp.data
    })
  }

  function printAvtos() {
    const id = $.fn.urlparam('id')
    printUrl(`print2.php?path=tehosmall.php&_id=${id}&_type=avto`)
  }

  function printPriceps() {
    const id = $.fn.urlparam('id')
    printUrl(`print2.php?path=tehosmall.php&_id=${id}&_type=pricep`)
  }

  function printBefore() {
    const d = $('#strdate').val()
    printUrl(`print2.php?path=tehosmall.php&_id=-1&_type=avto&date=${d}`)
  }

  function onFirmChanged(nv, ov) {
    if (!nv) {
      return
    }
    $ctrl.avto = null
    $ctrl.pricep = null

    GruzAvto.query({ firmId: nv }, function (avtos) {
      $ctrl.avtos = mapId2obj(avtos)
    })
    GruzPricep.query({ firmId: nv }, function (priceps) {
      $ctrl.priceps = mapId2obj(priceps)
    })

    function mapId2obj(res) {
      const objmap = {}
      _.each(res, function (r) {
        const obj = r.toJSON()
        objmap[obj.id] = obj
      })
      return objmap
    }
  }

  function onAvtoTehosmotrChanged(timestamp) {
    if (typeof timestamp === 'undefined') {
      return
    }

    const avtoid = $ctrl.avto ? $ctrl.avto[0] : null
    if (!avtoid) {
      return
    }

    return saveTehosmotr('avto', avtoid, timestamp).then(function () {
      $ctrl.avtos[avtoid].tehosmdata = timestamp
    })
  }

  function saveTehosmotr(type, avtoid, timestamp) {
    const data = {
      _tehosmdata: timestamp,
      _edit_submit: 1,
    }
    if (type === 'pricep') {
      data._pricepid = avtoid
    } else {
      data._avtoid = avtoid
    }

    return $http.post('popup.php?url=tehosmotr&_id=' + $ctrl.firm, data)
  }

  function onPricepTehosmotrChanged(timestamp) {
    if (typeof timestamp === 'undefined') {
      return
    }

    const pricepid = $ctrl.pricep ? $ctrl.pricep[0] : null
    if (!pricepid) {
      return
    }

    return saveTehosmotr('pricep', pricepid, timestamp).then(function () {
      $ctrl.priceps[pricepid].tehosmdata = timestamp
    })
  }

  function ifLate(avto) {
    const now = Date.now() / 1000
    return now > parseInt(avto.tehosmdata || '0')
  }

  function deleteAvtoTehosmotr() {
    const avtoid = $ctrl.avto[0]
    return saveTehosmotr('avto', avtoid, null).then(function () {
      $ctrl.avtos[avtoid].tehosmdata = null
    })
  }

  function deletePricepTehosmotr() {
    const pricepid = $ctrl.pricep[0]
    return saveTehosmotr('pricep', pricepid, null).then(function () {
      $ctrl.priceps[pricepid].tehosmdata = null
    })
  }
}
