export const safetyBriefingState = {
  name: 'safetyBriefingState',
  url: '/plists/safety-briefing',
  component: 'safetyBriefingForm',
  resolve: {
    ourFirms: (firmServiceApiV2) => firmServiceApiV2.getOurFirms(),
  },
  data: {
    title: 'Журнал инструктажа водителей по безопасности движения',
  },
}
