import utils from '../../utils'

export const PutlistFormComponent = {
  template: require('./putlist-form.component.html'),
  styles: [require('./putlist-form.component.scss')],
  bindings: {
    plist: '<',

    // data
    plans: '<',
    drivers: '<',
    avtos: '<',
    priceps: '<',
    ourFirms: '<',

    onClose: '&',
  },
  controller: function (
    putlistServiceApiV2,
    planService,
    printer,
    $state,
    popup
  ) {
    const $ctrl = this

    Object.defineProperties(this, {
      avto: {
        get: function () {
          if ($ctrl.plist.avto) {
            return $ctrl.id2avto[$ctrl.plist.avto]
          } else {
            return null
          }
        },
      },
      dirty: {
        get: () => {
          return !utils.obj.equal($ctrl.plist, $ctrl.plist0)
        },
      },
      aprob: {
        get: () => {
          const prob = $ctrl.plist.plistp.speed - $ctrl.plist.plistu.speed
          return prob >= 0 ? prob : 0
        },
      },
      rashod: {
        get: () => {
          const a = $ctrl.avto
          const p = $ctrl.plist
          if (!a || !p) return null
          const pp = p.plistp
          const pu = p.plistu

          const type_ = a.type
          const Hw = a.ntr
          const Hs = a.bnorm
          const Pgr = pp.psgruz
          const Gkon = p.wcont
          const Gpr = p.weight
          const Wgr = pp.weight
          const lpS = pp.speed - pu.speed
          if (lpS < 0) return null
          const lpD = pp.zima + pp._2mil + pp.negab + pp.procie

          let lpQn, lpW, lpHsan
          switch (type_) {
            case 'Г':
              lpW = Wgr * Pgr
              lpHsan = Hs + Hw * (Gpr + Gkon)
              lpQn = 0.01 * (lpHsan * lpS + Hw * lpW) * (1 + 0.01 * lpD)
              break
            case 'Л':
              lpQn = 0.01 * Hs * lpS * (1 + 0.01 * lpD)
              break
            case 'А':
              lpQn = 0.01 * Hs * lpS * (1 + 0.01 * lpD)
              break
            default:
              return null
          }
          return lpQn
        },
      },
      rashod_rnd1: {
        get: () => utils.math.round($ctrl.rashod, 1),
      },
      vbake: {
        get: () => {
          const p = $ctrl.plist
          if (!p) return 0
          const pu = p.plistu
          let rashod = utils.math.round($ctrl.rashod, 0)
          if (!rashod) {
            rashod = 0
          }
          const ostatok = pu.ostatok + pu.give + pu.gsm - rashod
          return Math.ceil(ostatok)
        },
      },
      summa: {
        get: () => {
          const p = $ctrl.plist
          if (!p) return null
          return Math.ceil(p.lcost * $ctrl.rashod)
        },
      },

      actsDisabled: {
        get: () => {
          const pl = $ctrl.plist
          if (!pl) {
            return 'Отсутствует путевой лист'
          }
          if ($ctrl.dirty) {
            return 'В форме присутствуют несохраненные изменения'
          }
          if (!pl.pricep) {
            return 'Не выбран прицеп'
          }
          const arendator = $ctrl.id2ourfirm[pl.firm_id]
          const pricep = $ctrl.id2pricep[pl.pricep]
          const arendodatel = $ctrl.id2ourfirm[pricep.pid]
          if (arendator.id === arendodatel.id) {
            return 'Арендатор и арендодатель - одно лицо'
          }

          console.log(arendator, arendodatel)

          return ''
        },
      },
    })

    $ctrl.$onInit = () => {
      $ctrl.loading = false
      $ctrl.plist0 = utils.obj.copy($ctrl.plist)
      $ctrl.selplist = utils.obj.copy($ctrl.plist)
      $ctrl.id2avto = $ctrl.avtos.idlize()
      $ctrl.id2pricep = $ctrl.priceps.idlize()
      $ctrl.id2ourfirm = $ctrl.ourFirms.idlize()

      $ctrl.CommercialTypes = [
        { id: true, title: 'Коммерческая' },
        { id: false, title: 'Для собственных нужд' },
      ]
      $ctrl.shipmentTypes = [
        { id: 'LOCAL', title: 'Городская' },
        { id: 'SUBURBAN', title: 'Пригородная' },
        { id: 'INTERURBAN', title: 'Междугородняя' },
      ]
    }

    $ctrl.onPlistChanged = () => {
      if (
        $ctrl.dirty &&
        !confirm(`У вас есть несохраненные изменения. Продолжить?`)
      ) {
        return
      }
      $ctrl.loading = true
      $state.go(`putlist`, { id: $ctrl.selplist.id })
    }

    $ctrl.onPricepChanged = () => {
      const pric = $ctrl.plist.pricep
      if (pric) {
        $ctrl.plist.weight = +$ctrl.id2pricep[pric].weight
        // $ctrl.plist.weight = +$ctrl.priceps.find(p => p.id == pric).weight;
      } else {
        $ctrl.plist.weight = 0
      }
    }

    $ctrl.openClaim = () => {
      return planService.get({ id: $ctrl.plist.pid }).then((plan) => {
        popup.state('zay.base.detail', { id: plan.pid }, 800, 600)
      })
    }

    $ctrl.searchPlans = (pid) => {
      if (pid.length > 0) {
        return planService
          .get({ pid__contains: pid })
          .then((plans) => ($ctrl.plans = plans))
      } else {
        $ctrl.plans = []
      }
    }

    $ctrl.save = () => {
      $ctrl.plist.plistp.aprob = $ctrl.aprob
      $ctrl.plist.plistp.inbak = $ctrl.vbake
      return putlistServiceApiV2.save($ctrl.plist).then((pl) => {
        if (!$ctrl.plist.id) {
          $ctrl.selplist = pl
        }
        $ctrl.plist = utils.obj.copy(pl)
        $ctrl.plist0 = utils.obj.copy(pl)
      })
    }

    $ctrl.resetList = () => {
      $ctrl.plist = utils.obj.copy($ctrl.plist0)
    }

    $ctrl.close = (plist) => {
      if ($ctrl.onClose) {
        $ctrl.onClose({ plist: { ...plist } })
      } else {
        window.close()
      }
    }

    $ctrl.getGruz = () => {
      const aprob = $ctrl.aprob
      const psgruz = $ctrl.plist.plistp.psgruz
      $ctrl.plist.plistp.psgruz =
        aprob !== psgruz ? aprob : Math.ceil(aprob / 2)
    }

    $ctrl.print = () => {
      // eslint-disable-next-line no-undef
      printUrl(`print2.php?path=plist.php&id=${$ctrl.plist.id}`)
    }

    $ctrl.printActVyd = () => {
      printer.print('arenda_pric_act', { id: $ctrl.plist.id, type: 1 })
    }

    $ctrl.printActVoz = () => {
      printer.print('arenda_pric_act', { id: $ctrl.plist.id, type: 0 })
    }

    $ctrl.onPlanChanged = (pid) => {
      const plan = $ctrl.plans.find((p) => p.id === pid)
      if (plan) {
        $ctrl.plist.marshrut = plan.kuda
        $ctrl.plist.plistp.weight = plan.weight + plan.addw
      }
    }
  },
}
