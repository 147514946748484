import { FormController } from '../../controllers/form-controller.class'

/** @ngInject */
class PackingFormController extends FormController {
  /** Конструктор контроллера. */
  // eslint-disable-next-line no-useless-constructor
  constructor($scope, $injector, equipmentServiceApiV2) {
    super($scope, $injector)
    this.equipmentServiceApiV2 = equipmentServiceApiV2
    this.$state = $injector.get('$state')
  }

  $onInit() {
    this.groups = this.equipmentTree
  }

  /** Изделия, добавленные в паккинг. */
  get packedItems() {
    return this.groups.results
      .map((g) => g.items)
      .reduce((pv, cv) => pv.concat(cv))
      .filter((i) => i._quantity > 0)
      .map((i) => ({ id: i.id, quantity: i._quantity }))
  }

  print() {
    this.equipmentServiceApiV2.printPacking(this.packedItems)
  }
}

export const PackingFormComponent = {
  template: require('./packing-form.component.html'),
  styles: [require('./packing-form.component.scss')],
  bindings: {
    equipmentTree: '<',
  },
  controller: PackingFormController,
}
