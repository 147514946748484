import PopeyeModal from '../popeye-modal.class'

/** Контроллер формы счета субконтрактора. */
class ZatratiModalController {
  static $inject = [
    '$scope',
    'options',
    'subschet',
    'zay',
    'usltypes',
    'zatratiService',
  ]

  constructor($scope, options, subschet, zay, usltypes, zatratiService) {
    Object.assign(this, {
      $scope,
      usltypes,
      zatratiService,
    })

    // необязательные опции
    this.options = Object.assign(
      {
        canEditZay: false,
      },
      options || {}
    )

    this.subschet = subschet || {
      id: null,
      pid: zay ? zay.id : null,
      nomer: null,
      data: new Date(),
      type: 2,
      plat: null,
      dataplat: null,
    }
    $scope.vm = this
  }

  /** Контроллер формы. */
  get form() {
    return this.$scope.form
  }

  /** Подстановка префикса счета. */
  onBtnPrefixClick(prefix, replace) {
    const nomer = this.subschet.nomer || ''
    const res = nomer.match(/[0-9]+/i)
    if (replace) {
      if (!res) {
        this.subschet.nomer = prefix
      } else {
        this.subschet.nomer = prefix + nomer.substr(res.index)
      }
    } else {
      // append
      this.subschet.nomer = nomer + prefix
    }
    $('#schet-form input[name=nomer]').focus()
  }

  /** Сохранение информации о счете. */
  save() {
    if (!this.form.$valid) {
      return
    }
    return this.zatratiService.save(this.subschet).then(this.$scope.$close)
  }
}

/** Модальная форма редактирования счета субконтрактору. */
export default class ZatratiModal extends PopeyeModal {
  modal = {
    template: require('./zatrati.modal.html'),
    controller: ZatratiModalController,
  }
}
