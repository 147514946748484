import { WindowFormController } from './form-controller.class'

/**
 * Базовый класс для создания простых форм с фильтрами и
 * выводом на печать.
 **/
export class PrintFormController extends WindowFormController {
  /** Имя (слаг) отчета. @type {string} */
  reportName = ''
  /** Старый отчет */
  legacyReport = false

  /** @ngInject */
  constructor($scope, $injector) {
    super($scope, $injector)
    this.$q = $injector.get('$q')
    this.printer = $injector.get('printer')
  }

  /**
   * Фильтры по умолчанию.
   * @type {Object} */
  get defaultFilters() {
    return {}
  }

  /** Преобразованные фильтры. */
  get convertedFilters() {
    return this.filters
  }

  $onInit() {
    this.resetFilters()
  }

  /** Печать отчета. */
  print() {
    if (this.legacyReport) {
      this.printer.printLegacy(this.reportName, this.convertedFilters)
    } else {
      this.printer.print(this.reportName, this.convertedFilters)
    }
  }

  /** Сброс фильтров. */
  resetFilters() {
    this.filters = { ...this.defaultFilters }
  }
}
