import dateCropFilter from './date-crop.filter'
import propsFilter from './props.filter'
import ifEmptyFilter from './if-empty.filter'
import { UnitsFilter } from './units.filter'
import { NbspFilter } from './nbsp.filter'

export default angular
  .module('gruz.filters', [])
  .filter('date_crop', dateCropFilter)
  .filter('propsFilter', propsFilter)
  .filter('if_empty', ifEmptyFilter)
  .filter('nbsp', NbspFilter)
  .filter('units', UnitsFilter).name
