// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class Otper2NewFormController extends PrintFormController {
  reportName = 'oper2-2'

  get defaultFilters() {
    return {
      from: firstDayOfMonth(),
      to: lastDayOfMonth(),
      excludeInnerRashod: false,
      zay: null,
      firm: null,
      manager: null,
      currency: '',
    }
  }

  get convertedFilters() {
    return {
      currency: this.filters.currency,
      d1: utils.db.date2dmy(this.filters.from),
      d2: utils.db.date2dmy(this.filters.to),
      firm: this.filters.firm,
      manager: this.filters.manager,
      zay: this.filters.zay,
      vn: this.filters.excludeInnerRashod ? '1' : '',
    }
  }
}

export const Otper2NewFormComponent = {
  template: require('./otper2n-form.component.html'),
  styles: [require('./otper2n-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: Otper2NewFormController,
}
