import { WindowFormController } from '../../controllers/form-controller.class'
import { TTN_TYPES } from '../../constants'
import { quotedName } from '../../utils'

/** @ngInject */
class TtnFormController extends WindowFormController {
  TTN_TYPES = TTN_TYPES
  DEFAULT_RTYPE = 'vtt'
  modelKeyProp = 'nomer'

  // действия над формой
  ttnCtrl = {
    '': 'Выберите накладную',
    new: 'Новая накладная',
  }

  constructor($q, $scope, $state, zaysServiceApiV2, toaster) {
    super($scope)
    this.$q = $q
    this.$state = $state
    this.zaysServiceApiV2 = zaysServiceApiV2
    this.toaster = toaster
  }

  $onInit() {
    this.api = this.zaysServiceApiV2.getTtnService(this.zay.id)
    if (!this.ttn) {
      this.model = this._createTTN()
    } else {
      this.model = this.ttn
      if (!this.model.rtype) {
        this.model.rtype = this.DEFAULT_RTYPE
      }
    }
    this.nomer = '' + this.model.nomer
    // список накладных
    this.zay.ttns.forEach((ttn) => {
      this.ttnCtrl[ttn.nomer] = `Накладная № ${ttn.nomer}`
    })
  }

  get gtdEnabled() {
    return this.model.rtype === 'gtd'
  }

  get vttEnabled() {
    return this.model.rtype === 'vtt'
  }

  /**
   * Фирма-перевозчик.
   *
   * @returns {string} полное наименование.
   */
  get perevozchik() {
    if (!this.model) return ''

    const plan = this.model.plan_info
    if (!plan) return ''

    if (plan.avto_tm) {
      return quotedName('ТРАНСАВТО', 'ЗАО')
    } else if (plan.avto_tehnomark) {
      return quotedName('Техно-Марк', 'ООО')
    } else {
      const avto = plan.avto_info
      if (!avto) return ''

      return avto.firm_info.full_name
    }
  }

  /** Обработка события переключения/создания ТТН. */
  onNomerChange(nomer) {
    if (nomer === 'new') {
      this._switchTtn(null)
    } else {
      this._switchTtn(nomer)
    }
  }

  /**
   * Сохранение формы.
   *
   * Если создается новая ТТН, то после сохранения форма переключается
   * в на нее.
   * */
  save() {
    if (this.model.nomer === 'new') {
      // т.е. создается новая ТТН
      delete this.model.nomer
      return super
        .save({ close: false })
        .then((ttn) => this._switchTtn(ttn.nomer))
    } else {
      return super.save({ close: false })
    }
  }

  /**
   * Удаление модели.
   *
   * После удаления происходит переход на предыдущую накладную.
   * Если предыдущей не существует, то происходит переход к созданию
   * новой накладной.
   * */
  delete() {
    if (this.model.plan_info) {
      this.toaster.pop({
        type: 'warning',
        body: 'Пожалуйста, уберите накладную из планирования',
      })
      return
    }
    if (confirm('Пожалуйста, подтвердите удаление накладной')) {
      return super.delete().then((key) => this._switchTtn(this.model.prev))
    }
  }

  /**
   * Перейти к другой ТТН.
   *
   * Если номер не указан, то перейти к созданию ТТН.
   * @param {number|null} nomer
   */
  _switchTtn(nomer) {
    if (!nomer) {
      this.$state.go('zay.base.ttn.new', {})
    } else {
      this.$state.go(
        'zay.base.ttn.detail',
        {
          id: this.zay.id,
          nomer,
        },
        { reload: true }
      )
    }
  }

  /** Создание пустой ТТН. */
  _createTTN() {
    return {
      pid: this.zay.id,
      nomer: 'new',
      rtype: this.DEFAULT_RTYPE,
    }
  }
}

export const TtnFormComponent = {
  template: require('./ttn-form.component.html'),
  styles: [require('./ttn-form.component.scss')],
  bindings: {
    ttn: '<',
    zay: '<',
  },
  controller: TtnFormController,
}
