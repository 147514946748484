import angular from 'angular'

export default angular
  .module('gruz.states.equipment', [])
  .config(($stateRegistryProvider) => {
    $stateRegistryProvider.register({
      name: 'equipments',
      url: '/equipments',
      component: 'equipments',
      data: {
        title: 'Типы оборудования',
      },
      resolve: {
        equipmentTypes: (groupServiceApiV2) =>
          groupServiceApiV2.get({ depth: 1 }).then((resp) => resp.results),
      },
    })

    $stateRegistryProvider.register({
      name: 'equipmentGroup',
      url: '/equipment/groups/{id}',
      component: 'equipmentGroups',
      data: {
        title: 'Группы оборудования',
      },
      resolve: {
        equipmentGroup: ($stateParams, groupServiceApiV2) =>
          groupServiceApiV2.get({ id: $stateParams.id }),
        equipmentGroupItems: (equipmentGroup, equipmentServiceApiV2) =>
          equipmentServiceApiV2.get({ group: equipmentGroup.id }),
        parentEquipmentGroup: (equipmentGroup, groupServiceApiV2) => {
          if (equipmentGroup.parent) {
            return groupServiceApiV2.get({ id: equipmentGroup.parent })
          }
        },
      },
    })

    $stateRegistryProvider.register({
      name: 'equipmentItem',
      url: '/equipment/items/{id}',
      component: 'equipmentItems',
      data: {
        title: 'Изделия',
      },
      resolve: {
        equipmentItem: ($stateParams, equipmentServiceApiV2) =>
          equipmentServiceApiV2.get({ id: $stateParams.id }),
        parentGroupItem: (equipmentItem, groupServiceApiV2) =>
          groupServiceApiV2.get({ id: equipmentItem.group }),
      },
    })

    $stateRegistryProvider.register({
      name: 'packing',
      abstract: true,
      url: '/packing',
      resolve: {},
    })

    $stateRegistryProvider.register({
      name: 'packing.form',
      url: '/create',
      component: 'packingForm',
      data: {
        title: 'Создание паккинга',
      },
      resolve: {
        equipmentTree: (groupServiceApiV2) => groupServiceApiV2.getWithItems(),
      },
    })
  }).name
