import { CRUDService } from './crud-service.class'

export class UchetZoplService extends CRUDService {
  subject = 'uchet-zopl'

  parse = (o) => {
    o.id = parseInt(o.id)
    o.schet = parseInt(o.schet)
    o.s_schet = parseInt(o.s_schet)
    o.date = $.fn.dateutils.str_yyyymmdd2date(o.date, '-')
    return o
  }

  format = (o) => {
    o.date = $.fn.dateutils.date2str_yyyymmdd(o.date, '-')
    return o
  }

  /**
   * Зачесть оплату по счету
   * @param {*} from засчитываемый счет
   * @param {*} to итоговый счет
   */
  zopl(from, to) {
    return this.save({
      schet: to.nscheta,
      s_schet: from.nscheta,
      firm: from.firm,
      date: from.data,
    })
  }
}
