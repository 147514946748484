import PopeyeModal from '../popeye-modal.class'

/** Контроллер формы оплаты счета субконтрактору. */
class ZatratiPayController {
  static $inject = ['$scope', 'subschet', 'zatratiService']

  /** Изначальна сумма счета. */
  summa = 0

  /** Инициализация контроллера. */
  constructor($scope, subschet, zatratiService) {
    Object.assign(this, { $scope, subschet, zatratiService })
    $scope.vm = this
    this.summa = +subschet.summa

    // инициализация платежки
    this.subschet = angular.copy(subschet)
    if (!this.subschet.dataplat) {
      this.subschet.dataplat = new Date()
    }
  }

  /** Контроллер формы. */
  get form() {
    return this.$scope.form
  }

  /** Частичная оплата счета. */
  get isPartialPay() {
    return this.subschet.summa < this.summa
  }

  /** Сохранение информации о платеже. */
  save() {
    if (!this.form.$valid) {
      return
    }
    if (this.isPartialPay) {
      return this.zatratiService
        .partialPay(this.subschet)
        .then(this.$scope.$close)
    } else {
      return this.zatratiService.pay(this.subschet).then(this.$scope.$close)
    }
  }
}

/** Модальная форма оплаты счета субконтрактору. */
export default class ZatratiPayModal extends PopeyeModal {
  modal = {
    template: require('./zatrati-pay.modal.html'),
    controller: ZatratiPayController,
  }
}
