import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'
import utils from '../utils'
import { DateTime } from 'luxon'

export class PlanService extends CRUDService {
  subject = 'plans'

  parse = (p) => {
    p.id = parseInt(p.id)
    p.nomer = parseInt(p.nomer)
    p.data = utils.db.str2date(p.data)
    p.eksp = p.eksp ? utils.db.str2int(p.eksp) : null
    p.weight = utils.db.str2float(p.weight)
    p.addw = utils.db.str2float(p.addw)
    p.contract_id = p.contract_id ? parseInt(p.contract_id) : null
    return p
  }

  format = (o) => {
    o.data = utils.db.date2str(o.data)
    return o
  }
}

export class PlanServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'plans'

  parse = (p) => {
    p.data = utils.db.str2date(p.data)
    p.pdvigr = utils.db.str2date(p.pdvigr)
    p.pdvoz = utils.db.str2date(p.pdvoz)
    p.fdvigr = utils.db.str2date(p.fdvigr)
    p.fdvoz = utils.db.str2date(p.fdvoz)
    p.length = utils.db.str2float(p.length)
    p.height = utils.db.str2float(p.height)
    p.width = utils.db.str2float(p.width)
    p.weight = utils.db.str2float(p.weight)
    p.addw = utils.db.str2float(p.addw)
    p.kmgruzy = utils.db.str2float(p.kmgruzy)
    p.kmgruzn = utils.db.str2float(p.kmgruzn)
    return p
  }

  format = (o) => {
    o.data = DateTime.fromJSDate(o.data).toISODate()
    o.pdvigr = o.pdvigr ? DateTime.fromJSDate(o.pdvigr).toISODate() : null
    o.pdvoz = o.pdvoz ? DateTime.fromJSDate(o.pdvoz).toISODate() : null
    o.fdvigr = o.fdvigr ? DateTime.fromJSDate(o.fdvigr).toISODate() : null
    o.fdvoz = o.fdvoz ? DateTime.fromJSDate(o.fdvoz).toISODate() : null
    return o
  }

  getPricepUsage(params = {}) {
    const title = 'Использование прицепов'
    return this.$http({
      url: `${this.baseUrl}/${this.subject}/pricep_usage?${utils.url.buildQuery(
        params
      )}`,
      method: 'GET',
      responseType: 'blob',
    }).then((resp) => {
      this.FileSaver.saveAs(resp.data, `${title}.docx`)
    })
  }

  /** Печать акта приема-передачи прицепов в аренду. */
  getPricepArendaReport(params = {}) {
    return this.printer.printBlob(
      'Акт приема-передачи прицепов в аренду.docx',
      `${this.baseUrl}/${this.subject}/pricep_arenda`,
      params
    )
  }
}
