import PopeyeModal from '../popeye-modal.class'

/** @ngInject */
class AddGroupModalController {
  constructor(
    $q,
    $scope,
    $state,
    toaster,
    groupItem,
    groupChildrenServiceApiV2,
    groupServiceApiV2
  ) {
    Object.assign(this, {
      $q,
      $scope,
      $state,
      toaster,
      groupItem,
      groupChildrenServiceApiV2,
      groupServiceApiV2,
    })

    this.setOpportunity()

    if (this.groupItem.id) {
      this.data = {
        name: this.groupItem.name,
        id: this.groupItem.id,
      }
    } else if (this.groupItem.parentId) {
      this.data = {
        parentId: this.groupItem.parentId,
      }
    }

    this.levels = {
      1: 'оборудование',
      2: 'тип',
      3: 'группа',
      4: 'подгруппа',
    }

    $scope.vm = this
  }

  /**
   * Добавление нового элемента
   */
  addChild() {
    if (this.validate()) {
      this.groupChildrenServiceApiV2.saveChild(this.data).then((group) => {
        this.$state.reload()
        this.$scope.$close()
      })
    }
  }

  /**
   * Редактирование текущего элемента
   */
  editItem() {
    if (this.validate()) {
      this.groupServiceApiV2.save(this.data).then((resp) => {
        this.$state.reload()
        this.$scope.$close()
      })
    }
  }

  cancel() {
    this.$scope.$close()
  }

  /**
   * Установка разрешений и ограничений
   */
  setOpportunity() {
    const isAddGroup = this.groupItem.parentId && this.groupItem.isType()

    this.opportunity = {
      can_upload_pdf:
        isAddGroup || this.groupItem.isGroup() || this.groupItem.isSubGroup(),
      can_upload_image:
        !this.groupItem.isGroup() || !this.groupItem.isSubGroup(),
      is_edit: this.groupItem.id,
      is_add_child: this.groupItem.parentId,
    }
  }

  getTitle() {
    if (this.groupItem.depth < 4) {
      return this.levels[this.groupItem.depth + 1]
    } else {
      return this.levels[4]
    }
  }

  /**
   * Общая валидация полей формы
   */
  validate() {
    return this.checkName()
  }

  /**
   * Проверка заполненности наименования
   * @returns
   */
  checkName() {
    if (this.data.name === undefined || this.data.name === '') {
      this.toaster.pop({
        type: 'error',
        title: 'Поле "Наименование" обязательно для заполнения',
      })
      return false
    }

    return true
  }
}

export class AddGroupModal extends PopeyeModal {
  modal = {
    template: require('./add-group.modal.html'),
    styles: [require('./add-group.modal.scss')],
    controller: AddGroupModalController,
  }
}
