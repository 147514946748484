export class LoginController {
  static $inject = ['$q', '$scope', '$window', '$http', 'authService']
  constructor($q, $scope, $window, $http, authService) {
    Object.assign(this, { $q, $scope, $window, $http, authService })
  }

  /**
   * Имя пользователя
   * @type {string}
   * */
  username

  /**
   * Пароль
   * @type {string}
   */
  password

  login(event) {
    event.preventDefault()
    this.authService.createToken(this.username, this.password).then(
      (token) => {
        $('#access').trigger('submit')
      },
      (rejection) => {
        if (rejection.status === 401) {
          alert('Неправильный пользователь или пароль.')
        } else {
          alert('Ошибка авторизации.')
        }
      }
    )
  }
}
