import { ListController } from '../../controllers/list-controller.class'

class KintransListController extends ListController {
  /** @ngInject */
  constructor($scope, $injector, keensServiceApiV2, kintransModal) {
    super($scope, $injector)
    this.api = keensServiceApiV2
    this.modal = kintransModal
    this.tableOptions = {
      headers: {
        comp: 'Компания',
        bank: 'Банк',
      },
    }
  }
}

export const KintransListComponent = {
  template: require('./kintrans-list.component.html'),
  styles: [require('./kintrans-list.component.scss')],
  bindings: {},
  controller: KintransListController,
}
