class PricepsController {
  DEFAULT_FIRM = 'zkm'

  PRICEP_STATUSES = {
    '': '-',
    gotov: 'готов',
    vremonte: 'в ремонте',
    jdet_remonta: 'ожидает ремонта',
    za_shtat: 'выведен за штат',
  }

  PRICEP_TYPES = {
    REGULAR: 'обычный',
    SELF_PROPELLED: 'самоходный',
    MODULAR: 'модульный',
  }

  static $inject = [
    '$q',
    '$scope',
    '$timeout',
    '$stateParams',
    'pricepServiceApiV2',
    'pricepModal',
  ]

  constructor(
    $q,
    $scope,
    $timeout,
    $stateParams,
    pricepServiceApiV2,
    pricepModal
  ) {
    this.$q = $q
    this.$scope = $scope
    this.$timeout = $timeout
    this.$stateParams = $stateParams
    this.pricepServiceApiV2 = pricepServiceApiV2
    this.pricepModal = pricepModal
    $scope.vm = this

    this.pricepTable = {
      options: {
        headers: {
          mark: 'Марка',
          nomer: 'Номер',
          weight: 'Вес',
          pod: 'Под',
          arenda: 'Аренда',
          weightpts: 'Вес (ПТС)',
          status_str: 'Состояние',
          pricep_type_str: 'Тип',
          comment: 'Примечание',
        },
        tablesorterOptions: {
          sortList: [[1, 1]],
        },
      },
      service: this.pricepServiceApiV2,
      init$: this.$q.defer(),
      ctrl: null,
      rowfmt: (p) => {
        p.pricep_type_str = this.PRICEP_TYPES[p.pricep_type]
        p.status_str = this.PRICEP_STATUSES[p.status]
        return p
      },
    }
  }

  get selectedPricep() {
    return this.pricepTable.ctrl.selectedObject
  }

  $onInit() {
    const defaultFirm = this.ourFirms.results.find(
      (f) => f.kmbm === this.DEFAULT_FIRM
    )

    this.filters = {
      firm: this.firm ? this.firm.id : defaultFirm?.id,
      status: '',
    }

    const promises = [this.pricepTable.init$.promise]
    this.$q.all(promises).then((resps) => {
      this.pricepTable.ctrl = resps[0]
    })
  }

  /** Добавить новый прицеп. */
  add() {
    const modal = this.pricepModal.open({
      locals: {
        pricep: { pid: this.filters.firm },
      },
    })
    return modal.closed.then(this.pricepTable.ctrl.addOrUpdateRow)
  }

  /** Редактировать выбранный прицеп. */
  edit() {
    const modal = this.pricepModal.open({
      locals: {
        pricep: this.selectedPricep,
      },
    })
    return modal.closed.then(this.pricepTable.ctrl.addOrUpdateRow)
  }

  delete(id) {
    if (!confirm('Вы уверены, что хотите удалить прицеп?')) {
      return
    }
    return this.pricepServiceApiV2
      .delete(this.selectedPricep.id)
      .then(this.pricepTable.ctrl.removeRow)
  }

  close() {
    window.close()
  }

  print() {
    window.open(
      'print2.php?path=auto2.php&fid=' +
        this.filters.firm +
        '&status=' +
        this.filters.status,
      'width=800,height=600,menubar=1'
    )
  }
}

export const PricepsComponent = {
  template: require('./priceps.component.html'),
  styles: [require('./priceps.component.scss')],
  bindings: {
    onSave: '&',
    onClose: '&',
    ourFirms: '<',
    firm: '<',
  },
  controller: PricepsController,
}
