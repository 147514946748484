import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

export class Uslugi2Service extends CRUDService {
  subject = 'uslugi2'
  cacheEnabled = true

  parse = (o) => {
    o.id = parseInt(o.id)
    return o
  }

  format = (o) => {
    return o
  }
}

export class Uslugi2ServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'uslugi2'
}
