export const FIRM_NDS = {
  bm: '0.2',
  okm: '0.2',
  zkm: '',
  kl: '0',
  th: '0',
  tm: '',
  ta: '0',
}

export const FIRM_CODENAMES_SMALL = {
  bm: 'БМ',
  okm: 'ОКМ',
  zkm: 'ЗКМ',
  kl: 'КЛ',
  th: 'ТХ',
  tm: 'ТМ',
  ta: 'ТА',
}

export const PRICEP_TYPES = {
  REGULAR: 'обычный',
  SELF_PROPELLED: 'самоходный',
  MODULAR: 'модульный',
}

export const PRICEP_STATUS_OPTIONS = {
  gotov: 'готов к эксплуатации',
  vremonte: 'в ремонте',
  jdet_remonta: 'ожидает ремонта',
  za_shtat: 'выведен за штат',
}

export const EQUIPMENT_REGISTRY = 'Реестр оборудования'
export const EQUIPMENT_ADMIN = 'Администратор реестра оборудования'
export const ADMIN = 'Администратор'
export const SUPERVISOR = 'Супервизор'
export const BOOK_KEEPER = 'Бухгалтер'
export const EKSPEDITOR = 'Экспедитор'
export const MANAGER = 'Менеджер'

export const EQUIPMENT_GROUP_LEVELS = {
  1: 'Оборудование',
  2: 'Тип',
  3: 'Группа',
  4: 'Подгруппа',
}

export const CI_DECIMAL_PATTERN = /^[0-9]{1,13}(\.[0-9]{1,3})?$/

export const TTN_TYPES = {
  HC: 'High Cube',
  T: 'Tank',
  M: 'M(REF+High)',
  O: 'Open Top',
  F: 'Flat Rack',
}

export const NODATA = 'н/д'
