import { ADMIN, BOOK_KEEPER, MANAGER, SUPERVISOR } from '../constants'

/**
 * Токен JWT.
 */
class JWT {
  constructor(token) {
    this._token = this._parseJWT(token)
  }

  _parseJWT(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  get payload() {
    return this._token
  }
}

/**
 * Сервис для получения данных о текущем пользователе.
 * @ngInject
 */
export class CurrentUserService {
  constructor(authService) {
    this.authService = authService
  }

  /**
   * Роли пользователя.
   * @returns {Array<string>} роли пользователя
   */
  get roles() {
    if (!this.info) return []

    return this.info.roles
  }

  /**
   * Токен доступа.
   * @returns {Object}
   * */
  get info() {
    if (!this.authService.token) return {}
    return new JWT(this.authService.token.access).payload
  }

  /**
   * Пользователь имеет одну из ролей.
   * @param {string|Array<string>} roles роли
   * @returns {boolean}
   */
  hasRole(roles) {
    if (typeof roles === 'string') {
      roles = [roles]
    }
    return roles.find((r) => this.roles.includes(r)) !== undefined
  }

  /**
   * Пользователь имеет доступ к финансам по заявке.
   * @param {*} zay
   * @returns {boolean}
   */
  hasZayFinansePermission(zay) {
    if (this.hasRole([SUPERVISOR, ADMIN, BOOK_KEEPER])) return true

    if (this.hasRole(MANAGER)) {
      if (zay.autor === this.info.legacy_user) return true
      else if (zay.manager === this.info.legacy_user) return true
      else return false
    } else {
      return false
    }
  }
}
