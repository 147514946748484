import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'
import utils from '../utils'

export class PutlistService extends CRUDService {
  subject = 'plists'

  parse = (p) => {
    p.id = parseInt(p.id)
    p.pid = p.pid ? parseInt(p.pid) : null
    p.pid2 = p.pid2 ? parseInt(p.pid2) : null
    p.avto = p.avto ? parseInt(p.avto) : null
    p.firm_id = parseInt(p.firm_id)
    p.driver1 = p.driver1 ? parseInt(p.driver1) : null
    p.driver2 = p.driver2 ? parseInt(p.driver2) : null
    p.pricep = p.pricep ? parseInt(p.pricep) : null
    p.pricep2 = p.pricep2 ? parseInt(p.pricep2) : null
    p.cover = p.cover === '1'
    p.city = p.city === '1'
    p.none = p.none === '1'
    p.data1 = p.data1 ? $.fn.dateutils.ts2d(p.data1) : null
    p.data2 = p.data2 ? $.fn.dateutils.ts2d(p.data2) : null
    p.wcont = utils.db.str2float(p.wcont)
    p.weight = utils.db.str2float(p.weight)
    p.arenda = utils.db.str2int(p.arenda)
    if (p._arenda) {
      p._arenda = utils.db.str2int(p._arenda)
    }
    p.is_commercial = p.is_commercial === '1'

    p.plistp.aprob = utils.db.str2int(p.plistp.aprob)
    p.plistp.psgruz = utils.db.str2int(p.plistp.psgruz)
    p.plistp.weight = utils.db.str2float(p.plistp.weight)
    p.plistp.speed = utils.db.str2int(p.plistp.speed)
    p.plistp.zima = utils.db.str2float(p.plistp.zima)
    p.plistp._2mil = utils.db.str2float(p.plistp._2mil)
    p.plistp.negab = utils.db.str2float(p.plistp.negab)
    p.plistp.procie = utils.db.str2float(p.plistp.procie)
    p.plistp.inbak = utils.db.str2float(p.plistp.inbak)

    p.plistu.speed = utils.db.str2int(p.plistu.speed)
    p.plistu.give = utils.db.str2float(p.plistu.give)
    p.plistu.ostatok = utils.db.str2float(p.plistu.ostatok)
    p.plistu.gsm = utils.db.str2float(p.plistu.gsm)
    return p
  }

  format = (o) => {
    o.cover = o.cover ? '1' : '0'
    o.city = o.city ? '1' : '0'
    o.none = o.none ? '1' : '0'
    o.is_commercial = o.is_commercial ? '1' : '0'
    o.data1 = o.data1
      ? $.fn.dateutils.d2ts(o.data1)
      : $.fn.dateutils.d2ts(new Date())
    o.data2 = o.data2
      ? $.fn.dateutils.d2ts(o.data2)
      : $.fn.dateutils.d2ts(new Date())
    return o
  }

  getRashod = (id) => {
    return this.$http
      .post(`forms_ajax/rasschet.php?id=${id}`, {
        func: 'getNameAndTime',
      })
      .then((resp) => {
        const data = resp.data
        switch (data.error) {
          case 1:
            return {
              rashod: data.rashod,
              summ: data.summa,
              inbak: data.ostatok,
            }
          default:
            throw data.error
        }
      })
  }
}

export class PutlistServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'putlists'

  parse = (p) => {
    p.data1 = p.data1 ? $.fn.dateutils.ts2d(p.data1) : null
    p.data2 = p.data2 ? $.fn.dateutils.ts2d(p.data2) : null
    p.weight = utils.db.str2float(p.weight)

    p.plistp.aprob = utils.db.str2int(p.plistp.aprob)
    p.plistp.psgruz = utils.db.str2int(p.plistp.psgruz)
    p.plistp.weight = utils.db.str2float(p.plistp.weight)
    p.plistp.speed = utils.db.str2int(p.plistp.speed)
    p.plistp.zima = utils.db.str2float(p.plistp.zima)
    p.plistp._2mil = utils.db.str2float(p.plistp._2mil)
    p.plistp.negab = utils.db.str2float(p.plistp.negab)
    p.plistp.procie = utils.db.str2float(p.plistp.procie)

    p.plistu.speed = utils.db.str2int(p.plistu.speed)

    return p
  }

  format = (o) => {
    o.data1 = Math.trunc(
      o.data1 ? $.fn.dateutils.d2ts(o.data1) : $.fn.dateutils.d2ts(new Date())
    )
    o.data2 = Math.trunc(
      o.data2 ? $.fn.dateutils.d2ts(o.data2) : $.fn.dateutils.d2ts(new Date())
    )
    return o
  }

  getSafetyBriefingJournal(params = {}) {
    return this.$http({
      url: `${this.baseUrl}/${
        this.subject
      }/safety_briefing_journal?${utils.url.buildQuery(params)}`,
      method: 'GET',
      responseType: 'blob',
    }).then((resp) => {
      this.FileSaver.saveAs(
        resp.data,
        'Журнал инструктажа водителей по безопасности движения.docx'
      )
    })
  }

  getTechnicalControlJournal(params = {}) {
    return this.$http({
      url: `${this.baseUrl}/${
        this.subject
      }/tech_control_journal?${utils.url.buildQuery(params)}`,
      method: 'GET',
      responseType: 'blob',
    }).then((resp) => {
      this.FileSaver.saveAs(
        resp.data,
        'Журнал котроля технического состояния автомобиля.docx'
      )
    })
  }
}
