import { PutlistController } from './ngputlist'
import { FirmsController } from './ngfirms'
import { FirmInnSearchDialogCtrl } from './firm_inn_search.dialog'
import { ZaysController } from './ngzays'
import { AvtostrahController } from './strah'
import { TachDialogCtrl } from './tach.dialog'
import { TehosmotrController } from './tehosmotr'
import { OfSActController } from './ngofsact'
import { OformlenieController } from './ngoformlenie'
import { PlategiController } from './ngplategi'
import FinansyController from './ngfinansy'
import { UrlBindController } from './urlbind'
import { LoginController } from './login'
import angular from 'angular'
import { ListController } from './list-controller.class'
import { JsTableController } from '../components/js-table/js-table.component'

export default angular
  .module('gruz.controllers', [])
  .controller('gruz.ngputlist', PutlistController)
  .controller('gruz.ngfirms', FirmsController)
  .controller('gruz.firmInnSearchDialog', FirmInnSearchDialogCtrl)
  .controller('gruz.ngzays', ZaysController)
  .controller('gruz.avtostrah', AvtostrahController)
  .controller('gruz.tachDialog', TachDialogCtrl)
  .controller('gruz.tehosmotr', TehosmotrController)
  .controller('gruz.ngofsact', OfSActController)
  .controller('gruz.ngoformlenie', OformlenieController)
  .controller('gruz.ngplategi', PlategiController)
  .controller('gruz.ngfinansy', FinansyController)
  .controller('gruz.urlbind', UrlBindController)
  .controller('gruz.login', LoginController)
  .controller('listController', ListController)
  .controller('jsTableController', JsTableController).name
