import { EQUIPMENT_REGISTRY, EQUIPMENT_ADMIN } from '../../constants'

class EquipmentsController {
  static $inject = ['$scope', '$location', 'currentUser']

  constructor($scope, $location, currentUser) {
    this.$location = $location
    this.currentUser = currentUser
    $scope.vm = this
  }

  /**
   * Проверка доступа к панели управления реестром
   * @returns {boolean}
   */
  hasAccess() {
    return this.currentUser.roles.includes(EQUIPMENT_REGISTRY)
  }

  /**
   * Проверка на администратора реестра оборудования
   * @returns {boolean}
   */
  hasAdminAccess() {
    const isRegistryAdmin = this.currentUser.roles.includes(EQUIPMENT_ADMIN)
    return this.hasAccess() && isRegistryAdmin
  }
}

export const EquipmentsComponent = {
  template: require('./equipments.component.html'),
  styles: [require('./equipments.component.scss')],
  bindings: {
    equipmentTypes: '<',
  },
  controller: EquipmentsController,
}
