import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

export class KeensService extends CRUDService {
  subject = 'keens'
  cacheEnabled = true

  parse = (k) => {
    k.id = parseInt(k.id)
    return k
  }
}

export class KeensServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'keens'
}
