import { WindowFormController } from '../../controllers/form-controller.class'

/** @ngInject */
class FuelCostFormController extends WindowFormController {
  constructor($scope, $injector, fuelCostServiceApiV2) {
    super($scope, $injector)
    this.api = fuelCostServiceApiV2
  }

  $onInit() {
    this.model = this.fuelCost
  }
}

export const FuelCostFormComponent = {
  template: require('./fuel-cost-form.component.html'),
  styles: [require('./fuel-cost-form.component.scss')],
  bindings: {
    fuelCost: '<',
  },
  controller: FuelCostFormController,
}
