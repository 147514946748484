import { settings } from '../../app.config'
// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'

class ArendaPolupricepovFormController extends PrintFormController {
  /** @ngInject */
  constructor($scope, $injector, planServiceApiV2) {
    super($scope, $injector)
    this.planServiceApiV2 = planServiceApiV2
  }

  $onInit() {
    const today = new Date()
    const firm0 = this.ourFirms.count ? this.ourFirms.results[0].id : null
    this.filters = {
      month: today.getMonth() + 1,
      year: settings.defaultYear,
      arendatel: firm0,
      arendator: firm0,
    }
  }

  /** Печать отчета. */
  print() {
    this.planServiceApiV2.getPricepArendaReport(this.filters)
  }
}

export const ArendaPolupricepovFormComponent = {
  template: require('./arenda-polupricepov-form.component.html'),
  styles: [require('./arenda-polupricepov-form.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: ArendaPolupricepovFormController,
}
