// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import { currentMonth } from '../../utils'

/** @ngInject */
class OtvystFormController extends PrintFormController {
  reportName = 'printotchet'
  legacyReport = true

  get defaultFilters() {
    return {
      eksp: null,
      month: currentMonth(),
    }
  }

  get convertedFilters() {
    return {
      _man: this.filters.eksp,
      _m1: this.filters.month,
    }
  }
}

export const OtvystFormComponent = {
  template: require('./otvyst-form.component.html'),
  styles: [require('./otvyst-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: OtvystFormController,
}
