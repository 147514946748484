export function TachDialogCtrl($http, $timeout, tachographService) {
  const vm = this

  const id = $.fn.urlparam('id')

  Object.assign(vm, {
    tach: null,

    init: () => {
      if (id) {
        tachographService.get({ id }).then((tach) => {
          vm.tach = tach
        })
      }
    },
    submit: () => {
      tachographService.save(vm.tach).then((r) => {
        if (window.dialog) {
          window.dialog.resolve(r.data)
        }
        window.close()
      })
    },
    cancel: () => {
      window.close()
    },
  })

  vm.init()
}
