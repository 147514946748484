export const pricepListState = {
  name: 'listPriceps',
  url: '/priceps',
  component: 'priceps',
  resolve: {
    ourFirms: (firmServiceApiV2) => {
      return firmServiceApiV2.getOurFirms()
    },
  },
  data: {
    title: 'Прицепы',
  },
}

export const pricepEditState = {
  name: 'editPricep',
  url: '/priceps/:pricepId',
  component: 'pricepForm',
  resolve: {
    model: (pricepServiceApiV2, $transition$) => {
      return pricepServiceApiV2.get({ id: $transition$.params().pricepId })
    },
  },
  data: {
    title: 'Редактирование прицепа',
  },
}
