/** Контроллер для работы с договорами. */
class ContractsController {
  /** Значения по-умолчанию для фильтров. */
  DEFAULT_FILTERS = {
    client: null,
    server: null,
    page_size: 1500,
  }

  /** Фильтры списка договоров. */
  filters
  /** Выбранный договор. */
  contract = null
  contractId = null
  /** Фирма-заказчик, для которой будут отображаться договоры. */
  client = null

  /** @ngInject */
  constructor($q, $scope, contractServiceApiV2, contractsModal) {
    Object.assign(this, { $q, $scope, contractServiceApiV2, contractsModal })
    $scope.vm = this

    this.contractsTable = {
      options: {
        headers: {
          _client: 'Заказчик',
          _server: 'Исполнитель',
          nomer: 'Номер',
          _ot: 'Начало',
          _do: 'Окончание',
          _is_default: 'Основной договор',
        },
      },
      init$: $q.defer(),
      service: contractServiceApiV2,
      ctrl: null,
      rowfmt: (row) => {
        row._client = row.client_info ? row.client_info._full_name : '-'
        row._server = row.server_info ? row.server_info._full_name : '-'
        row._ot = row.ot ? $.fn.dateutils.date2str_ddmmyyyy(row.ot, '.') : '-'
        row._do = row.do ? $.fn.dateutils.date2str_ddmmyyyy(row.do, '.') : '-'
        row._is_default = row.is_default ? '&#10004;' : ''
        return row
      },
    }
  }

  $onInit() {
    // Init filters
    this.resetFilters()
    this.$q.all([this.contractsTable.init$.promise]).then((resps) => {
      this.contractsTable.ctrl = resps[0]
    })
  }

  /** Создать договор. */
  create() {
    const modal = this.contractsModal.open({
      locals: {
        contract: {
          client: this.filters.client,
          server: this.filters.server,
        },
      },
    })
    return modal.closed.then(() => {
      this.contractsTable.ctrl.reload()
    })
  }

  /** Отредактировать договор. */
  edit() {
    const modal = this.contractsModal.open({
      resolve: {
        contract: () => this.contractServiceApiV2.get({ id: this.contractId }),
      },
    })
    return modal.closed.then(() => {
      this.contractsTable.ctrl.reload()
    })
  }

  /** Удаление договора. */
  delete() {
    if (!confirm('Вы уверены, что хотите удалить выбранный договор?')) {
      return
    }
    this.contractServiceApiV2
      .delete(this.contractId)
      .then(this.contractsTable.ctrl.removeRow)
  }

  /** Закрыть окно. */
  close() {
    window.close()
  }

  /** Сброс фильтров. */
  resetFilters() {
    this.filters = { ...this.DEFAULT_FILTERS }
    if (this.client) {
      this.filters.client = this.client.id
    }
  }
}

export const ContractsComponent = {
  template: require('./contracts.component.html'),
  styles: [require('./contracts.component.scss')],
  bindings: {
    client: '<',
  },
  controller: ContractsController,
}
