// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class OsopzmFormController extends PrintFormController {
  reportName = 'printstrah'
  legacyReport = true

  get defaultFilters() {
    return {
      from: firstDayOfMonth(),
      to: lastDayOfMonth(),
    }
  }

  get convertedFilters() {
    return {
      _day1: this.filters.from.getDate(),
      _day2: this.filters.to.getDate(),
      _mesac1: this.filters.from.getMonth() + 1,
      _mesac2: this.filters.to.getMonth() + 1,
      _year1: this.filters.from.getFullYear(),
      _year2: this.filters.to.getFullYear(),
    }
  }
}

export const OsopzmFormComponent = {
  template: require('./osopzm-form.component.html'),
  styles: [require('./osopzm-form.component.scss')],
  bindings: {},
  controller: OsopzmFormController,
}
