import PopeyeModal from '../popeye-modal.class'

/** @ngInject */
class ZayNoteModalController {
  constructor($scope, zay, zaysServiceApiV2) {
    this.$scope = $scope
    this.zaysServiceApiV2 = zaysServiceApiV2
    this.zay = zay
    $scope.vm = this
  }

  save() {
    return this.zaysServiceApiV2
      .patch({
        id: this.zay.id,
        prims: this.zay.prims,
      })
      .then((zay) => {
        this.$scope.$close(zay)
      })
  }

  cancel() {
    this.$scope.$close()
  }
}

export class ZayNoteModal extends PopeyeModal {
  modal = {
    template: require('./zay-note.modal.html'),
    controller: ZayNoteModalController,
  }
}
