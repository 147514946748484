import { CRUDServiceAPI2 } from './crud-service.class'

/** Сервис для фирм на основе ApiV2 */
export class EkspServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'eksp'

  toHtml(eksp) {
    return eksp.name
  }

  /**
   * Поисковые фильтры для экспедиторов.
   * @param {string} text часть ФИО
   * */
  getSearchFilters(text) {
    return { name: text }
  }
}
