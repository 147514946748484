import PopeyeModal from '../popeye-modal.class'

class ContractsModalController {
  static $inject = ['$scope', 'contract']

  constructor($scope, contract) {
    this.$scope = $scope
    this.contract = contract
    $scope.vm = this
  }
}

export class ContractsModal extends PopeyeModal {
  modal = {
    template: require('./contracts.modal.html'),
    controller: ContractsModalController,
  }
}
