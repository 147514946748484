export function StringTSToDateDirective() {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function (value) {
        if (!value) {
          return ''
        }
        const timestamp = value.getTime() / 1000
        return '' + timestamp
      })
      ngModel.$formatters.push(function (value) {
        const timestamp = parseInt(value)
        if (isNaN(timestamp)) {
          return null
        }
        return new Date(timestamp * 1000)
      })
    },
  }
}
