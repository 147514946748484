export function AvtostrahController(
  $scope,
  GruzFirms,
  GruzAvto,
  GruzPricep,
  $http
) {
  const $ctrl = this

  $ctrl.firm = null
  $ctrl.firms = GruzFirms.query({ kmbm__nashi: 1 })

  $scope.$watch(function () {
    return $ctrl.firm
  }, onFirmChanged)

  $ctrl.ifLate = ifLate
  $ctrl.onAvtostrahChanged = onAvtostrahChanged
  $ctrl.onPricepstrahChanged = onPricepstrahChanged
  $ctrl.deleteAvtostrah = deleteAvtostrah
  $ctrl.deletePricepstrah = deletePricepstrah

  $scope.$ctrl = $ctrl

  function onFirmChanged(nv, ov) {
    if (!nv) {
      return
    }
    $ctrl.avto = null
    $ctrl.pricep = null

    GruzAvto.query({ firmId: nv }, function (avtos) {
      $ctrl.avtos = mapId2obj(avtos)
    })
    GruzPricep.query({ firmId: nv }, function (priceps) {
      $ctrl.priceps = mapId2obj(priceps)
    })

    function mapId2obj(res) {
      const objmap = {}
      _.each(res, function (r) {
        const obj = r.toJSON()
        objmap[obj.id] = obj
      })
      return objmap
    }
  }

  function onAvtostrahChanged(timestamp) {
    if (typeof timestamp === 'undefined') {
      return
    }

    const avtoid = $ctrl.avto ? $ctrl.avto[0] : null
    if (!avtoid) {
      return
    }

    return saveStrah('avto', avtoid, timestamp).then(function () {
      $ctrl.avtos[avtoid].strahdata = timestamp
    })
  }

  function saveStrah(type, avtoid, timestamp) {
    const data = {
      _strahdata: timestamp,
      _edit_submit: 1,
    }
    if (type === 'pricep') {
      data._pricepid = avtoid
    } else {
      data._avtoid = avtoid
    }

    return $http.post('popup.php?url=strah&_id=' + $ctrl.firm, data)
  }

  function onPricepstrahChanged(timestamp) {
    if (typeof timestamp === 'undefined') {
      return
    }

    const pricepid = $ctrl.pricep ? $ctrl.pricep[0] : null
    if (!pricepid) {
      return
    }

    return saveStrah('pricep', pricepid, timestamp).then(function () {
      $ctrl.priceps[pricepid].strahdata = timestamp
    })
  }

  function ifLate(avto) {
    const now = Date.now() / 1000
    return now > parseInt(avto.strahdata || '0')
  }

  function deleteAvtostrah() {
    const avtoid = $ctrl.avto[0]
    return saveStrah('avto', avtoid, null).then(function () {
      $ctrl.avtos[avtoid].strahdata = null
    })
  }

  function deletePricepstrah() {
    const pricepid = $ctrl.pricep[0]
    return saveStrah('pricep', pricepid, null).then(function () {
      $ctrl.priceps[pricepid].strahdata = null
    })
  }
}
