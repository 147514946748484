import { settings } from '../../app.config'
// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'

class RashodToplivaFormController extends PrintFormController {
  reportName = 'toplivo'
  trucks = []

  /** @ngInject */
  constructor($scope, $injector, avtoServiceApiV2) {
    super($scope, $injector)
    this.avtoServiceApiV2 = avtoServiceApiV2
  }

  get defaultFilters() {
    const today = new Date()
    return {
      month: today.getMonth() + 1,
      year: settings.defaultYear,
      auto: null,
      firm: this.ourFirms.results[0].id,
    }
  }

  $onInit() {
    super.$onInit()
    this.$scope.$watch(() => this.filters.firm, this._updateTrucks.bind(this))
  }

  /** Обновление списка тягачей. */
  _updateTrucks() {
    return this.avtoServiceApiV2
      .get({ pid: this.filters.firm })
      .then((trucks) => {
        this.trucks = trucks.results
      })
  }
}

export const RashodToplivaFormComponent = {
  template: require('./rashod-topliva-form.component.html'),
  styles: [require('./rashod-topliva-form.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: RashodToplivaFormController,
}
