import PopeyeModal from '../popeye-modal.class'

export class EquipmentImageDeleteModalController {
  imageTypes = ['images', 'sketches', 'nameplates']

  fileTypes = ['attachments']

  /** @ngInject */
  constructor($q, $scope, $state, type, imageItem, equipmentImageServiceApiV2) {
    this.$q = $q
    this.$scope = $scope
    this.$state = $state
    this.equipmentImageServiceApiV2 = equipmentImageServiceApiV2

    this.imageItem = imageItem
    this.type = type
    this.data = {
      imageId: this.imageItem.id,
      type,
    }

    this.setOpportunity()

    $scope.vm = this
  }

  /**
   * Удаление изделия
   */
  delete() {
    return this.equipmentImageServiceApiV2
      .delete(this.data.imageId, this.type)
      .then((resp) => {
        this.$state.reload()
        this.$scope.$close()
      })
  }

  cancel() {
    this.$scope.$close()
  }

  /**
   * Установка разрешений и ограничений
   */
  setOpportunity() {
    this.opportunity = {
      is_image: this.imageTypes.includes(this.type),
      is_file: this.fileTypes.includes(this.type),
    }
  }
}

export class EquipmentImageDeleteModal extends PopeyeModal {
  modal = {
    template: require('./equipment-image-delete.modal.html'),
    styles: [require('./equipment-image-delete.modal.scss')],
    controller: 'equipmentImageDeleteModalController',
  }
}
