import PopeyeModal from '../popeye-modal.class'

class EquipmentImageEditModalController {
  static $inject = [
    '$q',
    '$scope',
    '$state',
    'eqItem',
    'type',
    'imageItem',
    'equipmentImageServiceApiV2',
  ]

  imageTypes = ['images', 'nameplates']

  fileTypes = ['attachments', 'sketches']

  constructor(
    $q,
    $scope,
    $state,
    eqItem,
    type,
    imageItem,
    equipmentImageServiceApiV2
  ) {
    this.$q = $q
    this.$scope = $scope
    this.$state = $state
    this.equipmentImageServiceApiV2 = equipmentImageServiceApiV2

    this.eqItem = eqItem
    this.imageItem = imageItem
    this.type = type
    this.data = {
      eqItemId: this.eqItem.id,
      imageId: this.imageItem.id,
      type,
    }

    this.setOpportunity()

    $scope.vm = this
  }

  /**
   * Редактирование изделия
   */
  save() {
    this.equipmentImageServiceApiV2.save(this.data).then((resp) => {
      this.$state.reload()
      this.$scope.$close()
    })
  }

  cancel() {
    this.$scope.$close()
  }

  /**
   * Установка разрешений и ограничений
   */
  setOpportunity() {
    this.opportunity = {
      is_image: this.imageTypes.includes(this.type),
      is_file: this.fileTypes.includes(this.type),
    }
  }
}

export class EquipmentImageEditModal extends PopeyeModal {
  modal = {
    template: require('./equipment-image-edit.modal.html'),
    styles: [require('./equipment-image-edit.modal.scss')],
    controller: EquipmentImageEditModalController,
  }
}
