import { NODATA } from '../constants'
import { isInteger } from '../utils'
import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

/**
 * @typedef {Object} Firm Фирма.
 * @property {string} type форма организации
 * @property {string} comp наименование
 */

/**
 * Текстовое представление фирмы.
 * @param {Firm} firm фирма
 * @returns {string} текстовое представление фирмы
 */
function toHtml(firm) {
  const parts = []
  if (firm.type) {
    parts.push(firm.type)
    parts.push(`"${firm.comp}"`)
  } else {
    parts.push(firm.comp)
  }
  return parts.join(' ')
}

/**
 * Поисковые фильтры для заявок.
 * @param {string} text часть номера или плательщика заявки
 * */
function getSearchFilters(text) {
  return { _contains: text, limit: 100, verbose: 1 }
}

/** Сервис для фирм на основе ApiV1 */
export class FirmsService extends CRUDService {
  subject = 'firms'

  toHtml = toHtml
  getSearchFilters = getSearchFilters

  parse = (k) => {
    k.id = parseInt(k.id)
    return k
  }

  /** Получение наших фирм. */
  getOurFirms() {
    return this.get({ kmbm__nashi: 1, verbose: 1 })
  }
}

/** Сервис для фирм на основе ApiV2 */
export class FirmServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'firms'

  toHtml(firm) {
    const parts = []
    if (firm.type) {
      parts.push(firm.type)
      parts.push(`"${firm.comp}"`)
    } else {
      parts.push(firm.comp)
    }

    const name = parts.join(' ')
    const inn = `ИНН ${firm.rekv.inn || NODATA}`

    return `${name}<br><small>${inn}</small>`
  }

  /**
   * Фильтры для поиска.
   *
   * Если вводимый текст является числом, то поиск ведется
   * по ИНН. Иначе - по названию фирмы.
   *
   * @param {string} text часть текста для поиска
   * @returns {object} словарь фильтров
   */
  getSearchFilters(text) {
    if (isInteger(text)) {
      return { inn__contains: text, limit: 100 }
    } else {
      return { _contains: text, limit: 100 }
    }
  }

  /** Получение наших фирм. */
  getOurFirms() {
    return this.get({ kmbm__nashi: 1 })
  }
}
