export function GruzFirms($resource) {
  return $resource(
    'api.php?url=firms&id=:firmId',
    {},
    {
      query: { isArray: true },
    }
  )
}

export function GruzPricep($resource) {
  return $resource(
    'api.php?url=priceps&firm_id=:firmId',
    {},
    {
      query: { isArray: true },
    }
  )
}

export function GruzAvto($resource) {
  return $resource(
    'api.php?url=avtos&firm_id=:firmId',
    {},
    {
      query: { isArray: true },
    }
  )
}
