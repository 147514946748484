import { CRUDServiceAPI2 } from './crud-service.class'

export class EquipmentImageServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'equipment'

  /**
   * Получение
   * @param {} type
   * @param {*} filters
   * @returns
   */
  get(type, filters) {
    this.subject = `${this.subject}/${type}`
    return super.get(filters)
  }

  /**
   * Редактирование изображения
   * @param {*} data
   * @returns
   */
  save(data) {
    const subject = `${this.subject}/${data.type}/${data.imageId}`
    const url = `${this.baseUrl}/${subject}`

    const formData = new FormData()
    formData.append('name', data.image.name)
    formData.append('file', data.image)
    formData.append('item', data.eqItemId)
    return this.$http.put(url, formData, this.getMultipartHeaders())
  }

  /**
   * Удаление изображения
   * @param key ключ
   * @param {string} type тип изображения
   * @returns ключ
   */
  async delete(key, type = 'images') {
    const url = `${this.baseUrl}/${this.subject}/${type}/${key}`
    await this.$http.delete(url)
    return key
  }
}
