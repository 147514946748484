import { WindowFormController } from '../../controllers/form-controller.class'

class DriverFormController extends WindowFormController {
  static $inject = ['$scope', 'driverServiceApiV2']

  constructor($scope, driverServiceApiV2) {
    super($scope)
    this.api = driverServiceApiV2
  }
}

export const DriverFormComponent = {
  template: require('./driver-form.component.html'),
  styles: [require('./driver-form.component.scss')],
  bindings: {
    model: '<',
  },
  controller: DriverFormController,
}
