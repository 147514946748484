import PopeyeModal from '../popeye-modal.class'

export default class ZoplModal extends PopeyeModal {
  modal = {
    template: require('./zopl.modal.html'),
    controller: function ($scope, uchet, firmUchet, uchetZoplService) {
      const uchetOpts = firmUchet
        .map((u) => Object.assign({}, u))
        .filter((u) => {
          if (u.id === uchet.id) {
            return false
          } else if (u.nzoplrefs > 0) {
            // пропускаем итоговый счет и
            // те счета, которые уже засчитаны как
            // предоплатные
            return false
          } else {
            return true
          }
        })
      const itogovyi = uchetOpts.find((u) => u.nzopls > 0)
      const vm = {
        uchet,
        uchetOpts,
        zopl: itogovyi
          ? +itogovyi.nscheta
          : uchetOpts.length > 0
          ? +uchetOpts[0].nscheta
          : 0,

        submit: () => {
          return uchetZoplService
            .zopl(uchet, vm.zoplObject)
            .then($scope.$close, console.log)
        },
      }
      Object.defineProperty(vm, 'zoplObject', {
        get: () =>
          Object.assign(
            {},
            uchetOpts.find((u) => (u.nscheta = vm.zopl))
          ),
      })
      $scope.vm = vm
    },
  }
}
