// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { firstDayOfMonth } from '../../utils'

class ZayStatusController extends PrintFormController {
  ZTYPES = [
    { id: 1, name: 'Все' },
    { id: 2, name: 'Закрытые' },
    { id: 3, name: 'Не закрытые' },
    { id: 4, name: 'Пустые' },
    { id: 5, name: 'Без исполнителя' },
  ]

  /** @ngInject */
  constructor($scope, $injector, $q, zaysServiceApiV2, popup) {
    super($scope, $injector)
    $scope.vm = this
    this.$q = $q
    this.popup = popup
    this.selected = null

    this.table = {
      options: {
        headers: {
          id: 'Заказ',
          data: 'Дата',
          plid__comp: 'Заказчик',
          manager__fio: 'Менеджер',
          closed: 'Закр',
          gruz: 'Машин',
        },
      },
      init$: $q.defer(),
      service: zaysServiceApiV2,
      ctrl: null,
      rowfmt: (row) => {
        row.closed = row.close === '1' ? 'да' : ''
        return row
      },
    }
  }

  get defaultFilters() {
    return {
      ztype: 1,
      date_from: firstDayOfMonth(),
      date_to: new Date(),
      firm: null,
      manager: null,
    }
  }

  get convertedFilters() {
    const filters = { ...this.filters }
    filters.date_from = utils.db.date2str(this.filters.date_from)
    filters.date_to = utils.db.date2str(this.filters.date_to)
    switch (filters.ztype) {
      case 2:
        filters.closed = true
        break
      case 3:
        filters.closed = false
        break
      case 4:
        filters.empty = true
        break
      case 5:
        filters.no_manexp = true
    }
    return filters
  }

  search() {
    this.appliedConvFilters = { ...this.convertedFilters }
    this.appliedFilters = { ...this.filters }
  }

  printContainers() {}

  printZays() {
    const af = this.appliedFilters
    this.printer.print('all_zays', {
      year1: af.date_from.getFullYear(),
      year2: af.date_to.getFullYear(),
      month1: af.date_from.getMonth() + 1,
      month2: af.date_to.getMonth() + 1,
      day1: af.date_from.getDate(),
      day2: af.date_to.getDate(),
      firm: af.plid,
      ztype: af.ztype,
      zmanager: af.manexp,
    })
  }

  edit() {
    this.popup.state('zay.base.detail', { id: this.selected }, 800, 600)
  }
}

export const ZayStatusComponent = {
  template: require('./zay-status.component.html'),
  styles: [require('./zay-status.component.scss')],
  bindings: {
    managers: '<',
  },
  controller: ZayStatusController,
}
