// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { firstDayOfYear, lastDayOfYear } from '../../utils'

/** @ngInject */
class NeoplFormController extends PrintFormController {
  reportName = 'neopl_scheta'

  get defaultFilters() {
    return {
      from: firstDayOfYear(),
      to: lastDayOfYear(),
      eksp: null,
      firm: null,
      groupByFirms: false,
    }
  }

  get convertedFilters() {
    return {
      _date_from: utils.db.date2str(this.filters.from),
      _date_to: utils.db.date2str(this.filters.to),
      _manager_id: this.filters.eksp,
      _firm_id: this.filters.firm,
      _group_by_firms: this.filters.groupByFirms ? '1' : '',
    }
  }
}

export const NeoplFormComponent = {
  template: require('./neopl-form.component.html'),
  styles: [require('./neopl-form.component.scss')],
  bindings: {
    ekspeditors: '<',
  },
  controller: NeoplFormController,
}
