export function ProstoiAvtoCtrl($scope, $q, $http, $timeout) {
  const vm = this
  $scope.vm = vm

  Object.assign(vm, {
    avtos: $('#avtos'),
    days: 60,
    init: () => {
      vm.avtos
        .jsTable({
          headers: {
            mark: 'Марка',
            nomer: 'Номер&nbsp;тягача',
            polu: 'Закрепленный полуприцеп',
            driver: 'Закрепленный водитель',
            prostoi: 'Дней простоя',
          },
          sourceUrl: `api.php?url=avtos&prostoi=${vm.days}`,
          tablesorterOptions: {
            sortList: [[0, 0]],
          },
        })
        .jsTable('reload')
    },
    search: () => {
      return $http.get(`api.php?url=avtos&prostoi=${vm.days}`).then((resp) => {
        vm.avtos.jsTable('loadRows', resp.data)
      })
    },
    close: () => {
      window.close()
    },
  })

  vm.init()
}

export const ProstoiAvtoComponent = {
  template: require('./prostoi-avto.component.html'),
  styles: [require('./prostoi-avto.component.scss')],
  bindings: {},
  controller: ProstoiAvtoCtrl,
}
