import { NODATA } from '../../constants'
import { ListController } from '../../controllers/list-controller.class'
import utils, { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class AvtoJournalFormController extends ListController {
  constructor($scope, $injector, putlistServiceApiV2, printer) {
    super($scope, $injector)
    this.api = putlistServiceApiV2
    this.printer = printer
    this.tableOptions = {
      headers: {
        id: 'Лист',
        truck: 'Тягач',
        driver: 'Водитель',
        timeu: 'Выезд',
        speedu: 'Спидометр',
        give: 'Выдано топлива',
        timep: 'Возврат',
        speedp: 'Спидометр',
      },
    }
  }

  get defaultFilters() {
    return {
      firm: this.ourFirms.results[0].id,
      date_from: utils.db.date2dmy(firstDayOfMonth()),
      date_to: utils.db.date2dmy(lastDayOfMonth()),
    }
  }

  rowFormatter(row) {
    return {
      ...row,
      truck: row.avto ? row.avto_info.nomer : NODATA,
      driver: row.driver1 ? row.driver1_info.fio : NODATA,
      timeu: row.plistu.time,
      speedu: row.plistu.speed,
      give: row.plistu.give,
      timep: row.plistp.time,
      speedp: row.plistp.speed,
    }
  }

  /** Печать списка. */
  print() {
    this.printer.printLegacy('printjournal', {
      _date1: this.filters.date_from,
      _date2: this.filters.date_to,
      _firm: this.filters.firm,
    })
  }
}

export const AvtoJournalFormComponent = {
  template: require('./avto-journal-form.component.html'),
  styles: [require('./avto-journal-form.component.scss')],
  bindings: {
    ourFirms: '<',
  },
  controller: AvtoJournalFormController,
}
