import { settings } from '../app.config'

/**
 * @typedef {Object} Token Токен.
 * @property {string} access Access token
 * @property {string} refresh Refresh token
 */

/**
 * Сервис аутентификации на основе JWT.
 */
export class AuthService {
  /** Ключ токена в хранилище. */
  TOKEN_KEY = 'authToken'

  static $inject = ['$q', '$http', '$window']
  constructor($q, $http, $window) {
    Object.assign(this, { $q, $http, $window })
    this.storage = $window.localStorage
    this.url = settings.apiUrl
  }

  /**
   * Получение токена из хранилища.
   * @returns {Token|null} Токен
   */
  getToken() {
    const data = this.storage.getItem(this.TOKEN_KEY)
    if (!data) {
      return null
    }
    return JSON.parse(data)
  }

  /**
   * Актуальный токен.
   * @alias getToken
   * @returns {Token|null} Токен
   */
  get token() {
    return this.getToken()
  }

  /**
   * Создание токена (и сохранение в хранилище).
   * @param {string} username пользователь
   * @param {string} password пароль
   * @returns {Promise<Token>} созданный токен
   */
  createToken(username, password) {
    // удалим существующий, чтобы избежать попытки обновления токена
    // при ошибочных кредах
    this.deleteToken()
    const storage = this.storage
    const url = `${this.url}/auth/jwt/create`
    return this.$http.post(url, { username, password }).then((resp) => {
      storage.setItem(this.TOKEN_KEY, JSON.stringify(resp.data))
      return resp.data
    })
  }

  /**
   * Обновление токена (и сохранение в хранилище).
   * @returns {Promise<Token>} обновленный токен
   */
  refreshToken() {
    const deferred = this.$q.defer()
    let token = this.getToken()
    if (!token) {
      deferred.reject('Refresh token not found')
    } else {
      const url = `${this.url}/auth/jwt/refresh`
      this.$http.post(url, { refresh: token.refresh }).then(
        (resp) => {
          token = this.getToken()
          token.access = resp.data.access
          this.storage.setItem(this.TOKEN_KEY, JSON.stringify(token))
          deferred.resolve(token)
        },
        (rejection) => {
          deferred.reject(rejection)
        }
      )
    }
    return deferred.promise
  }

  /**
   * Удаление токена из хранилища.
   */
  deleteToken() {
    this.storage.removeItem(this.TOKEN_KEY)
  }
}

export class AuthHttpInterceptorService {
  static $inject = ['$q', '$injector', '$window']
  constructor($q, $injector, $window) {
    Object.assign(this, { $q, $injector, $window })
  }

  /**
   * Перехват запроса.
   * @param {*} config конфигурация запроса
   * @returns {*} конфигурация запроса
   */
  request = (config) => {
    const authService = this.$injector.get('authService')
    config.headers = config.headers || {}
    const token = authService.getToken()
    if (token) {
      config.headers.Authorization = `JWT ${token.access}`
    }
    return config
  }

  /**
   * Перехват ошибки запроса. Попытка обновления токена.
   * @param {*} rejection параметры отказа
   * @returns Promise<*> параметры отказа
   */
  responseError = (rejection) => {
    const authService = this.$injector.get('authService')
    const $http = this.$injector.get('$http')
    const deferred = this.$q.defer()
    const $window = this.$window
    const absUrl = /^(?:[a-z+]+:)?\/\//i
    const url = rejection.config.url
    const pathname = absUrl.test(url)
      ? new URL(rejection.config.url).pathname
      : url

    if (rejection.status === 401 && authService.getToken() != null) {
      // если ошибка случилась в процессе обновления токена, то выходим
      if (pathname === '/auth/jwt/refresh') {
        deferred.reject(rejection)
      } else {
        authService.refreshToken().then(retryHttpRequest, logout)
      }
    } else {
      deferred.reject(rejection)
    }

    /** Повтор запроса. */
    function retryHttpRequest() {
      $http(rejection.config).then(
        function (response) {
          deferred.resolve(response)
        },
        function (response) {
          deferred.reject(response)
        }
      )
    }

    /** Выход на главную страницу с сообщением об истечении сессии. */
    function logout(rejection) {
      authService.deleteToken()
      $window.location.href = '/gruz.php?exit=1&session_expired=1'
    }
    return deferred.promise
  }
}
