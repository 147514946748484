export default class PopeyeModal {
  modal = null

  static $inject = ['Popeye']
  constructor(Popeye) {
    this.Popeye = Popeye
  }

  open(options) {
    return this.Popeye.openModal(Object.assign({}, this.modal, options))
  }
}
