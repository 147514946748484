export const baseFirmState = {
  name: 'firm',
  url: '/firms/:id',
  abstract: true,
  resolve: {
    firm: ($stateParams, firmServiceApiV2) => {
      if ($stateParams.id) {
        return firmServiceApiV2.get({ id: $stateParams.id })
      }
    },
  },
  data: {
    title: 'Фирма',
  },
}

export const firmAddState = {
  name: 'newfirm',
  url: '/firms/add',
  component: 'firmForm',
  data: {
    title: 'Новая фирма',
  },
}

export const firmEditState = {
  name: 'firm.detail',
  url: '/edit',
  component: 'firmForm',
  resolve: {
    model: (firm) => firm,
  },
}

export const firmContractsState = {
  name: 'firm.contracts',
  url: '/contracts',
  component: 'contracts',
  resolve: {
    client: (firmServiceApiV2, $transition$) => {
      return firmServiceApiV2.get({ id: $transition$.params().id })
    },
  },
  data: {
    title: 'Договоры фирмы',
  },
}

export const firmAddDriverState = {
  name: 'firm.newdriver',
  url: '/add-driver',
  component: 'driverForm',
  resolve: {
    model: (firm) => {
      return { pid: firm.id }
    },
  },
  data: {
    title: 'Новый водитель',
  },
}
