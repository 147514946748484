export function MultipleFileReadDirective() {
  return {
    scope: {
      multifileread: '=',
    },
    link: function (scope, element, attributes) {
      element.bind('change', function (changeEvent) {
        scope.$apply(function () {
          const files = changeEvent.target.files
          scope.multifileread = []

          for (const key in files) {
            const file = files[key]
            if (file instanceof File) {
              scope.multifileread.push(file)
            }
          }
        })
      })
    },
  }
}
