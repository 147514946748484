import { WindowFormController } from '../../controllers/form-controller.class'
import utils from '../../utils'

/** @ngInject */
class ZayFormController extends WindowFormController {
  /** Словарь наших фирм: код => фирма. */
  ourFirmsByCode = {}
  /** Контракты для оператора-заявки с внешним заказчиком. */
  contracts

  /** Конструктор компонента. */
  constructor(
    $scope,
    popup,
    toaster,
    zayNoteModal,
    planServiceApiV2,
    zaysServiceApiV2,
    contractServiceApiV2,
    contractsModal,
    profile
  ) {
    super($scope)
    this.api = zaysServiceApiV2
    this.popup = popup
    this.$scope = $scope
    this.toaster = toaster
    this.zayNoteModal = zayNoteModal
    this.planServiceApiV2 = planServiceApiV2
    this.contractServiceApiV2 = contractServiceApiV2
    this.contractsModal = contractsModal
    this.profile = profile
  }

  /** Фирма-оператор заявки. */
  get operator() {
    return this.ourFirmsByCode[this.model.type]
  }

  /** Доступы. */
  get permissions() {
    return {
      finansy: this.profile.hasZayFinansePermission(this.model),
    }
  }

  $onInit() {
    this.model = this.zay
    this.ourFirmsByCode = this.ourFirms.results.idlize((f) => f.kmbm)

    const emptyZay = {
      data: utils.db.date2str(new Date()),
      type: 'tm',
      gruz: 1,
      dohod: 0,
      rashod: 0,
      prims: '',
    }
    if (!this.model) {
      this.model = { ...emptyZay }
    }
    this.oldModel = { ...this.model }

    // если у плательщика в реквизитах фирмы есть особые указания
    // (в справочнике фирм - выводим их)
    const osobye = this.plid ? this.plid.rekv.osobye : ''
    if (osobye) {
      this.toaster.pop({
        title: 'Особые указания',
        body: osobye,
        timeout: 0,
      })
    }

    // если в самой заявке есть непустое примечание - выводим его
    const prims = this.model.prims.trim()
    if (prims !== '') {
      this.toaster.pop({
        title: 'Примечание',
        body: prims,
        timeout: 0,
      })
    }
  }

  finansy() {
    $.fn.openPopup(`ngfinansy&id=${this.model.id}`, 970, 770)
  }

  /**
   * Копирование фирм.
   */
  copyFirms() {
    if (!this.model.plid) return
    this.model.zaid = +this.model.plid
    this.model.otid = +this.model.plid
    this.model.poid = +this.model.plid
  }

  /** Добавить примечание к заявке. */
  addPrim() {
    return this.zayNoteModal
      .open({
        locals: {
          zay: this.model,
        },
      })
      .closed.then((zay) => {
        if (zay) {
          this.toaster.pop({
            type: 'success',
            body: 'Примечание сохранено',
          })
        }
      })
  }

  svodka() {
    // eslint-disable-next-line no-undef
    openwin(`popup.php?url=svodka&id=${this.model.id}`, 600, 450)
  }

  /** Открытие формы для ТТН. */
  openGruz() {
    const width = 840
    const height = 580
    let state = 'zay.base.ttn.new'
    const params = { id: this.model.id }
    if (this.model.ttns.length > 0) {
      state = 'zay.base.ttn.detail'
      params.nomer = this.model.ttns[0].nomer
    }
    return this.popup.state(state, params, width, height)
  }

  /** Закрытие формы заявки. */
  close() {
    window.close()
  }

  /**
   * Установка перевезенного груз и маршрута из 1й накладной
   */
  loadfn() {
    this.planServiceApiV2.get({ pid: this.model.id }).then((page) => {
      const plans = page.results
      if (plans.length === 0) {
        alert('Накладные отсутствуют!')
        return
      }
      const plan = plans[0]
      this.model.pergruz = plan.name || ''
      this.model.marshrut = plan.kuda || ''
    })
  }

  /** Сохранение заявки. */
  save() {
    if (!this.form.$valid) {
      return
    }
    return this.api.save(this.model).then((zay) => {
      this.model = zay
      return super.save({ close: false })
    })
  }

  /** Увеличение кол-ва накладных. */
  inc() {
    this.model.gruz++
  }

  /** Уменьшение кол-ва накладных. */
  dec() {
    this.model.gruz--
  }

  /** Открыть форму для редактирования фирмы. */
  openFirm(firmId) {
    if (!firmId) {
      return
    }
    // eslint-disable-next-line no-undef
    ngOpenDialog(`firms/${firmId}/edit`, 730, 800)
  }

  updateContract() {
    if (!this.model.zaid || !this.operator) {
      this.contracts = []
      this.model.contract = null
      return
    }
    this.contractServiceApiV2
      .get({
        client: this.model.zaid,
        server: this.operator.id,
      })
      .then((resp) => {
        this.contracts = resp.results
      })
  }
}

export const ZayFormComponent = {
  template: require('./zay-form.component.html'),
  styles: [require('./zay-form.component.css')],
  bindings: {
    zay: '<',
    plid: '<',
    ourFirms: '<',
    legacyUsers: '<',
    managers: '<',
    contracts: '<',
  },
  controller: ZayFormController,
}
