// eslint-disable-next-line max-len
import { PrintFormController } from '../../controllers/print-form-controller.class'
import utils, { firstDayOfMonth, lastDayOfMonth } from '../../utils'

/** @ngInject */
class Otper1FormController extends PrintFormController {
  reportName = 'oper1'

  get defaultFilters() {
    return {
      from: firstDayOfMonth(),
      to: lastDayOfMonth(),
      excludeInnerRashod: false,
      firm: null,
    }
  }

  get convertedFilters() {
    return {
      d1: utils.db.date2dmy(this.filters.from),
      d2: utils.db.date2dmy(this.filters.to),
      firm: this.filters.firm,
      vn: this.filters.excludeInnerRashod ? '1' : '',
    }
  }
}

export const Otper1FormComponent = {
  template: require('./otper1-form.component.html'),
  styles: [require('./otper1-form.component.scss')],
  bindings: {},
  controller: Otper1FormController,
}
